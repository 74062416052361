import { useState,  useEffect } from 'react'
import * as php from '../../libs/php_libs';
import { useFileView } from '../../providers/FileViewProvider';

export const Amount = () => {
  const fileView = useFileView();
  const [amount, setAmount] = useState("");
  const [tax, setTax] = useState("");

  useEffect(() => {
    fileView.setNewAmount(0);
    fileView.setNewTax(0);
  }, []);

  return (
<>
    <div>


      <dt className="text-xs font-medium text-gray-500">
        <span className="">税込金額と消費税</span>
      </dt>

      <dd className="inline-block">
        <div className="mt-1">
          <input
            maxLength={12}
            type="search"
            name="amount"
            id="amount"
            className="h-9 shadow-sm focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm border-gray-300 px-4 rounded mr-2 w-36"
            placeholder="税込金額"
            value={ isNaN(Number(amount)) || amount === "" ? amount : php.number_format(Number(amount))}
            onChange={(e) => {
              let removed = e.target.value.replace(/(?!^)-|[^-\d]/g, "");
              setAmount(removed);
              fileView.setNewAmount(Number(removed));
            }}
          />



          <input
            maxLength={12}
            type="search"
            name="tax"
            id="tax"
            className="h-9 shadow-sm focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm border-gray-300 px-4 rounded mr-2 w-36"
            placeholder="消費税"
            value={ isNaN(Number(tax)) || tax === "" ? tax : php.number_format(Number(tax))}
            onChange={(e) => {
              let removed = e.target.value.replace(/(?!^)-|[^-\d]/g, "");
              setTax(removed);
              fileView.setNewTax(Number(removed));
            }}
          />

              </div>
            </dd>


    </div>

    <div className="text-xs font-medium text-gray-500 mt-2">
      <span className={`ml-4 cursor-pointer ${!isNaN(Math.floor(Number(amount) * 8 / 108)) && Math.floor(Number(amount) * 8 / 108) === Number(tax) && 'text-indigo-800'}`}
        onClick={() => {
          const tax = Math.floor(Number(amount) * 8 / 108);
          setTax(tax.toString());
          fileView.setNewTax(tax);
        }}
      >8%切捨
        <span className="ml-2">
          {!isNaN(Math.floor(Number(amount)*8/108)) ? php.number_format(Math.floor(Number(amount)*8/108))+"円" : <span className="text-red-600">不正な金額です</span>}
        </span>
      </span>

        <span className={`ml-4 cursor-pointer ${!isNaN(Math.round(Number(amount) * 8 / 108)) && Math.round(Number(amount) * 8 / 108) === Number(tax) && 'text-indigo-800'}`}
          onClick={() => {
            const tax = Math.round(Number(amount) * 8 / 108);
            setTax(tax.toString());
            fileView.setNewTax(tax);
          }}
      >8%四捨五入
        <span className="ml-2">
          {!isNaN(Math.round(Number(amount)*8/108)) ? php.number_format(Math.round(Number(amount)*8/108))+"円" : <span className="text-red-600">不正な金額です</span>}
        </span>
      </span>

        <span className={`ml-4 cursor-pointer ${!isNaN(Math.ceil(Number(amount) * 8 / 108)) && Math.ceil(Number(amount) * 8 / 108) === Number(tax) && 'text-indigo-800'}`}
          onClick={() => {
            const tax = Math.ceil(Number(amount) * 8 / 108);
            setTax(tax.toString());
            fileView.setNewTax(tax);
          }}
      >8%切上
        <span className="ml-2">
          {!isNaN(Math.ceil(Number(amount)*8/108)) ? php.number_format(Math.ceil(Number(amount)*8/108))+"円" : <span className="text-red-600">不正な金額です</span>}
        </span>
      </span>
    </div>

    <div className="text-xs font-medium text-gray-500 mt-2">
        <span className={`ml-4 cursor-pointer ${!isNaN(Math.floor(Number(amount) * 10 / 110)) && Math.floor(Number(amount) * 10 / 110) === Number(tax) && 'text-indigo-800'}`}
          onClick={() => {
            const tax = Math.floor(Number(amount) * 10 / 110);
            setTax(tax.toString());
            fileView.setNewTax(tax);
          }}
      >10%切捨
        <span className="ml-2">
          {!isNaN(Math.floor(Number(amount)*10/110)) ? php.number_format(Math.floor(Number(amount)*10/110))+"円" : <span className="text-red-600">不正な金額です</span>}
        </span>
      </span>

        <span className={`ml-4 cursor-pointer ${!isNaN(Math.round(Number(amount) * 10 / 110)) && Math.round(Number(amount) * 10 / 110) === Number(tax) && 'text-indigo-800'}`}
          onClick={() => {
            const tax = Math.round(Number(amount) * 10 / 110);
            setTax(tax.toString())
            fileView.setNewTax(tax);
          }}
      >10%四捨五入
        <span className="ml-2">
          {!isNaN(Math.round(Number(amount)*10/110)) ? php.number_format(Math.round(Number(amount)*10/110))+"円" : <span className="text-red-600">不正な金額です</span>}
        </span>
      </span>

        <span className={`ml-4 cursor-pointer ${!isNaN(Math.ceil(Number(amount) * 10 / 110)) && Math.ceil(Number(amount) * 10 / 110) === Number(tax) && 'text-indigo-800'}`}
          onClick={() => {
            const tax = Math.ceil(Number(amount) * 10 / 110);
            setTax(tax.toString())
            fileView.setNewTax(tax);
          }}
      >10%切上
        <span className="ml-2">
          {!isNaN(Math.ceil(Number(amount)*10/110)) ? php.number_format(Math.ceil(Number(amount)*10/110))+"円" : <span className="text-red-600">不正な金額です</span>}
        </span>
      </span>
  </div>
</>
  )
}

export default Amount
