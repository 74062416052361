
import * as php from '../../libs/php_libs';
import {useFileView} from '../../providers/FileViewProvider';
import { TUploadResponse } from "./FileUpload";

export const FileAuditPart = () => {
  const fileView = useFileView();
  const file = fileView.getFile();

  const date = (date: string) => {
    const now = new Date(date.replace(/-/g,"/"));
    const ut = now.getTime() / 1000;
    return php.date("Y/m/d H:i:s", ut);
  }
  return (

    <div className="border-t border-gray-200 px-4 py-5 sm:px-6 min-h-screen overflow-scroll">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10 ">
        <div className="sm:col-span-10">
          <dd className="mt-1 text-sm text-gray-900 ">


            {file?.related_files?.map((value: TUploadResponse, index: number) => {
              return (
          <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
            <div className="px-4 py-3 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">{value.name}</h3>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">HASH(sha256)</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{value.hash}</dd>
                </div>
                <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">ファイルサイズ</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {
                            value.size > 1024
                        ? value.size> 1048576
                          ? Math.round(value.size / 1048576) + "mb" : Math.round(value.size / 1024) + "kb"
                        : value.size + "b"
                          }
                          <span className="ml-4">({php.number_format(value.size)} bytes)</span>
                  </dd>
                </div>
                <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">アップロード日時</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{date(value.date)}</dd>
                </div>
                {/* <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">ファイル所有者</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">uid:{value.account_id}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">ダウンロード数</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"></dd>
                </div> */}
              </dl>
            </div>
          </div>
              )
            })}



          </dd>
        </div>

      </dl>
    </div>
  )
}


export default FileAuditPart;
