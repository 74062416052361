import { SetupMainContainer } from "./SetupMainContainer";
import { SetupMainTab } from "./SetupMainTab";
import { AccountPart } from "./AccountPart";
import { EmailPart } from "./EmailPart";
import { FilePart } from "./FilePart";
import { useSetup } from '../../providers/SetupProvider';
import Breadcrumbs, {TPage} from '../../components/Breadcrumbs';

const subMenu = ["アカウント", "メールアーカイブ設定", "ファイル管理設定" ];

export const SetupMain = () => {
  const setup = useSetup();
  const tabIndex = setup.getTabIndex();
  const pages: TPage[] = [
    { name: '設定', href: '/setup', current: false },
    { name: subMenu[tabIndex], href: '', current: true },
  ];

  return (
    <div className="mt-4">
      {/* <Breadcrumbs pages={pages} /> */}
      <SetupMainContainer>
        <SetupMainTab />
        {setup.getTabIndex() === 0 && <AccountPart />}
        {setup.getTabIndex() === 1 && <EmailPart />}
        {setup.getTabIndex() === 2 && <FilePart />}
      </SetupMainContainer>
    </div>
  );
}

export default SetupMain;
