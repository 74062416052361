import { useState, useEffect } from 'react'
import {useFileView} from '../../providers/FileViewProvider';
import Select from 'react-select';

export type TOption = {
  value: string;
  label: string;
}

export const Suppliers = (props: { options: TOption[] }) => {
  const defaultOptions = props.options;
  const fileView = useFileView();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [options, setOptions] = useState<TOption[]>();
  const [supplier, setSupplier] = useState("");

  // const handleChange = ( e :React.ChangeEvent<HTMLInputElement>) => {
  //   fileView.setSearchSuppliers(e.target.value);
  // };
  useEffect(() => {
    // リストを作成
    let newOptions :TOption[] = [];
    newOptions.push({ label: "", value: "" });  // index:0は入力用
    defaultOptions.forEach((value, index) => {
      if (value.value === fileView.getSearchSuppliers()) {
        setSelectedIndex(index+1);
      }
      newOptions.push(value);
    });
    setOptions(newOptions);
    // eslint-disable-next-line
  }, [defaultOptions]);

  const customStyles = {
    menu: (provided:any, state:any) => ({
      ...provided,
      width: "250px",
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    control: (provided:any, state:any) => ({
      ...provided,
      width: "250px",
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    dropdownIndicator: (provided:any, state:any) => ({
      ...provided,
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    singleValue: (provided:any, state:any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  };

  const handleChange = (supplier: string) => {
    setSupplier(supplier);
    fileView.setSearchSuppliers(supplier);
    let newOptions: TOption[] = [...options!];
    newOptions[0] = { label: "", value: "" };
    setOptions(newOptions);

    if (supplier === "") {
      setSelectedIndex(-1);
    } else {
      newOptions!.forEach((value, index) => {
        if (supplier === value.value) {
          setSelectedIndex(index);
        }
      });
    }
  };

  const handleInput = (supplier: string) => {
    if (supplier !== "") {
      fileView.setSearchSuppliers(supplier);
      setSupplier(supplier);
    }
  };

  return (
    <div className="inline-block ml-4 mt-2">
      <label htmlFor="email" className="block text-sm font-medium text-gray-600">
        取引先
      </label>
      <div className="mt-1">
        {options && <Select
          onBlur={(e) => {
            let newOptions: TOption[] = [...options!];
            newOptions[0] = { label: supplier, value: supplier };
            setOptions(newOptions);
            setSelectedIndex(supplier === "" ? -1 : 0);
          }}
          className="text-gray-600 text-xs inline-block mr-2"
          styles={customStyles}
          options={options}
          value={options[selectedIndex!]}
          placeholder="指定なし"
          isClearable={true}
          noOptionsMessage={({ inputValue }) => !inputValue ? "" : "入力に一致する候補はありません"}
          onChange={(e) => {
            handleChange(e?.value || "");
          }}
          onInputChange={(e) => {
            handleInput(e || "");
          }}
        />
        }
      </div>
    </div>
  )
}

export default Suppliers;
