import {useState, useRef, useEffect } from 'react'
import { useApi } from "../../hooks/useApi";
import { useNavigate } from 'react-router-dom';
import {Spinner} from '../../components/Spinner';
import { useEmailView } from '../../providers/EmailViewProvider';
import { PencilAltIcon, UploadIcon, XIcon } from '@heroicons/react/solid'
//https://react-icons.github.io/react-icons/icons?name=hi

export const Memo = () => {
  const emailView = useEmailView();
  const email = emailView.getEmail();
  const id = email.id;
  const [editMode, setEditMode] = useState(false);
  const [memo, setMemo] = useState("");
  const [orgMemo, setOrgMemo] = useState("");
  const { running, error, data, execute } = useApi();
  const navigate = useNavigate()

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setMemo(email.memo);
    setOrgMemo(email.memo);
    setEditMode(false);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    const current = inputRef.current;
    current && current.focus();
  });

  useEffect(() => {
    if (data) {
      setEditMode(false);
      email.memo = memo;
      emailView.updateEmails(email);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (error?.status === 401) {
      // ローカルストレージから対象のキーに紐づく値を削除
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      navigate('/');
    }
    // eslint-disable-next-line
  }, [error]);

  const saveMemo = () => {
    const params = { memo };
    execute({ url: `/v1/update_email/${id}/`, params });
  };

  const cancelMemo = () => {
    setMemo(orgMemo);
    setEditMode(false);
  };


  return (
    <div className="border-t border-gray-200 px-4 py-1 sm:px-6 my-2">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10">
      {!editMode ?
      (<>
        <div className="sm:col-span-2 ">
          <span className="h-6 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-600 hover:bg-gray-700 text-gray-200 mx-4 cursor-pointer"
              onClick={() => {
                setEditMode(true);
            }
            }>
            <PencilAltIcon className="h-3 w-3 text-gray-400" aria-hidden="true" />
            <span className="ml-1">備考編集</span>
          </span>
        </div>
        <div className="sm:col-span-8">
          {memo ?
              <span className=" text-gray-900 text-xs" dangerouslySetInnerHTML={{ __html: memo.replace(/\r?\n/g, '<br />') }} />
            :
              <span className="text-gray-400 text-xs" >備考は登録されていません。</span>
          }
        </div>
      </>
      ) :
      (<>
        <div className="sm:col-span-2 h-80">
          <span className="h-6 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-600 hover:bg-gray-700 text-gray-200 mx-4 cursor-pointer"
              onClick={() => {
                setEditMode(false);
            }
            }>
            <XIcon className="flex-shrink-0 h-3 w-3 mr-1 focus:shadow-outline focus:outline-none" aria-hidden="true" />
            <span className="ml-0">備考編集</span>
          </span>
        </div>
        <div className="sm:col-span-8">

            <div className="mx- my-4" style={{ display: !editMode ? 'none' : '' }}>
              <span className="inline-block text-xs font-medium text-gray-500">備考</span>
                <textarea
                  tabIndex={0}
                  ref={inputRef}
                  rows={4}
                  name="comment"
                  id="comment"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-300 block w-full sm:text-sm border-gray-300  rounded p-2"
                  placeholder="備考を入力してください。"
                  defaultValue={memo}
                  onChange={(e) => {
                    setMemo(e.target.value);
                  }}
                />
            </div>


            <div className="h-7 inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-blue-600 hover:bg-blue-700 text-white cursor-pointer"
              onClick={() => saveMemo()}
            >
              <UploadIcon className="h-3 w-3 text-white" aria-hidden="true" />
              <span className="ml-1">備考保存</span>
            </div>
            <div className="h-7 inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-gray-300 hover:bg-gray-500 text-white mx-2 cursor-pointer"
              onClick={() => cancelMemo()}
            >
              <span className="ml-1 text-black">キャンセル</span>
            </div>
            <div className="text-xs text-gray-500 mt-4">※編集後、備考保存をクリックしてください。</div>
            <hr className="my-4" />
      {running && <Spinner text="Now Memo API processing..." />}
      {error && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  API実行中にエラーが発生しました。データは更新されていません。
                </span>
      }

        </div>
          </>)}


  </dl>
</div>
  );
};

export default Memo;
