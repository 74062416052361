import {useState, useEffect, useCallback, Fragment, useRef} from 'react'
import { DocumentIcon, TrashIcon, DocumentDownloadIcon } from '@heroicons/react/solid'
import { useApi } from "../../hooks/useApi";
import { useDeleteConfirm } from "../../hooks/useDeleteConfirm";
import { useNavigate } from 'react-router-dom';

import { useDropzone } from 'react-dropzone';
import axios from "axios";

import noFileSelectImg from '../../assets/images/no_file_selected.png'
import {useEmailView} from '../../providers/EmailViewProvider';
import { Spinner } from '../../components/Spinner';

export type TUploadResponse = {
  account_id: number,
  name: string,
  hash: string,
  size: number,
  date: string,
  expire: number,
  key: string,
};

export type TDeleteTarget = {
  name: string,
  hash: string
}

export type TToken = {
  id: number;
  token: string;
  expire: number;
  level: number;
}


//https://tailwindcomponents.com/component/file-upload-with-drop-on-and-preview
export const FileUpload = () => {
  const emailView = useEmailView();
  const email = emailView.getEmail();

  const [files, setFiles] = useState<File[]>([])
  const [uploadFiles, setUploadFiles] = useState<TUploadResponse[]>([])
  //https://www.bezkoder.com/react-hooks-file-upload/
  const [currentFile, setCurrentFile] = useState("");
  const [progress, setProgress] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [accountId, setAccountId] = useState(0);
  const [target, setTarget] = useState<TDeleteTarget>();
  const { deleteOk, setDeleteOpen, setDeleteOk, setDeleteContent, DeleteConfirm } = useDeleteConfirm();
  const navigate = useNavigate()
  const { running, error, data, execute } = useApi();
  const [level, setLevel] = useState(2);

  const api_server = localStorage.getItem("api_server") || "https://archives.mirai.ad.jp/";
  const api = `${api_server}api/arc`;

  useEffect(() => {
    // ローカルストレージからキーを指定して取得
    const api_key = localStorage.getItem("token") || "";
    if (api_key) {
      const token: TToken = JSON.parse(atob(api_key));
      setAccountId(token.id);
      setLevel(token.level);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (deleteOk) {
      target && deleteRelatedFiles(target);
      setDeleteOk(false);
    }
    // eslint-disable-next-line
  },[deleteOk]);



  useEffect(() => {
    setUploadFiles([]);
  },[email]);

  useEffect(() => {
    if (fileCount && uploadFiles && uploadFiles.length === fileCount) {
      saveRelatedFiles();
      setFileCount(0);
    }
    // eslint-disable-next-line
  }, [fileCount, uploadFiles]);

  const OnUpload = (files: File[]) => {
    setFileCount(files.length)
    setUploadFiles([]);
    // サーバーにファイルを1個アップロードする
    const uploadFile = async (file: File, onUploadProgress: (progressEvent: ProgressEvent) => void) => {
        // ローカルストレージからキーを指定して取得
        const token = localStorage.getItem("token") || "";
        let formData = new FormData();
        formData.append("file", file);
        setCurrentFile(file.name);
        await axios.post(`${api}/email_upload/`,
          formData, {
          headers: {
            "X-API-Key": token,
            "X-Email-ID": email.id.toString(),
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            },
          onUploadProgress,
        }).then((response) => {
          response.data.files && response.data.files.forEach((res:TUploadResponse) => {
            setUploadFiles((uploadFiles) => {
              let newUploadFiles = [...uploadFiles];
              newUploadFiles.push(res);
              return newUploadFiles;
            });
            deleteItem(res.name);
          });
        })
    };

    // 順にファイルをアップロードする 非同期にするにはプログレスを修正する必要がある
    files.forEach((file) => {
      // アップロード状況を更新する callback
      const onUploadProgress = (progressEvent: ProgressEvent) => {
        setCurrentFile(file.name);
        const per = progressEvent.lengthComputable ? Math.round((100 * progressEvent.loaded) / progressEvent.total) : 0;
        setProgress(per);
      };
      uploadFile(file, onUploadProgress);
    });
  }

  const onDrop = useCallback((acceptedFiles) => {
    setProgress(0);
    acceptedFiles && acceptedFiles.forEach((file : File) => {
      setFiles((files) => {
        let newFiles: File[] = [];
        let dupeFlag = false;
        files.forEach(v => {
          if (file.name === v.name) {
            dupeFlag = true;
          }
          newFiles.push(v);
        });
        if ( ! dupeFlag ) newFiles.push(file);
        return newFiles;
      });
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const deleteItem = useCallback((name: string) => {
    setFiles((files) => {
      let newFiles: File[] = [];
      files.forEach(v => {
        if (name !== v.name) {
          newFiles.push(v);
        }
      });
      return newFiles;
    });

  }, []);

  useEffect(() => {
    if (data) {
      emailView.updateEmails(email);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (error?.status === 401) {
      // ローカルストレージから対象のキーに紐づく値を削除
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      navigate('/');
    }
    // eslint-disable-next-line
  }, [error]);

    // DBのアップロード済み情報を更新する
  const saveRelatedFiles = () => {
    let newRelatedFiles:TUploadResponse[] = [];
    if (email?.related_files) {
      newRelatedFiles = [...email.related_files];
    }
    uploadFiles.forEach((newValue) => {
      let dupeFlag = false;
      email?.related_files?.forEach((oldValue) => {
        if (oldValue.hash === newValue.hash) dupeFlag = true;
      });
      if (!dupeFlag) newRelatedFiles.push(newValue);
    });
    email.related_files = newRelatedFiles;

    const params = {
      related_files: newRelatedFiles,
      upload_files: uploadFiles
    };
    execute({ url: `/v1/update_email/${email.id}/`, params });
  };

  // ファイルを指定して削除する
  const deleteRelatedFiles = (target:TDeleteTarget) => {
    let newRelatedFiles:TUploadResponse[] = [];
    email?.related_files?.forEach((value) => {
      if (value.hash !== target.hash) newRelatedFiles.push(value);
    });
    email.related_files = newRelatedFiles;

    const delete_files: TDeleteTarget[] = [];
    delete_files.push(target);
    const params = {
      related_files: newRelatedFiles,
      delete_files
    };
    execute({ url: `/v1/update_email/${email.id}/`, params });
  };

  //upload参考
  //https://chaika.hatenablog.com/entry/2020/08/07/160000
  //https://www.bezkoder.com/react-file-upload-axios/
  //https://www.bezkoder.com/react-hooks-file-upload/


  return (
<div>
  <div className="border-t border-gray-200 px-4 py-5 sm:px-6 min-h-screen">
    <main className="container mx-auto max-w-screen-lg h-full">

                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 ml-4 mb-4">
                  ※必要に応じて、ダウンロード形式のファイルや、パスワード解除済みのファイル等をアップロードしてください。
                </span>

      {/* file upload modal */}
          <article tabIndex={0} aria-label="File Upload Modal" className="relative h-full flex flex-col bg-white shadow-xl rounded-md">
          {/* overlay */}
          {isDragActive && (
            <div id="overlay" className="w-full h-full absolute top-0 left-0 pointer-events-none z-50 flex flex-col items-center justify-center rounded-md">
              <i>
                <svg className="fill-current w-12 h-12 mb-3 text-blue-700" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z" />
                </svg>
              </i>
              <span className="text-xs text-blue-700">ファイルをドロップしてアップロードしてください。</span>
            </div>
          )}

        {/* scroll area */}
        <section className={`h-full overflow-auto p-8 w-full flex flex-col  ${isDragActive && `opacity-30`}`} >
          <div {...getRootProps()} >
            <input {...getInputProps()} />
            <header className="border-dashed border-2 border-gray-400 py-6 flex flex-col justify-center items-center bg-blue-50 hover:bg-blue-100">
              <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">
                  <span className="text-gray-400 text-sm">アップロードするファイルを、<span className="text-blue-400">この枠内にドロップする</span>か、ファイルを選択してください。</span>
              </p>
                <button id="button" className="mt-2 rounded-sm px-3 py-0 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none">
                  <span className="text-sm">ファイルを選択する</span>
                </button>

            </header>
            </div>
          <h1 className="pt-8 pb-3 font-semibold sm:text-sm text-gray-400">
            アップロードするファイル
          </h1>

          <ul id="gallery" className="flex flex-1 flex-wrap -m-1">
                {files.length === 0 ? (
                  <li id="empty" className="h-full w-full text-center flex flex-col items-center justify-center">
                    <img className="mx-auto w-32" src={noFileSelectImg} alt="no data" />
                    <span className="text-xs text-gray-500">ファイルが選択されていません。</span>
                  </li>)
                  : files.length && files.map((file, key) => {

                  const objectURL = URL.createObjectURL(file);
                  return file.type.match("image.*") ? (
                        <li className="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24 " key={key} >
                          <article tabIndex={0} className="group hasImage w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative text-transparent hover:text-white shadow-sm ">
                            <img alt={file.name} src={objectURL} className="img-preview w-full h-full sticky object-cover rounded-md bg-fixed " />

                            <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3 hover:bg-black hover:opacity-60">
                            <h1 className="flex-1">{file.name}</h1>
                              <div className="flex">
                                <span className="p-1">
                                  <i>
                                    <svg className="fill-current w-4 h-4 ml-auto pt-" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                      <path d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z" />
                                    </svg>
                                  </i>
                                </span>

                                <p className="p-1 size text-xs">
                              {
                                file.size > 1024
                                  ? file.size > 1048576
                                    ? Math.round(file.size / 1048576) + "mb" : Math.round(file.size / 1024) + "kb"
                                  : file.size + "b"
                              }
                                </p>
                              <button className="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md" onClick={() => deleteItem(file.name)} >
                                  <svg className="pointer-events-none fill-current w-4 h-4 ml-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path className="pointer-events-none" d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                                  </svg>
                                </button>
                              </div>
                            </section>
                          </article>
                        </li>
                      ):
                      (
                        <li className="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24" key={key}>
                          <article tabIndex={0} className="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm">
                          <img alt="upload preview" className="img-preview hidden w-full h-full sticky object-cover rounded-md bg-fixed" />

                          <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                            <h1 className="flex-1 group-hover:text-blue-800">{file.name}</h1>
                            <div className="flex">
                              <span className="p-1 text-blue-800">
                                <i>
                                  <svg className="fill-current w-4 h-4 ml-auto pt-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z" />
                                  </svg>
                                </i>
                              </span>
                              <p className="p-1 size text-xs text-gray-700">
                              {
                                file.size > 1024
                                  ? file.size > 1048576
                                    ? Math.round(file.size / 1048576) + "mb" : Math.round(file.size / 1024) + "kb"
                                  : file.size + "b"
                              }
                              </p>
                                <button className="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md text-gray-800" onClick={() => deleteItem(file.name) }>
                                <svg className="pointer-events-none fill-current w-4 h-4 ml-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                  <path className="pointer-events-none" d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                                </svg>
                              </button>
                            </div>
                          </section>
                        </article>
                      </li>
                    );


                  })
                }
          </ul>
        </section>

        {/* progress bar */}
        {progress && files.length && progress < 100 ?(
          <div className="m-10">
            <div className="mb-1 flex justify-between">
              <span className="text-base text-blue-500 font-medium">{currentFile}</span>
              <span className="text-sm font-medium text-blue-500">{progress}%</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
            </div>
          </div>
        ): <></>}

      {running && <Spinner text="Now File API processing..." />}
      {error && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  API実行中にエラーが発生しました。データは更新されていません。
                </span>
      }

        {/* sticky footer */}
        <footer className={`flex justify-end px-8 pb-8 pt-4 ${isDragActive && `opacity-30`}`}>
          { progress && files.length ?
            (
            <button id="submit" className="rounded px-3 py-1 bg-gray-500 text-white focus:shadow-outline focus:outline-none cursor-default">
              <span className="text-sm" >アップロード</span>
            </button>
            ) : (
            <button id="submit" className="rounded px-3 py-1 bg-blue-600 hover:bg-blue-700 text-white font-bold focus:shadow-outline focus:outline-none"
              onClick={() => { OnUpload(files) }}>
              <span className="text-sm" >アップロード</span>
            </button>
          )}
            <button id="cancel" className="ml-3 rounded px-3 py-1 hover:bg-gray-300 font-bold focus:shadow-outline focus:outline-none bg-gray-200"
              onClick={() => { setFiles([])}}>
                <span className="text-sm"  >キャンセル</span>
            </button>

        </footer>

      </article>
    </main>

    <div className="m-10">

          {/* {uploadFiles?.length ?
            <dt className="text-sm font-medium text-gray-500">新規アップロードファイル</dt>
            :
            <dt className="text-sm font-medium text-gray-500"></dt>
          }

      <dd className="mt-1 text-sm text-gray-900">
        <ul className=" divide-y divide-gray-200">
            {uploadFiles?.map((value: TUploadResponse, index:number) => {
              return (
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-200 rounded-md mt-2 shadow-md hover:bg-gray-50" key={index}>
                  <div className="w-0 flex-1 flex items-center">
                    <DocumentIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="ml-2 flex-1 w-0 truncate">{value.name}</span><br />

                  </div>
                                        <span className="ml-2 text-gray-500">{value.date}</span>
                  <div className="ml-4 flex-shrink-0">

                    <span className="ml-2 text-gray-500">({
                      value.size > 1024
                        ? value.size> 1048576
                          ? Math.round(value.size / 1048576) + "mb" : Math.round(value.size / 1024) + "kb"
                        : value.size + "b"
                    }) </span>

                  </div>
                </li>
              )
            })
          }
        </ul>
      </dd> */}

          {email?.related_files?.length ?
            <dt className="text-sm font-medium text-gray-500 mt-4">アップロード済ファイル</dt>
            :
            <dt className="text-sm font-medium text-gray-500 mt-4">追加されたファイルはありません</dt>
          }

      <dd className="mt-1 text-sm text-gray-900">
        <ul className=" divide-y divide-gray-200">
            {email?.related_files?.map((value: TUploadResponse, index:number) => {
              return (
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-200 rounded-md mt-2 shadow-md hover:bg-gray-50" key={index}>
                  <div className="w-0 flex-1 flex items-center">
                    <DocumentIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="ml-2 flex-1 w-0 truncate">{value.name}</span><br />

                  </div>
                                        <span className="ml-2 text-gray-500">{value.date}</span>
                  <div className="ml-4 flex-shrink-0">

                    <span className="ml-2 text-gray-500">({
                      value.size > 1024
                        ? value.size> 1048576
                          ? Math.round(value.size / 1048576) + "mb" : Math.round(value.size / 1024) + "kb"
                        : value.size + "b"
                    }) </span>

                    {/* Header set Access-Control-Allow-Origin を .htaccessに設定"*" */}
                    <a href={`${api}/download/${email.id}/${value.hash}/e/${value.expire}/${value.key}/${accountId}`} >
                      <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded inline-flex items-center">
                        <DocumentDownloadIcon className="h-5 w-4 mr-1" />
                        <span className="text-xs">ダウンロード</span>
                      </button>
                    </a>

                    {accountId === value.account_id || level >= 4 ? (
                      <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-1 px-2 rounded inline-flex items-center w-16 ml-4"
                        onClick={() => {
                          setTarget({ name: value.name, hash: value.hash });
                          setDeleteContent(value.name);
                          setDeleteOpen(true);
                        }
                        }>
                        <TrashIcon className="h-5 w-4 mr-1" />
                        <span className="text-xs"
                        >削除</span>
                      </button>
                    ) : (
                      <button className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-1 px-2 rounded inline-flex items-center w-16 ml-4 cursor-default ">
                        <TrashIcon className="h-5 w-4 mr-1" />
                        <span className="text-xs"
                        >削除</span>
                      </button>
                    )}
                      <DeleteConfirm />
                  </div>
                </li>
              )
            })
          }
        </ul>
      </dd>

    </div>


  </div>
</div>
  );
}

export default FileUpload;
