import { useEffect, useState } from 'react';
import * as php from '../../libs/php_libs';
import { useApi } from "../../hooks/useApi";
import {Spinner} from '../../components/Spinner';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";
import {  DocumentDownloadIcon } from '@heroicons/react/solid'

import {useFileView} from '../../providers/FileViewProvider';

export type TLogs = {
  id: number;
  name: string;
  email_id: string;
  category: string;
  operation: string;
  description: string;
  remote_addr: string;
  created_at: string;
}

type TCsvData = {
  id: number;
  name: string;
  description: string;
  remote_addr: string;
  date: string;
}

// csvファイルの１行目にあたる部分を headers で指定できる
const csvHeaders = [
  { label: "名前", key: "name" },
  { label: "内容", key: "description" },
  { label: "リモートIPアドレス", key: "remote_addr" },
  { label: "日時", key: "date" },
];

export const DownloadLog = () => {
  const fileView = useFileView();
  const file = fileView.getFile();
  const [logs, setLogs] = useState<TLogs[]>([]);
  const [csvData, setCsvData] = useState<TCsvData[]>([]);
  const { running, error, data, execute } = useApi();
  const navigate = useNavigate()

  useEffect(() => {
    execute({ url: `/v1/get_file_download_log/${file.id}`, params: {}});
    // eslint-disable-next-line
  }, [fileView]);

  // APIで取得したlogsを摘要
  useEffect(() => {
    if (data && data?.logs ) {
      setLogs(data.logs);
      // 表示用のデータをCSV用のデータに変換する
      let csv: TCsvData[] = [];
      data.logs.forEach((value:TLogs) => {
        const dt = new Date(value.created_at.replace(/-/g,"/"));
        const ut = dt.getTime() / 1000;
        csv.push({
          id: value.id,
          name: value.name,
          description: value.description,
          remote_addr: value.remote_addr,
          date: php.date("Y/m/d H:i:s", ut),
        });
      })
      setCsvData(csv);
    };
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (error?.status === 401) {
      // ローカルストレージから対象のキーに紐づく値を削除
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      navigate('/');
    }
    // eslint-disable-next-line
  }, [error]);

  return (logs && logs.length > 0) || running? (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6 min-h-screen overflow-scroll">

      <div className="text-right mb-2 mr-2">
        <CSVLink data={csvData} headers={csvHeaders} filename={`[ダウンロード履歴]管理番号:${file.id}_${file.supplier}_${file.project}.utf8.csv`}>
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded inline-flex items-center">
            <DocumentDownloadIcon className="h-6 w-4 mr-1" />
          <span className="text-sm">CSVダウンロード</span>
          </button>
        </CSVLink>
      </div>

      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10 ">
        <div className="sm:col-span-10">
          <dd className="mt-1 text-sm text-gray-900 ">


          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 table-fixed">
                    <thead className="bg-gray-50">



                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12"
                  >
                    名前
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-5/12"
                  >
                    内容
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-3/12"
                  >
                    リモートIPアドレス
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12"
                  >
                    日時
                  </th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">{log.name}</td>
                    <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                      <div dangerouslySetInnerHTML={{ __html: log?.description?.replace(/\r?\n/g, '<br />')}}/>
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">{log.remote_addr}</td>
                    <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">{log.created_at.replace(/-/g,"/")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


          </dd>
        </div>


      </dl>

      {running && <Spinner text="Now Log API processing..." />}
      {error && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  API実行中にエラーが発生しました。
                </span>
      }

    </div>
  ) : (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6 min-h-screen overflow-scroll">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10 ">
        <div className="sm:col-span-10">
          <dd className="mt-1 text-sm text-gray-900 ">
            <span className="text-gray-400">ダウンロード履歴はありません。</span>
          </dd>
        </div>
      </dl>
    </div>
  )
}


export default DownloadLog;
