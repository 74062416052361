import { useEffect, useState} from 'react';
import * as php from '../../libs/php_libs';
import { Spinner } from '../Spinner';
import { DateRangePicker } from './DateRangePicker';
import {useFileView, TSupplier, TProject} from '../../providers/FileViewProvider';
import { useApi } from "../../hooks/useApi";
import { FileList } from '../FileList';
import {Suppliers} from "./Suppliers";
import {Project} from "./Project";
import {Memo} from "./Memo";
import {ProjectID} from "./ProjectID";
import {AmountRange} from "./AmountRange";
import {FileName} from "./FileName";
import {FileUploadMain} from "../FileUploadMain";
import {MetaTagSelect, TMetatag} from "./MetaTagSelect";
import Breadcrumbs, {TPage} from '../../components/Breadcrumbs';

export type TToken = {
  id: number;
  token: string;
  expire: number;
  level: number;
}

export type TOption = {
  value: string;
  label: string;
}

const subMenu = ["ファイル", "監査情報", "操作履歴", "ダウンロード履歴" ];

export const FileSearch = () => {
  const fileView = useFileView();
  const [fileCount, setFileCount] = useState(0);
  const [fileTotalCount, setFileTotalCount] = useState(0);
  const [metaTags, setMetaTags] = useState<TMetatag[]>([]);
  const [suppliersOptions, setSuppliersOptions] = useState<TOption[]>([]);
  const [projectsOptions, setProjectsOptions] = useState<TOption[]>([]);
  const { running:runningFile , data:dataFile, error:errorFile, execute:executeFile } = useApi();
  const { running:runningConfig , data:dataConfig, error:errorConfig, execute:executeConfig } = useApi();
  const tabIndex = fileView.getTabIndex();
  const [level, setLevel] = useState(0);
  const pages: TPage[] = fileView.getOpen() ?
    [
      { name: 'ファイル管理', href: '/file', current: false },
      { name: '新規案件登録', href: '', current: true },
    ]
    :
    dataFile ?
    [
      { name: 'ファイル管理', href: '/file', current: false },
      { name: 'ファイル検索', href: '/', current: true },
      { name:  subMenu[tabIndex], href: '', current: true },
    ]
    :
    [
      { name: 'ファイル管理', href: '/file', current: false },
      { name: 'ファイル検索', href: '', current: true },
    ];

  useEffect(() => {
    // executeFile({ url: "/v1/get_files",  });
    executeConfig({ url: "/v1/get_file_config" });

    const api_key = localStorage.getItem("token") || "";
    if (api_key) {
      const token: TToken = JSON.parse(atob(api_key));
      setLevel(token.level);
    }
    // ローカルストレージからキーを指定して取得
    let project_id = localStorage.getItem("project_id") || "";
    let project_hash = localStorage.getItem("project_hash") || "";
    if (project_id && project_hash) {
      // ローカルストレージから対象のキーに紐づく値を削除
      // localStorage.removeItem("project_id");
      // localStorage.removeItem("project_hash");

      const params = {
        "project_id": Number(project_id),
        "project_hash": project_hash,
      };
      executeFile({ url: "/v1/get_files", params });

    }
    // eslint-disable-next-line
  }, []);

  // APIで取得したfilesを摘要
  useEffect(() => {
    if (dataFile) {
      dataFile?.files && setFileCount(dataFile.files.length);


      setTimeout(() => {
        // fileView.setCurrentRow(34);
      },2000);

    };
    // eslint-disable-next-line
  }, [dataFile]);

  // APIで取得したconfigを摘要
  useEffect(() => {
    if (dataConfig) {
      setFileTotalCount(dataConfig?.config?.files?.count);
      setMetaTags(dataConfig?.config?.meta_tags);
      const config = {
        allMetaTags: dataConfig?.config?.all_meta_tags,
        suppliers: dataConfig?.config?.suppliers,
        projects: dataConfig?.config?.projects,
      };
      fileView.setConfig(config);
      // // react-select用にoptionsを生成する
      let tempSuppliersOptions: TOption[] = [];
      if (dataConfig?.config?.suppliers) {
        dataConfig.config.suppliers.forEach( (value:TSupplier) => {
          tempSuppliersOptions.push(
            {
              value: value.supplier,
              label: `${value.supplier} (${value.count})`,
            }
          );
        });
        setSuppliersOptions(tempSuppliersOptions);
      }
      // // react-select用にoptionsを生成する
      let tempProjectsOptions: TOption[] = [];
      if (dataConfig?.config?.suppliers) {
        dataConfig.config.projects.forEach( (value:TProject) => {
          tempProjectsOptions.push(
            {
              value: value.project,
              label: `${value.project} (${value.count})`,
            }
          );
        });
        setProjectsOptions(tempProjectsOptions);
      }

    };
    // eslint-disable-next-line
  }, [dataConfig])

  // ファイル検索実行
  const handleClick = () => {
    const params = {
      "meta_tag": fileView.getSelectedMetaTag(),
      "date_range": fileView.getDateRange(),
      "amount_range": fileView.getAmountRange(),
      "search_suppliers": fileView.getSearchSuppliers(),
      "search_project": fileView.getSearchProject(),
      "search_projectID": fileView.getSearchProjectID(),
      "search_filename": fileView.getSearchFileName(),
      "search_memo": fileView.getSearchMemo(),
      "hash": fileView.getHash(),
    };
    executeFile({ url: "/v1/get_files", params });
    executeConfig({ url: "/v1/get_file_config" });
  };

  return level > 1 ? (
    <>
      {/* <Breadcrumbs pages={pages} /> */}
      <FileUploadMain />
      <div className="bg-gray-100 shadow  rounded-md m-4">
        <div className="grid  grid-cols-1 gap-x-4  sm:grid-cols-12">

          <div className="px-2 pt-1 sm:col-span-10">
            <ProjectID />
            <Suppliers options={suppliersOptions} />
            <Project options={projectsOptions} />
          </div>

          <div className="px-2 py-1  sm:col-span-2 items-center row-span-2 mt-2">
          {!(runningFile ||runningConfig) ? (
            <div>
              <button id="submit" className="mt-4 rounded px-3 py-1 bg-blue-600 hover:bg-blue-700 text-white font-bold focus:shadow-outline focus:outline-none"
                  onClick={handleClick}>
                <span className="text-sm" >ファイル検索</span>
              </button>
              <div className="mt-2 items-center">
                <span className="text-sm text-gray-600 ">{php.number_format(fileCount)} / {php.number_format(fileTotalCount)} 件</span>
              </div>
            </div>
          ) : (
            <div>
              <button id="submit" className="mt-4 rounded px-3 py-1 bg-gray-500  text-white font-bold focus:shadow-outline focus:outline-none cursor-wait">
                <span className="text-sm" onClick={handleClick} >ファイル検索</span>
              </button>
              <div className="mt-2 items-center">
                    <span className="text-sm text-gray-600 ">{php.number_format(fileCount)} / {php.number_format(fileTotalCount)} 件</span>
              </div>
            </div>
          )}
          </div>

          <div className="px-2 pt-1  sm:col-span-10">
            <MetaTagSelect metaTags={metaTags}/>
            <Memo />
            <FileName />
          </div>

          <div className="px-2 py-2 sm:col-span-10">
            <DateRangePicker />
            <AmountRange />
          </div>

        </div>

      </div>


      {dataFile && <FileList files={dataFile.files} />}
      {runningFile && <Spinner text="Now File API processing..." />}
      {runningConfig && <Spinner text="Now Config API processing..." />}
      {errorFile && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  API実行中にエラーが発生しました。
                </span>
      }
      {errorConfig && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  API実行中にエラーが発生しました。
                </span>
      }

    </>
  ) : (
      <>
      {dataFile && <FileList files={dataFile.files} />}
      {runningFile && <Spinner text="Now File API processing..." />}
      {runningConfig && <Spinner text="Now Config API processing..." />}
      {errorFile && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  API実行中にエラーが発生しました。
                </span>
      }
      {errorConfig && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  API実行中にエラーが発生しました。
                </span>
      }
      </>
  )
}

export default FileSearch
