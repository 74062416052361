import {useEmailView} from '../../providers/EmailViewProvider';
import { useEffect, useState} from 'react';

export const SearchEmailID = () => {
  const emailView = useEmailView();
  const [defaultValue, setDefaultValue] = useState("");

  useEffect(() => {
    // ローカルストレージからキーを指定して取得
    let email_id = localStorage.getItem("email_id") || "";
    let email_hash = localStorage.getItem("email_hash") || "";
    if (email_id && email_hash ) {
      email_id = email_id.replace(/(?!^)-|[^-\d]/g, "");
      setDefaultValue(email_id);
      emailView.setSearchEmailID(Number(email_id));
      console.log("***********setSearchEmailID ************* ");
      console.log(Number(email_id));
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = ( e :React.ChangeEvent<HTMLInputElement>) => {
    const emailIDRemoved = e.target.value.replace(/(?!^)-|[^-\d]/g, "");
    emailView.setSearchEmailID(Number(emailIDRemoved));
    setDefaultValue(emailIDRemoved);
  };

  return (
    <div className="inline-block ml-4 mt-2">
      <label htmlFor="email" className="block text-sm font-medium text-gray-600">
        管理番号
      </label>
      <div className="mt-1">
        <input
          onChange={handleChange}
          type="search"
          name="searchEmailID"
          id="searchEmailID"
          className="h-9 focus:ring-indigo-500 focus:border-indigo-500 block w-30 sm:text-sm border-gray-300 rounded px-2 py-0.5"
          placeholder="指定なし"
          value={defaultValue}
        />
      </div>
    </div>
  )
}

export default SearchEmailID;
