import {useEmailView} from '../../providers/EmailViewProvider';

export const SearchWord = () => {
  const emailView = useEmailView();

  const handleChange = ( e :React.ChangeEvent<HTMLInputElement>) => {
    emailView.setSearchWord(e.target.value);
  };

  return (
    <div className="inline-block ml-4 mt-2">
      <label htmlFor="email" className="block text-sm font-medium text-gray-600">
        検索ワード
      </label>
      <div className="mt-1">
        <input
          onChange={handleChange}
          type="search"
          name="searchWord"
          id="searchWord"
          className="h-9 focus:ring-indigo-500 focus:border-indigo-500 block w-80 sm:text-sm border-gray-300 rounded px-2 py-0.5"
          placeholder="指定なし"
        />
      </div>
    </div>
  )
}

export default SearchWord;
