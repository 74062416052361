import {useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from "../hooks/useApi";
import {Spinner} from '../components/Spinner';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import { version } from './Login';

export type TToken = {
  id: number;
  token: string;
  expire: number;
  level: number;
}

const Reset = () => {
  //https://ichi.pro/react-router-v-6-no-shin-kino-54507686506060
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [loginIDError, setLoginIDError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginID, setLoginID] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const { running, error, data, execute } = useApi();
  const { running:unningChange, error:errorChange, data:dataChange, execute:executeChange } = useApi();
  const inputRef = useRef<HTMLInputElement>(null);
  const { id, key } = useParams();

  useEffect(() => {
    console.log(id, key);
    if (id && key) {
      const params = {
        id,
        key
      };
      execute({ url: "/v1/forget_auth", params, redirect: false });
    }
    // eslint-disable-next-line
  }, [id, key]);

  useEffect(() => {
    if (error && error?.status === 401) {
      setAuthError(true);
    }
    if (data ) {
      setLoginID(data.login_id);
      const current = inputRef.current;
      current && current.focus();
    }
    // eslint-disable-next-line
  }, [error, data]);

  useEffect(() => {
    console.log("===== dataChange ====");
    console.log(dataChange);
    if (dataChange && dataChange?.api_key) {
      const api_key = dataChange?.api_key || "";
      const name = dataChange?.name || "";
      // ローカルストレージにキーを指定して、それに紐づく値を保存
      localStorage.setItem('token', api_key);
      localStorage.setItem('name',  name);
      localStorage.setItem('id',  loginID);
      navigate('/login');
    }
    // eslint-disable-next-line
  }, [dataChange]);

  const changePassword = (e:React.ChangeEvent<HTMLInputElement>) => {
    setLoginPassword(e.target.value);
    setAuthError(false);
    setPasswordError(false);
  };

  const change = () => {
    loginID.length === 0 && setLoginIDError(true);
    loginPassword.length === 0 && setPasswordError(true);

    const params = {
      id,
      key,
      login_password : loginPassword,
    };
    if (loginPassword.length) {
      executeChange({ url: "/v1/reset", params, redirect: true});
    }
  };

  return (
    <>
      <div className="h-screen flex flex-col py-12 bg-gray-200 ">

        <div className="bg-gray-800 top-0 w-full absolute">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8 ">
            <div className="mt-8 md:mt-0 md:order-1 w-full ">
                  <div className="font-serif text-3xl font-black">
                    <span className="text-blue-900 gradient">＠Archives!</span>
                  </div>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:w-full sm:max-w-md mt-20 text-center">
          <div>
            <span className="mt-6 text-center text-2xl font-extrabold text-gray-900">電子帳簿保存アーカイブ</span>
            <span className="text-gra-600 text-xs ml-4">Ver.{version}</span>
          </div>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="space-y-6" >
              <div className="text-lg font-bold text-gray-600 border-b border-gray-200">パスワード再設定</div>
              <div className="">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  ログインID
                </label>
                <div className="mt-1">
                  <input
                    value={loginID}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    readOnly
                    disabled
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                {loginIDError && (
                <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-1 rounded relative mt-3" role="alert">
                  <span className="block sm:inline text-sm">ログインID が入力されていません。</span>
                </div>
                )}
              </div>


              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  新しいパスワード
                </label>
                <div className="mt-1">
                  <div className="relative">
                    <input
                      onChange={changePassword}
                      value={loginPassword}
                      ref={inputRef}
                      id="password"
                      name="password"
                      type={show ? "text": "password" }
                      autoComplete="current-password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                      {!show && <EyeIcon width={24} color="#909090" cursor="pointer" onClick={() => {setShow(true)}} />}
                      {show && <EyeOffIcon width={24} color="#909090" cursor="pointer" onClick={() => {setShow(false)}}/>}
                    </div>
                  </div>
                </div>
                </div>


              <div className="text-sm text-gray-600">
                パスワードは、8文字以上で指定してください。
              </div>





              {authError && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                  <strong className="font-bold text-sm">認証エラー</strong><br />
                  <span className="block sm:inline text-sm">パスワード再設定URLが無効です。</span>
                  <span className="absolute top-0 bottom-0 right-0 px-3 py-3"
                    onClick={() => setAuthError(false)}>
                    <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                  </span>
                </div>
              )}



              <div>
                {!running && !authError ?(
                <button
                  onClick={change}
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 "
                >
                  <strong className="font-bold text-sm">パスワード再設定</strong>
                </button>
                ) : (
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 cursor-default"
                >
                  <strong className="font-bold text-sm">パスワード再設定</strong>
                </button>
                )}
              </div>
            </div>
      {running && <Spinner text="Now Login processing..." />}
      {error && error.status !== 401 && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  パスワード再設定処理中にエラーが発生しました。<br />しばらくたってから再度お試しください。
                </span>
      }
      {errorChange && errorChange.status !== 401 && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  パスワード再設定処理中にエラーが発生しました。<br />しばらくたってから再度お試しください。
                </span>
      }



          </div>
        </div>


        <div className="bg-gray-100 bottom-0 w-full absolute">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8 ">
            <div className="mt-8 md:mt-0 md:order-1 w-full ">
              <p className=" text-center text-base text-gray-400">&copy; 2022 Mirai Communication Network, Inc. All rights reserved.</p>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}

export default Reset;
