import { useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

export type TUseApi = {
  url: string;
  params?: any;
  option?: any;
  redirect?: boolean;
};


export const useApi = () => {
  const [running, setRunning] = useState(false);
  const [error, setError] = useState<any>();
  const [data, setData] = useState<any>();
  const [response, setResponse] = useState<any>();
  const navigate = useNavigate()
  // const location = useLocation();

  const init = () => {
    setError(undefined);
    setData(undefined);
    setResponse(undefined);
    setRunning(false);
  };

  const execute = async ({ url, params = {}, option = {}, redirect = true }: TUseApi) => {
    const api_server = localStorage.getItem("api_server") || "https://archives.mirai.ad.jp/";
    const api = `${api_server}api/arc`;

    setRunning(true);
    setError(undefined);
    setData(undefined);
    setResponse(undefined);
    // ローカルストレージからキーを指定して取得
    const token = localStorage.getItem("token") || "";
    url && axios.post(
        api + url,
        params,
        {
          headers: {
            "X-API-Key": token,
            "Accept": "application/json",
            "Content-Type": "application/json;charset=utf-8",
          },
          // baseURL: "http://localhost:3000",
          withCredentials: true,
          ...option
        }
      )
        .then((res) => {
          setRunning(false);
          if (res.data?.renew_api_key) {
            // API Keyを更新する
            localStorage.setItem('token', res.data?.renew_api_key);
          }
          setData(res.data);
          setResponse(res);
        })
      .catch((err) => {
        setRunning(false);
        console.log(err);
        if (err?.response) {
          const status = err.response?.status || 200;
          setError(err.response);
          setResponse(err.response);
          if (status === 401) {
            // ローカルストレージから対象のキーに紐づく値を削除
            localStorage.removeItem("token");
            localStorage.removeItem("name");
            if ( redirect ) navigate('/');
          }
        }
      });
  };

  return { running, error, data, response, execute, init };
};

export default useApi;
