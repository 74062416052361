import React, { useState, createContext, useContext } from "react";
import { TFile } from "../components/FileView";
import { TTagData, TAllTag } from "../components/EmailView/MetaTag";

export type TDateRange = {
  fromDate: number;
  toDate: number;
}

export type TAmountRange = {
  fromAmount: number;
  toAmount: number;
}

export type TSupplier = {
  supplier: string,
  count: number
}

export type TProject = {
  project: string,
  count: number
}

export type TConfig = {
  allMetaTags?: TAllTag[];
  suppliers?: TSupplier[];
  projects?: TProject[];
};

export type TNewRegistData = {
  newSupplier: string;
  newProject: string;
  newProjectID: number;
  newAmount: number;
  newTax: number;
  newMetaTags: string[];
  newMemo: string;
};

export type TFileView = {
  tabIndex?: number;
  files?: TFile[];
  currentRow?: number;
  selectedMetaTag?: string;
  dateRange?: TDateRange;
  searchFileName?: string;
  searchMemo?: string;
  searchProject?: string;
  searchProjectID?: number;
  searchSuppliers?: string;
  amountRange?: TAmountRange;
  config?: TConfig;
  //
  newSupplier?: string;
  newProject?: string;
  newProjectID?: number;
  newAmount?: number;
  newTax?: number;
  newMetaTags?: string[];
  newMemo?: string;
  //
  open?: boolean; // 新規案件登録が開いているか
  //
  hash?: string;
};

export type TFileViewContextType = {
  fileView: TFileView | null;
  setTabIndex: (tabIndex :number) => void;
  getTabIndex: () => number;
  setFiles: (files: TFile[]) => void;
  getFiles: () => TFile[];
  getFile: () => TFile;
  setCurrentRow: (currentRow :number) => void;
  getCurrentRow: () => number;
  updateFiles: (file: TFile) => void;
  addFile: (file: TFile) => void;
  getAllMetatags: () => TAllTag[];
  setSelectedMetaTag: (selectedMetaTag :string) => void;
  getSelectedMetaTag: () => string;
  setDateRange: ( dateRange: TDateRange) => void;
  getDateRange: () => TDateRange;
  setAmountRange: ( amountRange: TAmountRange) => void;
  getAmountRange: () => TAmountRange;
  setSearchFileName: (searcFileName :string) => void;
  getSearchFileName: () => string;
  setSearchMemo: (searcMemo :string) => void;
  getSearchMemo: () => string;
  setSearchProjectID: (searcProject :number) => void;
  getSearchProjectID: () => number;
  setSearchProject: (searcProject :string) => void;
  getSearchProject: () => string;
  setSearchSuppliers: (searcSuppliers :string) => void;
  getSearchSuppliers: () => string;
  getSuppliers: () => TSupplier[];
  getProjects: () => TProject[];
  setConfig: (config: TConfig) => void;
  //
  setNewSupplier: (newSupplier :string) => void;
  setNewProject: (newProject :string) => void;
  setNewAmount: (newAmount :number) => void;
  setNewTax: (newTax :number) => void;
  setNewMetaTags: (newMetaTags :string[]) => void;
  setNewMemo: (newMemo :string) => void;
  getNewRegistData: () => TNewRegistData;
  clearNewRegistData: () => void;
  //
  setOpen: (open :boolean) => void;
  getOpen: () => boolean;
  //
  setHash: (hash :string) => void;
  getHash: () => string;
};


// https://qiita.com/johnmackay150/items/88654e5064290c24a32a
function createCtx<ContextType>() {
  const ctx = createContext<ContextType | undefined>(undefined);
  function useCtx() {
    const c = useContext(ctx);
    if (!c) throw new Error("useCtx must be inside a Provider with a value");
    return c;
  }
  return [useCtx, ctx.Provider] as const;
}

export const [useFileView, SetFileViewProvider] = createCtx<TFileViewContextType>();

export const FileViewProvider: React.FC = (props) => {
  const filelView = useFileViewCtx();
  return <SetFileViewProvider value={filelView}>{props.children}</SetFileViewProvider>;
}

const useFileViewCtx = (): TFileViewContextType => {
  const [fileView, setFileView] = useState<TFileView | null>(null);

  const setTabIndex = (tabIndex: number) => {
    setFileView({ ...fileView, tabIndex });
  };

  const getTabIndex = () => {
    return fileView ? fileView!.tabIndex ? fileView!.tabIndex : 0 : 0;
  };

  const setFiles = (files: TFile[]) => {
    setFileView({ ...fileView, files });
  };

  const getFiles = () => {
    return !fileView ? [] : fileView.files ? fileView.files : [];
  };

  const getFile = () => {
    const files = getFiles();
    let targetid = -1;
    for (let i = 0; i < files.length; i++) {
      if (files[i].id === getCurrentRow()) {
        targetid = i;
        break;
      }
    };

    return targetid >= 0 ? files[targetid] : files[0];
  };

  const setCurrentRow = (currentRow: number) => {
    setFileView({ ...fileView, currentRow });
  };

  const getCurrentRow = () => {
    return fileView ? (fileView.currentRow ? fileView.currentRow : -1) : -1 ;
  };

  const updateFiles = (file: TFile) => {
    const files = getFiles();
    let newFiles: TFile[] = [];

    newFiles = files.map((value) => {
      return file.id === value.id ? file : value;
    });
    setFileView({ ...fileView, files: newFiles });
  };

  const addFile = (file: TFile) => {
    let files = getFiles();
    files.push(file);
    setFileView({ ...fileView, files });
  };

  const getAllMetatags = () => {
    return fileView?.config ? (fileView.config.allMetaTags ? fileView.config.allMetaTags : []) : [];
  };

  const setSelectedMetaTag = (selectedMetaTag: string) => {
    setFileView({ ...fileView, selectedMetaTag });
  };

  const getSelectedMetaTag = () => {
    return fileView ? (fileView.selectedMetaTag ? fileView.selectedMetaTag : "") : "";
  };

  const setDateRange = (dateRange: TDateRange) => {
    setFileView({ ...fileView, dateRange });
  };

  const getDateRange = () => {
    return fileView ? (fileView.dateRange ? fileView.dateRange : {fromDate:-1, toDate:-1}) : {fromDate:-1, toDate:-1};
  };

  const setSearchFileName = (searchFileName: string) => {
    setFileView({ ...fileView, searchFileName });
  };

  const getSearchFileName = () => {
    return fileView ? (fileView.searchFileName ? fileView.searchFileName : "") : "";
  };

  const setSearchMemo = (searchMemo: string) => {
    setFileView({ ...fileView, searchMemo });
  };

  const getSearchMemo = () => {
    return fileView ? (fileView.searchMemo ? fileView.searchMemo : "") : "";
  };

  const setSearchProjectID = (searchProjectID: number) => {
    setFileView({ ...fileView, searchProjectID });
  };

  const getSearchProjectID = () => {
    return fileView ? (fileView.searchProjectID ? fileView.searchProjectID : 0) : 0;
  };

  const setSearchProject = (searchProject: string) => {
    setFileView({ ...fileView, searchProject });
  };

  const getSearchProject = () => {
    return fileView ? (fileView.searchProject ? fileView.searchProject : "") : "";
  };

  const setSearchSuppliers = (searchSuppliers: string) => {
    setFileView({ ...fileView, searchSuppliers });
  };

  const getSearchSuppliers = () => {
    return fileView ? (fileView.searchSuppliers ? fileView.searchSuppliers : "") : "";
  };

  const setAmountRange = (amountRange: TAmountRange) => {
    setFileView({ ...fileView, amountRange });
  };

  const getAmountRange = () => {
    return fileView ? (fileView.amountRange ? fileView.amountRange : {fromAmount:-9999999999, toAmount:9999999999}) : {fromAmount:-9999999999, toAmount:9999999999};
  };

  const getSuppliers = () => {
    return fileView?.config ? (fileView.config.suppliers ? fileView.config.suppliers : []) : [];
  };

  const getProjects = () => {
    return fileView?.config ? (fileView.config.projects ? fileView.config.projects : []) : [];
  };

  const setConfig = (config: TConfig) => {
    setFileView({ ...fileView, config });
  };

  const setNewSupplier = (newSupplier: string) => {
    setFileView({ ...fileView, newSupplier });
  };

  const setNewProject = (newProject: string) => {
    setFileView({ ...fileView, newProject });
  };

  const setNewAmount = (newAmount: number) => {
    setFileView({ ...fileView, newAmount });
  };

  const setNewTax = (newTax: number) => {
    setFileView({ ...fileView, newTax });
  };

  const setNewMetaTags = (newMetaTags: string[]) => {
    setFileView({ ...fileView, newMetaTags });
  };

  const setNewMemo = (newMemo: string) => {
    setFileView({ ...fileView, newMemo });
  };

  const getNewRegistData = () => {
    const newRegistData:TNewRegistData = {
      newSupplier: fileView?.newSupplier || "",
      newProject: fileView?.newProject || "",
      newProjectID: fileView?.newProjectID || 0,
      newAmount: fileView?.newAmount || 0,
      newTax: fileView?.newTax || 0,
      newMetaTags: fileView?.newMetaTags || [],
      newMemo: fileView?.newMemo || "",
    };
    return newRegistData;
  };

  const clearNewRegistData = () => {
    setFileView({ ...fileView, newSupplier:"", newProject:"", newAmount:0, newTax:0, newMetaTags:[], newMemo:"" });
  }

  const setOpen = (open: boolean) => {
    setFileView({ ...fileView, open });
  };

  const getOpen = () => {
    return fileView ? (fileView.open ? fileView.open : false) : false;
  };

  const setHash = (hash: string) => {
    setFileView({ ...fileView, hash });
  };

  const getHash = () => {
    return fileView ? (fileView.hash ? fileView.hash : "") : "";
  };

  return {
    fileView,
    setTabIndex,
    getTabIndex,
    setFiles,
    getFiles,
    getFile,
    setCurrentRow,
    getCurrentRow,
    updateFiles,
    addFile,
    getAllMetatags,
    setSelectedMetaTag,
    getSelectedMetaTag,
    setDateRange,
    getDateRange,
    setSearchFileName,
    getSearchFileName,
    setSearchMemo,
    getSearchMemo,
    setSearchProjectID,
    getSearchProjectID,
    setSearchProject,
    getSearchProject,
    setSearchSuppliers,
    getSearchSuppliers,
    setAmountRange,
    getAmountRange,
    getSuppliers,
    getProjects,
    setConfig,
    setNewSupplier,
    setNewProject,
    setNewAmount,
    setNewTax,
    setNewMetaTags,
    setNewMemo,
    getNewRegistData,
    clearNewRegistData,
    setOpen,
    getOpen,
    setHash,
    getHash,
  };

};
