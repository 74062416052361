import Navbar from '../components/Navbar';
import SetupMain from '../components/SetupMain';
import {SetupProvider} from '../providers/SetupProvider';

export const Setup: React.FC = () => {

  return (
    <>
      <SetupProvider>
        <Navbar active="設定" />
        <SetupMain />
      </SetupProvider>
    </>
	);
}

export default Setup;
