import React, { useEffect, useState, useMemo, useRef } from 'react';
import DataGrid, { DataGridHandle } from 'react-data-grid';
import * as php from '../../libs/php_libs';
import type { Column, SortColumn } from 'react-data-grid';
import EmailView, { TEmail } from '../EmailView';
import { tagDatas} from "../EmailView/MetaTag";
import {useEmailView} from '../../providers/EmailViewProvider';
import HtmlIcon from '../../components/HtmlIcon';
import { PaperClipIcon, DocumentIcon, TagIcon , PencilAltIcon} from '@heroicons/react/solid'
import "./index.css";

type Comparator = (a: TEmail, b: TEmail) => number;

const getComparator = (sortColumn: string): Comparator => {
  switch (sortColumn) {
    case 'from':
    case 'from_display':
    case 'to':
    case 'to_display':
    case 'date':
    case 'subject':
      return (a, b) => {
        return a[sortColumn].localeCompare(b[sortColumn]);
      };
    case 'attachments':
    case 'related_files':
    case 'meta_tag':
    case 'memo':
      return (a, b) => {
        return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
      };
    case 'id':
      return (a, b) => {
        return a[sortColumn] - b[sortColumn];
      };
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
};


export const EmailList = (props:{emails:TEmail[]}) => {
  const gridRef = useRef<DataGridHandle>(null);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [selectedRows, onSelectedRowsChange] = useState((): ReadonlySet<number> => new Set());
  const [emails, ] = useState<TEmail[]>(props.emails);
  const emailView = useEmailView();

  useEffect(() => {
    emailView.setEmails(emails);
    // eslint-disable-next-line
  },[emails]);

  const columns = useMemo((): readonly Column<TEmail>[] => {

    const date = (date: string) => {
      const now = new Date(date.replace(/-/g,"/"));
      const ut = now.getTime() / 1000;
      return php.date("Y/m/d H:i:s", ut);
    }

    return [
      // {
      //   ...SelectColumn,
      //   width: 30,
      //   frozen: true,
      // },
      {
        key: 'id',
        name: '管理番号',
        width: 80,
        frozen: true,
      },
      {
        key: 'attachments',
        name: '添付',
        width: 50,
        frozen: true,
        formatter: ({ row }) => row.attachments && row.attachments.length > 0 ? (<div><PaperClipIcon className="flex-shrink-0 h-4 w-4 text-gray-400" aria-hidden="true" />{row.attachments.length}</div>) : <></>,
      },
      {
        key: 'related_files',
        name: '追加',
        width: 50,
        frozen: true,
        formatter: ({ row }) => row.related_files  && row.related_files.length > 0 ? (<div><DocumentIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />{row.related_files ? row.related_files.length : 0}</div>) : <></>,
      },
      {
        key: 'meta_tag',
        name: 'タグ',
        width: 50,
        frozen: true,
        formatter: ({ row }) =>
          row?.meta_tag?.length ?
          <div>
            <TagIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />{row.meta_tag ? row.meta_tag.length : 0}
            {row && row?.meta_tag?.map(tag => {

              let textColor = "text-gray-800";
              let bgColor = "bg-gray-200";
              for (let i = 0; i < tagDatas.length; i++){
                if (tagDatas[i].name === tag) {
                  textColor = tagDatas[i].textColor;
                  bgColor = tagDatas[i].bgColor;
                }
              }
              return (
                  <span key={tag}>
                    <div className={`h-6 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ml-1 ${bgColor}`}>
                      <span className={`ml-1 ${textColor}`}>{tag}</span>
                    </div>
                  </span>
              )
            })}
          </div> : null
      },
      {
        key: 'memo',
        name: '備考',
        width: 50,
        frozen: true,
        formatter: ({ row }) => row.memo && row.memo ? (<><PencilAltIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" /><span className="ml-1">{row.memo}</span></>) : <></>,
      },
      {
        key: 'from',
        name: '差出人 Email',
        width: 150,
        frozen: true,
        // formatter: ({ row }) => row.from_display ? <>&lt;{row.from}&gt; {row.from_display}</> : <>{row.from}</>,
      },
      {
        key: 'from_display',
        name: '差出人名',
        width: 150,
        frozen: true,
      },
      {
        key: 'to',
        name: '宛先 Email',
        width: 30,
        frozen: true,
        // formatter: ({ row }) => row.to_display ? <>&lt;{row.to}&gt; {row.to_display}</> : <>{row.to}</>,
      },
      {
        key: 'to_display',
        name: '宛先名',
        width: 30,
        frozen: true,
      },
      {
        key: 'date',
        name: '日時',
        width: 160,
        frozen: true,
        formatter: ({ row }) => row.date ? <span>{date(row.date)}</span>
          : null
      },
      {
        key: 'subject',
        name: '件名',
        frozen: true,
        width: 300,
        formatter: ({ row }) => row.html ? (<div><HtmlIcon />{row.subject}</div>) : <div>{row.subject}</div>,
      },
    ]
    }, []);

  const sortedRows = useMemo((): TEmail[] => {

    if (sortColumns.length === 0) return emailView.getEmails(); // ソート対象のカラムが無い場合

    let sortedRows = emailView.getEmails();
    sortedRows.sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
    // emailView.setEmails(sortedRows);
    return sortedRows;
  }, [ sortColumns, emailView]);


  const rowKeyGetter = (row: TEmail) => row.id;
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // if (event.isDefaultPrevented()) {
    //   event.stopPropagation();
    // }
    // // ref 反映待ち これでいいのか？
    // setTimeout(() => {
    //   const id = Number( gridRef.current!.element!.querySelector<HTMLDivElement>('[tabindex="0"]')?.parentElement!.querySelector<HTMLDivElement>('[aria-colindex="2"]')?.textContent);
    //   setCurrentRow(id);
    // }, 10);

  }
  //https://www.npmjs.com/package/react-data-grid
  //https://github.com/adazzle/react-data-grid/blob/main/README.md
  return emails && emails.length > 0 ?(
      <div onKeyDown={onKeyDown} className="m-4" >
        <span className="mb-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 ml-2">
          ※一覧より確認したいメールをクリックしてください。
        </span>
        <DataGrid
          ref={gridRef}
          rowKeyGetter={rowKeyGetter}
          columns={columns}
          rows={sortedRows}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
            minWidth: 30
          }}
          selectedRows={selectedRows}
          onSelectedRowsChange={onSelectedRowsChange}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          className={`ClearTWCheckbox CleatTWSVG rounded-lg border-indigo-200`}
          // className={filters.enabled ? filterContainerClassname : undefined}
          onRowClick={(e) => {
            emailView.setCurrentRow(e.id);
          }}
        />


        <EmailView />
      </div >
  ) : (
      <div>
        <span className="text-gray-600 text-sm m-6">検索条件に該当するのメールはありません。</span>
      </div>
      );
}


export default EmailList;
