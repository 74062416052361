import { useState } from "react";
import { ExclamationIcon } from '@heroicons/react/outline'

export type TConfirm = {
  title?: string;
  message?: string;
  okButton?: string;
  cancelButton ?: string;
}

export const useDeleteConfirm = (props?: TConfirm) => {
  const title = props?.title || "ファイル削除申請の確認";
  const message = props?.title || "アップロード済みファイルの削除申請を行います。<br />管理者の承認後に削除されます。<br />";
  const okButton = props?.okButton || "削除申請";
  const cancelButton = props?.cancelButton || "キャンセル";
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteOk, setDeleteOk] = useState(false);
  const [deleteContent, setDeleteContent] = useState("");
  const [reason, setReason] = useState("誤ったファイルを登録したため、ファイル削除をお願いします。");

  const DeleteConfirm = () => {
    return deleteOpen ? (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl " >


            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <span className="text-lg leading-6 font-medium text-gray-900">
                    {title}
                  </span>
                  <div className="mt-2">
                    <span className="text-sm text-gray-500">
                      <div dangerouslySetInnerHTML={{ __html: message.replace(/\r?\n/g, '<br />') }} />
                      <div className="text-gray-800 text-sm my-4" dangerouslySetInnerHTML={{ __html: deleteContent.replace(/\r?\n/g, '<br />') }} />
                    </span>
                  </div>
                </div>
              </div>

            <div className="mx- my-4 mx-6" >
              <span className="inline-block text-xs font-medium text-gray-500">削除理由</span>
                <textarea
                  tabIndex={0}
                  rows={4}
                  name="comment"
                  id="comment"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-300 block w-full sm:text-sm border-gray-300 rounded p-2"
                  placeholder="削除理由を入力してください。"
                  defaultValue={reason}
                  onBlur={(e) => {
                    // onChangeだとfocus lost
                    setReason(e.target.value);
                  }}
                />
            </div>


              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-bold text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setDeleteOk(true);
                    setDeleteOpen(false);
                  }}
                >
                  {okButton}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-bold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setDeleteOpen(false);
                    setDeleteOk(false);
                  }}
                >
                  {cancelButton}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" ></div>
      </>
    ) : null
  }


  return { deleteOk, deleteOpen, reason, setDeleteOpen, setDeleteOk, setDeleteContent, DeleteConfirm }
}

export default useDeleteConfirm;
