import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const EmailLink: React.FC = () => {
  const navigate = useNavigate()
  const { id, key } = useParams();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (id && key) {
      // ローカルストレージにキーを指定して、それに紐づく値を保存
      localStorage.setItem('email_id', id);
      localStorage.setItem('email_hash', key);
      navigate('/email');
    } else {
      // 今の所メール管理は認証なし
      setError(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {error &&
        <div className="mx-6 mt-6 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold text-sm">認証エラー</strong><br />
          <span className="block sm:inline text-sm">リンクURLが無効です。</span>
        </div>
      }
    </>
	);
}

export default EmailLink;
