import {  useState } from 'react'

import { useFileView } from '../../providers/FileViewProvider';

const number_format = (num:number = 0) =>{
  return num.toString().replace(
    /(\d+?)(?=(?:\d{3})+$)/g,
    function (x) {
      return x + ',';
    }
  );
}

export const AmountRange = () => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const fileView = useFileView();

  const handleChangeFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    let fromRemoved = e.target.value.replace(/(?!^)-|[^-\d]/g, "");
    setFrom(fromRemoved);
    if (fromRemoved === "") fromRemoved = "-9999999999";
    let toRemoved = to.replace(/(?!^)-|[^-\d]/g, "");
    if (toRemoved === "") toRemoved = "9999999999";
    fileView.setAmountRange({ fromAmount: Number(fromRemoved) ? Number(fromRemoved) : 0, toAmount: Number(toRemoved) ? Number(toRemoved) : 0 });
  };

  const handleChangeTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    let toRemoved = e.target.value.replace(/(?!^)-|[^-\d]/g, "");
    setTo(toRemoved);
    if (toRemoved === "") toRemoved = "9999999999";
    let fromRemoved = from.replace(/(?!^)-|[^-\d]/g, "");
    if (fromRemoved === "") fromRemoved = "-9999999999";
    fileView.setAmountRange({ fromAmount: Number(fromRemoved) ? Number(fromRemoved) : 0, toAmount: Number(toRemoved) ? Number(toRemoved) : 0 });
  };


  return (
  <>
    <div className="inline-block">
      <label htmlFor="email" className="block text-sm font-medium text-gray-600">
        税込金額(円)
      </label>
      <div className="mt-1">
        <input
          onChange={handleChangeFrom}
          type="search"
          name="amountFrom"
          id="amountFrom"
          className="h-9 focus:ring-indigo-500 focus:border-indigo-500 block w-32 sm:text-sm border-gray-300 rounded px-2 py-0.5"
          placeholder="指定なし"
          value={ isNaN(Number(from)) || from === "" ? from : number_format(Number(from))}
        />
      </div>
    </div>
    <span className="text-gray-400 mx-2">〜</span>
    <div className="inline-block">
      <label htmlFor="email" className="block text-sm font-medium text-gray-600">
        税込金額(円)
      </label>
      <div className="mt-1">
        <input
          onChange={handleChangeTo}
          type="search"
          name="amountTo"
          id="amountTo"
          className="h-9 focus:ring-indigo-500 focus:border-indigo-500 block w-32 sm:text-sm border-gray-300 rounded px-2 py-0.5"
          placeholder="指定なし"
          value={ isNaN(Number(to)) || to === "" ? to : number_format(Number(to))}
        />
      </div>
    </div>


  </>
  )
}

export default AmountRange;
