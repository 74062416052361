import {useEmailView} from '../../providers/EmailViewProvider';
import { TUploadResponse } from "./FileUpload";
import { FileUpload } from './FileUpload';
import { EmailTextPart } from "./EmailTextPart";
import { EmailHtmlPart } from "./EmailHtmlPart";
import { EmailAuditPart } from "./EmailAuditPart";
import { EmailViewHeader } from "./EmailViewHeader";
import { EmailHeaderPart } from "./EmailHeaderPart";
import { OperationLog } from "./OperationLog";
import { DownloadLog } from "./DownloadLog";
import { EmailTab } from "./EmailTab";
import { EmailViewMainContainer } from "./EmailViewMainContainer";

export type TAttachment = {
  email_id: string;
  file_name: string;
  file_size: number;
  file_style: string;
  hash: string;
  expire: number;
  key: string;
}

export type THtml = {
  email_id: string;
  file_name: string;
  file_size: number;
  hash: string;
}

export type TEmail = {
  id: number;
  to: string;
  to_display: string;
  from: string;
  from_display: string;
  subject: string;
  date: string;
  text: string;
  html: THtml;
  attachments: TAttachment[];
  message_id: string;
  in_reply_id: string;
  references: string;
  headers: { [s: string]: object; };
  hash: string;
  signature: string;
  decode_signature: string;
  related_files:  TUploadResponse[];
  meta_tag: string[];
  memo: string;
  updated_at: string;
  created_at: string;
};

const subMenu = ["アカウント", "メールアーカイブ設定", "ファイル管理設定" ];

export const EmailView = () => {
  const emailView = useEmailView();
  const emails = emailView.getEmails();
  const id = emailView.getCurrentRow();

  const filterEmails = emails.filter((value:TEmail) => {
    let default_id=id;
    if (id === -1) {
      default_id = emails[0].id;
    }
    return value.id === default_id;
  });
  const email: TEmail = filterEmails[0];
  const tabIndex = emailView.getTabIndex();

  return email ? (
    <div className="mt-4">
      <EmailViewMainContainer>
        <EmailViewHeader />
        <EmailTab />
        {emailView.getTabIndex() === 0 && <EmailTextPart />}
        {emailView.getTabIndex() === 1 && <EmailHtmlPart />}
        {emailView.getTabIndex() === 2 && <EmailHeaderPart />}
        {emailView.getTabIndex() === 3 && <FileUpload  />}
        {emailView.getTabIndex() === 4 && <EmailAuditPart  />}
        {emailView.getTabIndex() === 5 && <OperationLog  />}
        {emailView.getTabIndex() === 6 && <DownloadLog  />}
      </EmailViewMainContainer>
    </div>
  ) :
    <></>;
}

export default EmailView;
