import {useState, useEffect, useCallback} from 'react'
import { useApi } from "../../hooks/useApi";
import { DocumentAddIcon, XIcon } from '@heroicons/react/solid'
import {FileUploadContainer } from "./FileUploadContainer";
import {MetaTag } from "./MetaTag";
import {Memo } from "./Memo";
import {Supplier } from "./Supplier";
import {Project } from "./Project";
import {Amount } from "./Amount";
import { useFileView } from '../../providers/FileViewProvider';
import {Spinner} from '../Spinner';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import noFileSelectImg from '../../assets/images/no_file_selected.png'
import axios from "axios";
import { DocumentIcon } from '@heroicons/react/solid'

export type TUploadResponse = {
  account_id: number,
  name: string,
  hash: string,
  size: number,
  date: string,
  expire: number,
  key: string,
};

export const FileUploadMain = () => {
  const fileView = useFileView();
  const { running:runningNewRegist, error:errorNewRegist, data:dataNewRegist, execute:executeNewRegist, init:initNewRegist } = useApi();
  const { running:runningUpdateFile, error:errorUpdateFile, data:dataUpdateFile, execute:executeUpdateFile, init:initUpdateFile } = useApi();
  // const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const [uploadFiles, setUploadFiles] = useState<TUploadResponse[]>([])
  const [progress, setProgress] = useState(0);
  const [files, setFiles] = useState<File[]>([])
  const [currentFile, setCurrentFile] = useState("");
  const [fileCount, setFileCount] = useState(0);
  const [id, setId] = useState(0);

  // 全て転送が完了したら DBのアップロード済み情報を更新する
  useEffect(() => {
    if (files.length === 0 && uploadFiles.length > 0) {
      const params = {
        related_files: uploadFiles,
        upload_files: uploadFiles
      };
      executeUpdateFile({ url: `/v1/update_file/${id}/`, params });
    }
    // eslint-disable-next-line
  }, [files]);

  // APIで新規登録した案件のレスポンスを取得する
  useEffect(() => {
    if (dataNewRegist) {
      const file = dataNewRegist.file;
      const id = file.id;
      setId(id);
      // fileView.addFile(file);
      // ファイルをuploadする
      OnUpload(files, id);
    }
    // eslint-disable-next-line
  }, [dataNewRegist]);

  useEffect(() => {
    if (errorNewRegist?.status === 401) {
      // ローカルストレージから対象のキーに紐づく値を削除
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      navigate('/');
    }
    // eslint-disable-next-line
  }, [errorNewRegist]);

    // DBのアップロード済み情報を更新する
  const saveRelatedFiles = ( ) => {
    const params = {
      related_files: [],
      upload_files: uploadFiles
    };
    executeUpdateFile({ url: `/v1/update_file/${id}/`, params });
  };

  useEffect(() => {
    if (fileCount && uploadFiles && uploadFiles.length === fileCount) {
      saveRelatedFiles();
      setFileCount(0);
    }
    // eslint-disable-next-line
  }, [fileCount, uploadFiles]);


  const OnUpload = (files: File[], id: number) => {
    setFileCount(files.length)
    setUploadFiles([]);
    // サーバーにファイルを1個アップロードする
    const uploadFile = async (f: File, onUploadProgress: (progressEvent: ProgressEvent) => void) => {
    const api_server = localStorage.getItem("api_server") || "https://archives.mirai.ad.jp/";
    const api = `${api_server}api/arc`;

    // ローカルストレージからキーを指定して取得
    const token = localStorage.getItem("token") || "";
    let formData = new FormData();
    formData.append("file", f);
    setCurrentFile(f.name);
    await axios.post(`${api}/file_upload/`,
        formData, {
        headers: {
          "X-API-Key": token,
          "X-File-ID": id.toString(),
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
        },
        onUploadProgress,
      }).then((response) => {
        response.data.files && response.data.files.forEach((res: TUploadResponse) => {
          setUploadFiles((uploadFiles) => {
            let newUploadFiles = [...uploadFiles];
            newUploadFiles.push(res);
            return newUploadFiles;
          });
          deleteItem(res.name);
        });
      }).catch((error) => {
        console.log(error.response);
      });
    };

    // 順にファイルをアップロードする 非同期にするにはプログレスを修正する必要がある
    files.forEach((file) => {
      // アップロード状況を更新する callback
      const onUploadProgress = (progressEvent: ProgressEvent) => {
        setCurrentFile(file.name);
        const per = progressEvent.lengthComputable ? Math.round((100 * progressEvent.loaded) / progressEvent.total) : 0;
        setProgress(per);
      };
      uploadFile(file, onUploadProgress);
    });
  }

  const onDrop = useCallback((acceptedFiles) => {
    setProgress(0);
    acceptedFiles && acceptedFiles.forEach((file : File) => {
      setFiles((files) => {
        let newFiles: File[] = [];
        let dupeFlag = false;
        files.forEach(v => {
          if (file.name === v.name) {
            dupeFlag = true;
          }
          newFiles.push(v);
        });
        if ( ! dupeFlag ) newFiles.push(file);
        return newFiles;
      });
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const deleteItem = useCallback((name: string) => {
    setFiles((files) => {
      let newFiles: File[] = [];
      files.forEach(v => {
        if (name !== v.name) {
          newFiles.push(v);
        }
      });
      return newFiles;
    });

  }, []);

  const validate = () => {
    const newRegistData = fileView.getNewRegistData();
    return newRegistData.newSupplier && newRegistData.newProject;
  };

  const handleOnClick = () => {
    const newRegistData = fileView.getNewRegistData();

    const params = {
      supplier: newRegistData.newSupplier,
      project: newRegistData.newProject,
      amount: newRegistData.newAmount,
      tax: newRegistData.newTax,
      meta_tag: newRegistData.newMetaTags,
      memo: newRegistData.newMemo,
    };
    executeNewRegist({ url: `/v1/add_file/`, params });
    fileView.clearNewRegistData();
    // setOpen(false);
  };

  const handleClose = () => {
    setUploadFiles([]);
    initNewRegist();
    initUpdateFile();
    fileView.clearNewRegistData();
    fileView.setOpen(false);
    setFiles([]);
  };

  return !fileView.getOpen() ? (
    <div className="mx-4 mt-4">
      <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded inline-flex items-center"
        onClick={() => {
          fileView.clearNewRegistData();
          setFiles([]);
          setUploadFiles([]);
          fileView.setOpen(true);
        }}
      >
        <DocumentAddIcon className="flex-shrink-0 h-6 w-6  focus:shadow-outline focus:outline-none" aria-hidden="true" />
        <span className="text-sm ml-1" >新規案件登録</span>
      </button>
    </div>
  ) :
  (
    <div className="mx-4 mt-4">
        <button className="bg-gray-600 hover:bg-gray-700 text-gray-200 font-bold py-1 px-4 rounded inline-flex items-center h-8"
          onClick={handleClose}
      >
        <XIcon className="flex-shrink-0 h-4 w-4 mr-2 focus:shadow-outline focus:outline-none" aria-hidden="true" />
        <span className="text-sm ml-1" >新規案件登録</span>
      </button>

      <FileUploadContainer>
        <div className="bg-gray-100 shadow mt-4">

          <div className="ml-4 pt-4 mb-4">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10 mx-6">
              <div className="sm:col-span-5">
                <Supplier />
              </div>
              <div className="sm:col-span-5">
                <Project />
              </div>
            </dl>
          </div>

          <div className="border-t border-gray-200 px-4 py-1 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10 mx-6">
              <div className="py-1 col-span-10">
                <Amount />
              </div>
            </dl>
          </div>


          <MetaTag />
          <Memo />

            {/* file upload */}
<div>
  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
    <main className="container mx-auto max-w-screen-lg h-full">
      {/* file upload modal */}
          <article tabIndex={0} aria-label="File Upload Modal" className="relative h-full flex flex-col bg-white shadow-xl rounded-md">
          {/* overlay */}
          {isDragActive && (
            <div id="overlay" className="w-full h-full absolute top-0 left-0 pointer-events-none z-50 flex flex-col items-center justify-center rounded-md">
              <i>
                <svg className="fill-current w-12 h-12 mb-3 text-blue-700" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z" />
                </svg>
              </i>
              <span className="text-xs text-blue-700">ファイルをドロップしてアップロードしてください。</span>
            </div>
          )}

        {/* scroll area */}
        <section className={`h-full overflow-auto p-8 w-full flex flex-col  ${isDragActive && `opacity-30`}`} >
          <div {...getRootProps()} >
            <input {...getInputProps()} />
            <header className="border-dashed border-2 border-gray-400 py-6 flex flex-col justify-center items-center bg-blue-50 hover:bg-blue-100">
              <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">
                  <span className="text-gray-400 text-sm">アップロードするファイルを、<span className="text-blue-400">この枠内にドロップする</span>か、ファイルを選択してください。</span>
              </p>
                  <button id="button" className="mt-2 rounded-sm px-3 py-0 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none">
                    <span className="text-sm">ファイルを選択する</span>
                  </button>

            </header>
            </div>
          <h1 className="pt-8 pb-3 font-semibold sm:text-sm text-gray-400">
            アップロードするファイル
          </h1>

          <ul id="gallery" className="flex flex-1 flex-wrap -m-1">
                {files.length === 0 ? (
                  <li id="empty" className="h-full w-full text-center flex flex-col items-center justify-center">
                    <img className="mx-auto w-32" src={noFileSelectImg} alt="no data" />
                    <span className="text-xs text-gray-500">ファイルが選択されていません。</span>
                  </li>)
                  : files.length && files.map((file, key) => {

                  const objectURL = URL.createObjectURL(file);
                  return file.type.match("image.*") ? (
                        <li className="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24 " key={key} >
                          <article tabIndex={0} className="group hasImage w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative text-transparent hover:text-white shadow-sm ">
                            <img alt={file.name} src={objectURL} className="img-preview w-full h-full sticky object-cover rounded-md bg-fixed " />

                            <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3 hover:bg-black hover:opacity-60">
                            <h1 className="flex-1">{file.name}</h1>
                              <div className="flex">
                                <span className="p-1">
                                  <i>
                                    <svg className="fill-current w-4 h-4 ml-auto pt-" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                      <path d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z" />
                                    </svg>
                                  </i>
                                </span>

                                <p className="p-1 size text-xs">
                              {
                                file.size > 1024
                                  ? file.size > 1048576
                                    ? Math.round(file.size / 1048576) + "mb" : Math.round(file.size / 1024) + "kb"
                                  : file.size + "b"
                              }
                                </p>
                              <button className="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md" onClick={() => deleteItem(file.name)} >
                                  <svg className="pointer-events-none fill-current w-4 h-4 ml-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path className="pointer-events-none" d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                                  </svg>
                                </button>
                              </div>
                            </section>
                          </article>
                        </li>
                      ):
                      (
                        <li className="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24" key={key}>
                          <article tabIndex={0} className="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm">
                          <img alt="upload preview" className="img-preview hidden w-full h-full sticky object-cover rounded-md bg-fixed" />

                          <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                            <h1 className="flex-1 group-hover:text-blue-800">{file.name}</h1>
                            <div className="flex">
                              <span className="p-1 text-blue-800">
                                <i>
                                  <svg className="fill-current w-4 h-4 ml-auto pt-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z" />
                                  </svg>
                                </i>
                              </span>
                              <p className="p-1 size text-xs text-gray-700">
                              {
                                file.size > 1024
                                  ? file.size > 1048576
                                    ? Math.round(file.size / 1048576) + "mb" : Math.round(file.size / 1024) + "kb"
                                  : file.size + "b"
                              }
                              </p>
                                <button className="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md text-gray-800" onClick={() => deleteItem(file.name) }>
                                <svg className="pointer-events-none fill-current w-4 h-4 ml-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                  <path className="pointer-events-none" d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                                </svg>
                              </button>
                            </div>
                          </section>
                        </article>
                      </li>
                    );


                  })
                }
          </ul>
        </section>

        {/* progress bar */}
        {progress && files.length && progress < 100 ?(
          <div className="m-10">
            <div className="mb-1 flex justify-between">
              <span className="text-base text-blue-500 font-medium">{currentFile}</span>
              <span className="text-sm font-medium text-blue-500">{progress}%</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
            </div>
          </div>
        ): <></>}

      </article>
    </main>
  </div>
</div>











      {(runningNewRegist || runningUpdateFile) && <Spinner text="Now File API processing..." />}
      {(errorNewRegist || errorUpdateFile) && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  API実行中にエラーが発生しました。データは登録されていません。
                </span>
      }

          <div className="border-t border-gray-200 px-4 py-1 sm:px-6">
              {uploadFiles.length === 0 &&
                <div className="absolute right-16">
                  {!runningNewRegist && validate() ? (
                    <div className="h-9 inline-flex items-center px-2.5 py-0.5 rounded text-xs  bg-blue-600 hover:bg-blue-700 text-white font-bold cursor-pointer"
                      onClick={handleOnClick}
                    >
                      <span className="text-sm" >新規案件登録</span>
                    </div>
                  ) : (
                    <div className="h-9 inline-flex items-center px-2.5 py-0.5 rounded text-xs font-bold bg-gray-400 text-white ">
                      <span className="text-sm" >新規案件登録</span>
                    </div>
                  )}
                  <button id="cancel" className="ml-3 rounded px-3 py-1 hover:bg-gray-300 font-bold focus:shadow-outline focus:outline-none bg-gray-200"
                    onClick={handleClose}>
                    <span className="text-sm"  >キャンセル</span>
                  </button>
                </div>
              }
              {files.length === 0 && uploadFiles.length > 0 &&
                <div className="absolute right-16">
                  <div className="h-9 inline-flex items-center px-2.5 py-0.5 rounded text-xs  bg-blue-600 hover:bg-blue-700 text-white font-bold cursor-pointer"
                    onClick={handleClose}>
                    <span className="text-sm"  >新規案件登録を閉じる</span>
                  </div>
                </div>
              }
              {uploadFiles.length > 0 && files.length === 0 &&
                <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mt-12" role="alert">
                  <div className="flex">
                    <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                    <div>
                      <p className="font-bold">{`管理番号 ${id} 新規案件登録が完了しました`}</p>
                      <p className="text-sm">ファイルの追加や編集は、「ファイル検索」から本案件を検索して編集してください。</p>
                    </div>
                  </div>
                </div>
              }

            <div className="mx-2 ">
                      {uploadFiles.length ?
                        <dt className="text-sm font-medium text-gray-500 mt-4">
                          アップロード済ファイル
                        </dt>
                        :
                        <dt className="text-sm font-medium text-gray-500 mt-4">追加されたファイルはありません</dt>
                      }

                  <dd className="mt-1 text-sm text-gray-900 mb-6">
                    <ul className=" divide-y divide-gray-200">
                          {uploadFiles.map((value: TUploadResponse, index: number) => {
                          return (
                            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-200 rounded-md mt-2 shadow-md hover:bg-gray-50" key={index}>
                              <div className="w-0 flex-1 flex items-center">
                                <DocumentIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span className="ml-2 flex-1 w-0 truncate">{value.name}</span><br />

                              </div>
                                                    <span className="ml-2 text-gray-500">{value.date}</span>
                              <div className="ml-4 flex-shrink-0">

                                <span className="ml-2 text-gray-500">({
                                  value.size > 1024
                                    ? value.size> 1048576
                                      ? Math.round(value.size / 1048576) + "mb" : Math.round(value.size / 1024) + "kb"
                                    : value.size + "b"
                                }) </span>

                              </div>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </dd>
            </div>

          </div>

        </div>
      </FileUploadContainer>

    </div>
  )
}

export default FileUploadMain;
