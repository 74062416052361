import { useEffect, useState} from 'react';
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { useNavigate } from 'react-router-dom';
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon, ChevronDownIcon } from "@heroicons/react/outline";
import * as php from '../libs/php_libs';
import "./Navbar.css";

export type TToken = {
  id: number;
  token: string;
  expire: number;
  level: number;
}

// ユーザーレベル
// Guest 基本R/O
// User  一般社員
// Admin  管理者

const navigation = [
  { name: "メールアーカイブ", href: "/email", allow:["Guest", "User", "Admin"] },
  { name: "ファイル管理", href: "/file", allow:["Guest", "User", "Admin"] },
  // { name: "マスタ管理", href: "/master", allow:["Admin"] },
];
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const Navbar = (props: { active?: string, notify?:number }) => {
  const [level, setLevel] = useState(0);

  const active = props.active || "";
  const notify = props.notify || 0;
  const navigate = useNavigate()
  const name = localStorage.getItem("name") || "";

  useEffect(() => {
    const api_key = localStorage.getItem("token") || "";
    if (api_key) {
      const token: TToken = JSON.parse(atob(api_key));
      setLevel(token.level);
    }
    // eslint-disable-next-line
  }, []);

  const logout = () => {
    // ローカルストレージから対象のキーに紐づく値を削除
    localStorage.removeItem("token");
    navigate("/");
  };

  const setup = () => {
    navigate("/setup");
  }

  return (
    <Disclosure as="nav" className="bg-gray-800 relative">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center cursor-pointer"
                  onClick={() => {
                    navigate("/home")
                  }}
                >
                  <div className="font-serif text-3xl font-black">
                    <span className="gradient">＠Archives!</span>
                  </div>
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <span
                        onClick={() => navigate(item.href)}
                        key={item.name}
                        className={classNames(
                          item.name === active
                            ? "bg-indigo-700 text-white"
                            : "text-gray-300  hover:text-white cursor-pointer bg-gradient-to-t from-indigo-900 via-gray-900 to-gray-900 hover:from-indigo-700 hover:via-gray-700 hover:to-gray-700",
                          "px-3 py-2 rounded-md text-sm font-black "
                        )}
                        aria-current={item.name === active ? "page" : undefined}
                      >
                        {item.name}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                <button
                  type="button"
                  className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <div className="relative">
                    <BellIcon className="z-40 h-6 w-6" aria-hidden="true" />
                  </div>
                  {notify > 0 &&
                    <div className="relative z-50 font-bold text-xs bg-red-600 text-white  rounded-full -mt-6 ml-5">
                      <p className="px-1">{php.number_format(notify)}</p>
                    </div>
                  }
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative z-50">
                  <div>
                    <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white px-2">

                      <span className="text-white px-3 py-2 font-black">{name} 様</span>
                      <ChevronDownIcon className="text-white font-black h-4 w-4 mt-2" aria-hidden="true" />

                      {/* <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      /> */}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {level > 1 && <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={setup}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer font-black "
                            )}
                          >
                            設定
                          </span>
                        )}
                      </Menu.Item>}
                      <Menu.Item>
                        {({ active }) => (
                            <span
                              onClick={logout}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 cursor-pointer font-black"
                              )}
                            >ログアウト
                            </span>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.name === active
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={item.name === active ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
