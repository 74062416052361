import {useFileView} from '../../providers/FileViewProvider';
import { useEffect, useState} from 'react';

export const ProjectID = () => {
  const fileView = useFileView();
  const [defaultValue, setDefaultValue] = useState("");

  useEffect(() => {
    // ローカルストレージからキーを指定して取得
    let project_id = localStorage.getItem("project_id") || "";
    let project_hash = localStorage.getItem("project_hash") || "";
    if (project_id && project_hash ) {
      localStorage.removeItem("project_id");
      localStorage.removeItem("project_hash");
      project_id = project_id.replace(/(?!^)-|[^-\d]/g, "");
      setDefaultValue(project_id);
      fileView.setSearchProjectID(Number(project_id));
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = ( e :React.ChangeEvent<HTMLInputElement>) => {
    const projectIDRemoved = e.target.value.replace(/(?!^)-|[^-\d]/g, "");
    fileView.setSearchProjectID(Number(projectIDRemoved));
    setDefaultValue(projectIDRemoved);
  };

  return (
    <div className="inline-block ml-4">
      <label htmlFor="email" className="block text-sm font-medium text-gray-600">
        管理番号
      </label>
      <div className="mt-1">
        <input
          onChange={handleChange}
          type="search"
          name="ProjectID"
          id="ProjectID"
          className="h-9 focus:ring-indigo-500 focus:border-indigo-500 block w-30 sm:text-sm border-gray-300 rounded px-2 py-0.5"
          placeholder="指定なし"
          value={defaultValue}
        />
      </div>
    </div>
  )
}

export default ProjectID;
