import  { useEffect, useState} from 'react';
import * as php from '../../libs/php_libs';
import { useEmailView } from '../../providers/EmailViewProvider';
import { MetaTag } from "./MetaTag";
import { Memo } from './Memo';
import {  Link } from 'react-router-dom';
import { ClipboardCopyIcon, ClipboardCheckIcon} from '@heroicons/react/outline'

export const EmailViewHeader = () => {
  const emailView = useEmailView();
  const email = emailView.getEmail();
  const [url, setUrl ] = useState("");
  const [copied, setCopied ] = useState(false);

  useEffect(() => {
    setUrl(`${window.location.protocol}//${window.location.hostname}`);
  },[])

  const date = (date: string) => {
    const now = new Date(date.replace(/-/g,"/"));
    const ut = now.getTime() / 1000;
    return php.date("Y/m/d H:i:s", ut);
  }


  return (
    <div className="bg-gray-100 shadow mt-4">

        <div className="border-t border-gray-200 px-4 py-1 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-12">
            <div className="px-4 py-1 sm:px-6 sm:col-span-10">
              <p className="mt-1 max-w-2xl text-xs text-gray-500">件名</p>
              <dd className="mt-1 text-sm text-gray-900">{email.subject}</dd>
            </div>
            <div className="px-4 py-1 sm:px-6 sm:col-span-2">
              <p className="mt-1 max-w-2xl text-xs text-gray-500">管理番号</p>
              <dd className="mt-1 text-sm text-gray-900">

              {copied ? (
                  <div className="mt-2">
                    <div>{email.id}</div>
                    <div className="inline-block">
                      <ClipboardCheckIcon className="inline-block mt-2 flex-shrink-0 h-7 w-7 text-gray-600 " aria-hidden="true"/>
                      <div className="inline-block absolute mt-3"
                      >Link Copied!!</div>
                  </div>
                </div>
              ) : (
                  <div className="mt-2">
                    <div>{email.id}</div>
                    <div className="inline-block">
                      <ClipboardCopyIcon className="inline-block mt-2 flex-shrink-0 h-7 w-7 text-gray-600 cursor-pointer" aria-hidden="true"
                            onClick={() => {
                              navigator.clipboard.writeText(`${url}/emaillink/${email.id}/${email.hash}`);
                              setCopied(true);
                              setTimeout(() => {setCopied(false)},1000);
                            }}
                        />
                      <div className="inline-block absolute mt-3">Link Copy</div>
                    </div>
                  </div>
              )}
              </dd>
            </div>

        </dl>
        </div>

        <div className="border-t border-gray-200 px-4 py-1 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-12">
            <div className="px-4 py-1 sm:px-6 sm:col-span-2">
              <dt className="text-xs font-medium text-gray-500">日時</dt>
              <dd className="mt-1 text-sm text-gray-900">{date(email.date)}</dd>
            </div>
            <div className="px-4 py-1 sm:px-6 sm:col-span-6">
              <dt className="text-xs font-medium text-gray-500">差出人</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {
                  email.from_display ? `${email.from_display} <${email.from}>` : email.from
                }
              </dd>
            </div>
            <div className="px-4 py-1 sm:px-6 sm:col-span-4">
              <dt className="text-xs font-medium text-gray-500">宛先</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {
                  email.to_display ? `${email.to_display} <${email.to}>` : email.to
                }
              </dd>
            </div>
          </dl>
        </div>

        <MetaTag />
        <Memo />

    </div>
  );
}

export default EmailViewHeader;
