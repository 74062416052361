import {useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useEmailView } from '../../providers/EmailViewProvider';
import { TagIcon, UploadIcon } from '@heroicons/react/solid'
import { XIcon } from '@heroicons/react/solid'
import { useApi } from "../../hooks/useApi";
import {Spinner} from '../../components/Spinner';
import Select from 'react-select';

export type TTagData = {
  id: number;
  name: string;
  bgColor: string;
  textColor: string;
  count?: number;
};

export type TAllTag = {
  id: number;
  meta_tag: string;
};

export type TOption = {
  value: string;
  label: string;
};

export const tagDatas:TTagData[] = [
  // { id: 0, name: '選択してください', bgColor: "bg-white", textColor: "text-white" },
  { id: 1, name: '見積書', bgColor: "bg-red-100", textColor: "text-red-800" },
  { id: 2, name: '注文発注書', bgColor: "bg-yellow-100", textColor: "text-yellow-800" },
  { id: 3, name: '納品書', bgColor: "bg-green-100", textColor: "text-green-800" },
  { id: 4, name: '検収書', bgColor: "bg-blue-100", textColor: "text-blue-800" },
  { id: 5, name: '請求書', bgColor: "bg-indigo-100", textColor: "text-indigo-800" },
  { id: 6, name: '領収書', bgColor: "bg-purple-100", textColor: "text-purple-800" },
  { id: 7, name: '契約書', bgColor: "bg-pink-100", textColor: "text-pink-800" },
  { id: 8, name: '重要書類', bgColor: "bg-orange-100", textColor: "text-orange-800" },
  { id: 9, name: '作業中', bgColor: "bg-emerald-100", textColor: "text-emerald-800" },
]

export const MetaTag = () => {
  const emailView = useEmailView();
  const email = emailView.getEmail();
  const id = email.id;
  const [editMode, setEditMode] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>("");
  const [options, setOptions] = useState<TOption[]>([]);
  const { running, error, data, execute } = useApi();
  const navigate = useNavigate()
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    setTags(email.meta_tag ? email.meta_tag : []);
    // リストを作成
    const allMetaTag = emailView.getAllMetatags();
    let newOptions: TOption[] = [];
    newOptions.push({ label: "", value: "" });  // index:0は入力用
    allMetaTag.forEach((value) => {
      newOptions.push({
        value: value.meta_tag,
        label: value.meta_tag,
      });
    });
    setOptions(newOptions);
    // eslint-disable-next-line
  }, [email]);

  useEffect(() => {
    if (data) {
      email.meta_tag = tags;
      emailView.updateEmails(email);
      setEditMode(false);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (error?.status === 401) {
      // ローカルストレージから対象のキーに紐づく値を削除
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      navigate('/');
    }
    // eslint-disable-next-line
  }, [error]);

  const saveTag = () => {
    const params = { meta_tag: tags };
    execute({ url: `/v1/update_email/${id}/`, params });
  };

  const deleteTag = (tag: string) => {
    const newTag = tags.filter((value) => {
      return value !== tag;
    })
    setTags(newTag);
  };
  const addTag = () => {
    const isStaticTag = (tag: string) => {
      for (let i = 0; i<tagDatas.length; i++) {
        if (tagDatas[i].name === tag) return true;
      };
      return false;
    };
    // inputからカスタムタグを追加する
    const newTags = tags.filter((value) => {
      return value !== selected;
    })
    selected && newTags.push(selected);
    // カスタムタグを前方に持ってくる
    let newTags3: string[]= [];
    newTags.forEach((value) => {
      !isStaticTag(value) && newTags3.push(value);
    });
    newTags.forEach((value) => {
      isStaticTag(value) && newTags3.push(value);
    });
    setTags(newTags3);
  };

  const handleChange = (tagData: string) => {
    setSelected(tagData);
    let newOptions: TOption[] = [...options!];
    newOptions[0] = { label: "", value: "" };
    setOptions(newOptions);
    // emailView.setSelectedMetaTag(tagData);
    if (tagData === "") {
      setSelectedIndex(-1);
    } else {
      newOptions!.forEach((value, index) => {
        if (tagData === value.value) {
          setSelectedIndex(index);
        }
      });
    }
  };

  const handleInput = (tagData: string) => {
    if (tagData !== "") {
      setSelected(tagData);
      // emailView.setSelectedMetaTag(tagData);
    }
  };

  const customStyles = {
    menu: (provided:any, state:any) => ({
      ...provided,
      width: "300px",
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    control: (provided:any, state:any) => ({
      ...provided,
      width: "300px",
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    dropdownIndicator: (provided:any, state:any) => ({
      ...provided,
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    singleValue: (provided:any, state:any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  };

  return editMode ? (
        <div className="border-t border-gray-200 px-4 py-1 sm:px-6 my-2  h-80">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10">
            <div className="sm:col-span-2">
                <span className="h-6 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-600 hover:bg-gray-700 text-gray-200 mx-4 cursor-pointer"
                  onClick={() => {
                    setEditMode(false);
                }
                }>
                  <XIcon className="flex-shrink-0 w-3 mr-1 focus:shadow-outline focus:outline-none" aria-hidden="true" />
                  <span className="ml-0">タグ編集</span>
                </span>
            </div>

            <div className="sm:col-span-8">

            {/* タグ選択 */}
            <div className="inline-block">

              <Select
                onBlur={(e) => {
                  let newOptions: TOption[] = [...options!];
                  newOptions[0] = { label: selected, value: selected };
                  setOptions(newOptions);
                  setSelectedIndex( selected === "" ? -1 : 0);
                }}
                className="text-gray-600 text-xs inline-block mr-2"
                styles={customStyles}
                options={options}
                value={options[selectedIndex!]}
                placeholder="タグを入力するか選択してください。"
                isClearable={true}
                noOptionsMessage={({ inputValue }) => !inputValue ? "" : "入力に一致する候補はありません"}
                onChange={(e) => {
                  handleChange(e?.value || "");
                }}
                onInputChange={(e) => {
                  handleInput(e || "");
                }}
              />

                <span className="h-6 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-600 hover:bg-gray-700 text-gray-200 mx-4 cursor-pointer"
                onClick={() => addTag()}
                >
                  <span className="mx-0">+ 追加</span>
                </span>
            </div>


            </div>
          </dl>

          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10">
            <div className="sm:col-span-2">
            </div>
            <div className="sm:col-span-8">
              <hr className="my-4" />
            {/* 登録タグを表示 */}
          {tags && tags.length ? <span className="text-xs font-medium text-gray-500 mr-2">保存するタグ</span> : null}
          {
            tags && tags.map((tag, index) => {
              let textColor = "text-gray-800";
              let bgColor = "bg-gray-200";
              for (let i = 0; i < tagDatas.length; i++){
                if (tagDatas[i].name === tag) {
                  textColor = tagDatas[i].textColor;
                  bgColor = tagDatas[i].bgColor;
                }
              }
              return (
                <span key={index}>
                  <div className={`h-6 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 ${bgColor}`}>
                    <span className="text-gray-800 cursor-pointer hover:text-red-900"
                      onClick={() =>deleteTag(tag)}
                    ><XIcon className="block h-3 w-3" aria-hidden="true" /></span>
                    <span className={`ml-1 ${textColor}`}>{tag}</span>
                  </div>
                </span>
              );
            })
          }

          {tags && tags.length ? <div className="text-xs text-gray-500 mt-2">※不要なタグは、×をクリックして削除してください。</div> : null}
          {!(tags && tags.length) ? <div className="text-xs text-gray-500 mt-2">タグは登録されていません。</div> : null}

            </div>
          </dl>

          <dl className="grid grid-cols-1 gap-y-8 sm:grid-cols-10">
            <div className="sm:col-span-2">
            </div>
            <div className="sm:col-span-8">
              <hr className="my-4" />
              <div className="h-7 inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-blue-600 hover:bg-blue-700 text-white  cursor-pointer"
                onClick={saveTag}
              >
                <UploadIcon className="h-3 w-3 text-white" aria-hidden="true" />
              <span className="ml-1"

              >タグ保存</span>
              </div>
              <div className="h-7 inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-gray-300 hover:bg-gray-500 text-white mx-2 cursor-pointer"
                onClick={() => setEditMode(false)}
              >
                <span className="text-black">キャンセル</span>
              </div>
              <div className="text-xs text-gray-500 mt-4">
                ※編集後、タグ保存をクリックしてください。<br />
                ※検索頻度の低いものは、カスタムタグではなく備考欄に登録するようにしてください。<br />
              </div>
      {error && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  API実行中にエラーが発生しました。データは更新されていません。
                </span>
      }
            </div>
          </dl>

      {running && <Spinner text="Now MetaTag API processing..." />}


        </div>
  ) : (
      <>
        <div className="border-t border-gray-200 px-4 py-1 sm:px-6 my-2 ">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10">
            <div className="sm:col-span-2">
                <div className="h-6 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-600 hover:bg-gray-700 text-gray-200 mx-4 cursor-pointer"
                  onClick={() => {
                    setEditMode(true);
                }
                }>
                  <TagIcon className="h-3 w-3 text-gray-400" aria-hidden="true" />
                  <span className="ml-1">タグ編集</span>
                </div>
            </div>

            <div className="sm:col-span-8">

            {/* 登録タグを表示 */}
            {
            tags && tags.length > 0 ? tags.map((tag, index) => {
              let textColor = "text-gray-800";
              let bgColor = "bg-gray-200";
              for (let i = 0; i < tagDatas.length; i++){
                if (tagDatas[i].name === tag) {
                  textColor = tagDatas[i].textColor;
                  bgColor = tagDatas[i].bgColor;
                }
              }
              return (
                <span key={index}>
                  <div className={`h-6 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-1 ${bgColor}`}>
                    <span className={`ml-1 ${textColor}`}>{tag}</span>
                  </div>
                </span>
              );
            }) : <span className="text-gray-400 text-xs">タグは登録されていません。</span>
            }

            </div>
          </dl>
        </div>

      </>
  );
}


export default MetaTag;
