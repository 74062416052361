// PHP互換のライブラリ
// Howto Use
// import * as php from '../../libs/php_libs';
// php.mktime();

export type TMktime = {
  hour?: number,
  minute?: number,
  second?: number,
  month?: number,
  day?: number,
  year?: number,
}

export const number_format = (num: number = 0) => {
  return num.toString().replace(
    /(\d+?)(?=(?:\d{3})+$)/g,
    function (x) {
      return x + ',';
    }
  );
}

export const mktime = (dt?: TMktime): number => {
  //PHPのmktimeを再現。
  //1970年1月1日午前0時からの通算秒数を返す。
  //引数は時,分,秒,月,日,年。省略のときは現在日時。  let now, vals, mydate, i;
  const now = new Date();
  if (dt === undefined) return now.getTime() / 1000;

  const hour = dt.hour !== undefined ? dt.hour : now.getHours();
  const minute = dt.minute !== undefined ? dt.minute : now.getMinutes();
  const second = dt.second !== undefined ? dt.second : now.getSeconds();
  const month = dt.month !== undefined ? dt.month : now.getMonth();
  const day = dt.day !== undefined ? dt.day : now.getDate();
  const year = dt.year !== undefined ? dt.year : now.getFullYear();

  const mydate = new Date(year, month - 1, day, hour, minute, second);
  return mydate.getTime() / 1000;
}

export const date = (format: string, timestamp?: number) => {
  if (timestamp === -1) return "指定なし";
  //PHPのdateを再現。
  //ただしY,y,m,n,F,M,d,j,w,l,D,G,H,g,h,i,s,a,Aに対応。
  //引数は形式,タイムスタンプ。タイムスタンプは省略できる。省略のときは現在日時。
  let temp;
  if (typeof timestamp == "undefined") {
    timestamp = mktime();
  }
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const mydate = new Date(timestamp * 1000);
  //二重変換防止のためformatの文字を@で挟む。
  format = format.replace(/([YymnFMdjwlDGHghisaA])/g, "@$1@")
  //年
  format = format.replace(/@Y@/g, mydate.getFullYear().toString());
  format = format.replace(/@y@/g, ("0" + mydate.getFullYear() % 100).slice(-2));
  //月
  format = format.replace(/@m@/g, ("0" + (mydate.getMonth() + 1)).slice(-2));
  format = format.replace(/@n@/g, (mydate.getMonth() + 1).toString());
  format = format.replace(/@F@/g, months[mydate.getMonth()]);
  format = format.replace(/@M@/g, months[mydate.getMonth()].substring(0, 3));
  //日
  format = format.replace(/@d@/g, ("0" + mydate.getDate()).slice(-2));
  format = format.replace(/@j@/g, mydate.getDate().toString());
  //曜日
  format = format.replace(/@w@/g, mydate.getDay().toString()); //0:日曜
  format = format.replace(/@l@/g, weekdays[mydate.getDay()]);
  format = format.replace(/@D@/g, weekdays[mydate.getDay()].substring(0, 3));
  //時(24時)
  format = format.replace(/@G@/g, mydate.getHours().toString());
  format = format.replace(/@H@/g, ("0" + mydate.getHours()).slice(-2));
  //時(12時)
  temp = mydate.getHours() % 12 === 0 ? 12 : mydate.getHours() % 12;
  format = format.replace(/@g@/g, temp.toString());
  format = format.replace(/@h@/g, ("0" + temp).slice(-2));
  //分
  format = format.replace(/@i@/g, ("0" + mydate.getMinutes()).slice(-2));
  format = format.replace(/@s@/g, ("0" + mydate.getSeconds()).slice(-2));
  //AM,PM
  temp = mydate.getHours() < 12 ? "am" : "pm";
  format = format.replace(/@a@/g, temp);
  format = format.replace(/@A@/g, temp.toUpperCase());
  return format;
}
