import { useState, useEffect } from 'react'
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { UploadIcon } from '@heroicons/react/solid'
import { useFileView } from '../../providers/FileViewProvider';
import { useApi } from "../../hooks/useApi";
import {Spinner} from '../Spinner';

export type TOption = {
  value: string;
  label: string;
};

export type TToken = {
  id: number;
  token: string;
  expire: number;
  level: number;
}

export const Project = () => {

  const fileView = useFileView();
  const file = fileView.getFile();
  const id = file.id;
  const [project, setProject] = useState("");
  const { running, error, data, execute } = useApi();
  const [options, setOptions] = useState<TOption[]>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const navigate = useNavigate()
  const [level, setLevel] = useState(2);
  const [accountId, setAccountId] = useState(0);
  const [hash, setHash] = useState("");

  useEffect(() => {
    // ローカルストレージからキーを指定して取得
    const api_key = localStorage.getItem("token") || "";
    setHash(localStorage.getItem("hash") || "");
    if (api_key) {
      const token: TToken = JSON.parse(atob(api_key));
      setLevel(token.level);
      setAccountId(token.id);
    }
  }, []);


  useEffect(() => {
    setProject(file.project);
    // リストを作成
    const projects = fileView.getProjects();
    let newOptions: TOption[] = [];
    newOptions.push({ label: "", value: "" });  // index:0は入力用
    projects.forEach((value, index) => {
      if (file.project === value.project) {
        setSelectedIndex(index+1);
      }
      newOptions.push({
        value: value.project,
        label: value.project,
      });
    });
    setOptions(newOptions);
    // eslint-disable-next-line
  }, [file]);

  useEffect(() => {
    if (data) {
      file.project = project;
      fileView.updateFiles(file);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (error?.status === 401) {
      // ローカルストレージから対象のキーに紐づく値を削除
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      navigate('/');
    }
    // eslint-disable-next-line
  }, [error]);

  const saveProject = () => {
    const projects = fileView.getProjects();
    let dupe = false;
    for (let i = 0; i < projects.length; i++){
      if (projects[i].project === project) {
        dupe = true;
        break;
      }
    }
    const newProtects = dupe ? projects : [...projects, { project, count: 1 }];
    let config = {
      allMetaTags: fileView.getAllMetatags(),
      suppliers: fileView.getSuppliers(),
      projects: newProtects
    }
    fileView.setConfig(config);

    const params = { project };
    execute({ url: `/v1/update_file/${id}/`, params });
  };

  const customStyles = {
    menu: (provided:any, state:any) => ({
      ...provided,
      width: "250px",
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    control: (provided:any, state:any) => ({
      ...provided,
      width: "250px",
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    dropdownIndicator: (provided:any, state:any) => ({
      ...provided,
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    singleValue: (provided:any, state:any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  };

  const handleChange = (project: string) => {
    setProject(project);
    let newOptions: TOption[] = [...options!];
    newOptions[0] = { label: "", value: "" };
    setOptions(newOptions);

    if (project === "") {
      setSelectedIndex(-1);
    } else {
      newOptions!.forEach((value, index) => {
        if (project === value.value) {
          setSelectedIndex(index);
        }
      });
    }
  };

  const handleInput = (project: string) => {
    if (project !== "") {
      setProject(project);
    }
  };

  // 権限を確認する
  // 0:権限なし 1:固定リンク 2:他ユーザー 3:作成者 4:特権ユーザー
  // levels 0:"Baned", 1:"Guest", 2:"User", 3:"Audit", 4:"Manager", 5:"Admin"];
  const getPermission = () => {
    if (level === 5) return 4;  //特権ユーザー
    if (level === 4) {
      if (file.account_id === accountId) {
        return 3;  //Manager自身が作成者
      } else {
        return 4;  //Manager 他人が作成したものは特権ユーザーとして扱う
      }
    }
    if (level >= 2) {
      if (file.account_id === accountId) {
        return 3;  //作成者
      } else {
        return 2; //他ユーザー
      }
    }
    if (file.hash === hash) return 1;
    return 0; //権限なし
  }

  return level > 1 ? (
    <div>
      <dt className="text-xs font-medium text-gray-500">案件</dt>

            <dd className="inline-block">
              <div className="mt-1">
          { options && <Select
            onBlur={(e) => {
              let newOptions: TOption[] = [...options!];
              newOptions[0] = { label: project, value: project };
              setOptions(newOptions);
              setSelectedIndex( project === "" ? -1 : 0);
            }}
            className="text-gray-600 text-xs inline-block mr-2"
            styles={customStyles}
            options={options}
            value={options[selectedIndex!]}
            placeholder="入力または選択してください。"
            isClearable={true}
            noOptionsMessage={({ inputValue }) => !inputValue ? "" : "入力に一致する候補はありません"}
            onChange={(e) => {
              handleChange(e?.value || "");
            }}
            onInputChange={(e) => {
              handleInput(e || "");
            }}
          />}
              {project && project.length > 0 && getPermission() >= 3 ? (
                <div className="h-7 inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-blue-600 hover:bg-blue-700 text-white  cursor-pointer"
                  onClick={saveProject}
                >
                  <UploadIcon className="w-3 text-white" aria-hidden="true" />
                  <span className="ml-1"

                  >案件更新</span>
                </div>
              ) : (
                <div className="h-7 inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-gray-400 text-white ">
                  <UploadIcon className="h-3 w-3 text-white" aria-hidden="true" />
                  <span className="ml-1"

                  >案件更新</span>
                </div>
              )}

      {running && <Spinner text="Now File API processing..." />}
      {error && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  API実行中にエラーが発生しました。データは更新されていません。
                </span>
      }

              </div>
            </dd>

    </div>
  ) : (
          <div>
            <dt className="text-xs font-medium text-gray-500">案件</dt>

            <dd className="inline-block">
              <div className="mt-1 text-sm">
                {project}
              </div>
            </dd>
          </div>

  )
}

export default Project
