import {useState, useRef, useEffect } from 'react'
import { useFileView } from '../../providers/FileViewProvider';
import { XIcon } from '@heroicons/react/solid'
import Select from 'react-select';


export type TTagData = {
  id: number;
  name: string;
  bgColor: string;
  textColor: string;
  count ?: number;
}

export const tagDatas:TTagData[] = [
  { id: 0, name: '選択してください', bgColor: "bg-white", textColor: "text-white" },
  { id: 1, name: '見積書', bgColor: "bg-red-100", textColor: "text-red-800" },
  { id: 2, name: '注文発注書', bgColor: "bg-yellow-100", textColor: "text-yellow-800" },
  { id: 3, name: '納品書', bgColor: "bg-green-100", textColor: "text-green-800" },
  { id: 4, name: '検収書', bgColor: "bg-blue-100", textColor: "text-blue-800" },
  { id: 5, name: '請求書', bgColor: "bg-indigo-100", textColor: "text-indigo-800" },
  { id: 6, name: '領収書', bgColor: "bg-purple-100", textColor: "text-purple-800" },
  { id: 7, name: '契約書', bgColor: "bg-pink-100", textColor: "text-pink-800" },
  { id: 8, name: '重要書類', bgColor: "bg-orange-100", textColor: "text-orange-800" },
  { id: 9, name: '作業中', bgColor: "bg-emerald-100", textColor: "text-emerald-800" },
]

export type TAllTag = {
  id: number;
  meta_tag: string;
};

export type TOption = {
  value: string;
  label: string;
};

export const MetaTag = () => {
  const fileView = useFileView();
  const [tags, setTags] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>("");
  const [options, setOptions] = useState<TOption[]>([]);

  useEffect(() => {
    // タグリストを作成
    const allMetaTag = fileView.getAllMetatags();
    let newOptions: TOption[] = [];
    allMetaTag.forEach((value) => {
      newOptions.push({
        value: value.meta_tag,
        label: value.meta_tag,
      });
    });
    setOptions(newOptions);
    // eslint-disable-next-line
  }, []);


  const deleteTag = (tag: string) => {
    const newTag = tags.filter((value) => {
      return value !== tag;
    })
    setTags(newTag);
  };

  const addTag = () => {
    const isStaticTag = (tag: string) => {
      for (let i = 0; i<tagDatas.length; i++) {
        if (tagDatas[i].name === tag) return true;
      };
      return false;
    };
    // inputからカスタムタグを追加する
    const newTags = tags.filter((value) => {
      return value !== selected;
    })
    selected && newTags.push(selected);
    // カスタムタグを前方に持ってくる
    let newTags3: string[]= [];
    newTags.forEach((value) => {
      !isStaticTag(value) && newTags3.push(value);
    });
    newTags.forEach((value) => {
      isStaticTag(value) && newTags3.push(value);
    });
    setTags(newTags3);
    fileView.setNewMetaTags(newTags3);
  };

  const handleChange = (tagData: string) => {
    setSelected(tagData);
    // fileView.setSelectedMetaTag(tagData);
  };
  const handleInput = (tagData: string) => {
    if (tagData !== "") {
      setSelected(tagData);
      // fileView.setSelectedMetaTag(tagData);
    }
  };

  const customStyles = {
    menu: (provided:any, state:any) => ({
      ...provided,
      width: "300px",
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    control: (provided:any, state:any) => ({
      ...provided,
      width: "300px",
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    dropdownIndicator: (provided:any, state:any) => ({
      ...provided,
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    singleValue: (provided:any, state:any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  };

  return (
<>
        <div className="border-t border-gray-200 px-4 py-1 sm:px-6 my-2 ">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10">
            <div className="sm:col-span-10 mx-6">

            {/* タグ選択 */}
              <div className=" text-xs font-medium text-gray-500">タグ</div>
            <div className="inline-block">
              <Select
                className="text-gray-600 text-xs inline-block mr-2"
                styles={customStyles}
                options={options}
                placeholder="タグを入力するか選択してください。"
                isClearable={true}
                noOptionsMessage={({ inputValue }) => !inputValue ? "" : "入力に一致する候補はありません"}
                onChange={(e) => {
                  handleChange(e?.value || "");
                }}
                onInputChange={(e) => {
                  handleInput(e || "");
                }}
              />

                <span className="h-6 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-600 hover:bg-gray-700 text-gray-200 mx-4 cursor-pointer"
                onClick={() => addTag()}
                >
                  <span className="mx-0">+ 追加</span>
                </span>
            </div>

            </div>
          </dl>

          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10">
            <div className="sm:col-span-10 mx-6">
              <hr className="my-4" />
            {/* 登録タグを表示 */}
          {tags && tags.length ? <span className="text-xs font-medium text-gray-500 mr-2">保存するタグ</span> : null}
          {
            tags && tags.map((tag, index) => {
              let textColor = "text-gray-800";
              let bgColor = "bg-gray-200";
              for (let i = 0; i < tagDatas.length; i++){
                if (tagDatas[i].name === tag) {
                  textColor = tagDatas[i].textColor;
                  bgColor = tagDatas[i].bgColor;
                }
              }
              return (
                <span key={index}>
                  <div className={`h-6 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 ${bgColor}`}>
                    <span className="text-gray-800 cursor-pointer hover:text-red-900"
                      onClick={() =>deleteTag(tag)}
                    ><XIcon className="block h-3 w-3" aria-hidden="true" /></span>
                    <span className={`ml-1 ${textColor}`}>{tag}</span>
                  </div>
                </span>
              );
            })
          }

          {tags && tags.length ? <div className="text-xs text-gray-500 mt-2">※不要なタグは、×をクリックして削除してください。</div> : null}
          {!(tags && tags.length) ? <div className="text-xs text-gray-500 mt-2">タグは登録されていません。</div> : null}

            </div>
          </dl>


      </div>
</>
  );
}



export default MetaTag;
