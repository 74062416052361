import React, { useState, createContext, useContext } from "react";

export type TSetup = {
  tabIndex?: number;
};

export type TSetupContextType = {
  setup: TSetup | null;
  setTabIndex: (tabIndex :number) => void;
  getTabIndex: () => number;
};


// https://qiita.com/johnmackay150/items/88654e5064290c24a32a
function createCtx<ContextType>() {
  const ctx = createContext<ContextType | undefined>(undefined);
  function useCtx() {
    const c = useContext(ctx);
    if (!c) throw new Error("useCtx must be inside a Provider with a value");
    return c;
  }
  return [useCtx, ctx.Provider] as const;
}

export const [useSetup, SetSetupProvider] = createCtx<TSetupContextType>();

export const SetupProvider: React.FC = (props) => {
  const setup = useSetupCtx();
  return <SetSetupProvider value={setup}>{props.children}</SetSetupProvider>;
}

const useSetupCtx = (): TSetupContextType => {
  const [setup, setSetup] = useState<TSetup | null>(null);

  const setTabIndex = (tabIndex: number) => {
    setSetup({ ...setup, tabIndex });
  };

  const getTabIndex = () => {
    return setup ? setup!.tabIndex ? setup!.tabIndex : 0 : 0;
  };

  return {
    setup,
    setTabIndex,
    getTabIndex,
  };

};
