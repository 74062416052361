import {useState } from 'react'
import { useFileView } from '../../providers/FileViewProvider';
//https://react-icons.github.io/react-icons/icons?name=hi

export const Memo = () => {
  const fileView = useFileView();
  const [memo, setMemo] = useState("");

  return (
    <div className="border-t border-gray-200 px-4 py-1 sm:px-6 my-2">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10">
        <div className="sm:col-span-10">

            <div className="mx- my-4 mx-6" >
              <span className="inline-block text-xs font-medium text-gray-500">備考</span>
                <textarea
                  tabIndex={0}
                  rows={4}
                  name="comment"
                  id="comment"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-300 block w-full sm:text-sm border-gray-300 rounded p-2"
                  placeholder="備考を入力してください。"
                  defaultValue={memo}
                  onChange={(e) => {
                    setMemo(e.target.value);
                    fileView.setNewMemo(e.target.value);
                  }}
                />
            </div>
        </div>

  </dl>
</div>
  );
};

export default Memo;
