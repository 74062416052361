import { useState } from 'react'
import * as php from '../../libs/php_libs';
import { useEmailView } from '../../providers/EmailViewProvider';
import DatePicker, { DateObject } from "react-multi-date-picker"

const ja = {
  name: "ja",
  months: [
    ["1月", "1"],
    ["2月", "2"],
    ["3月", "3"],
    ["4月", "4"],
    ["5月", "5"],
    ["6月", "6"],
    ["7月", "7"],
    ["8月", "8"],
    ["9月", "9"],
    ["10月", "10"],
    ["11月", "11"],
    ["12月", "12"],
  ],
  weekDays: [
    ["土", "土"],
    ["日", "日"],
    ["月", "月"],
    ["火", "火"],
    ["水", "水"],
    ["木", "木"],
    ["金", "金"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"],
  ],
};


export const DateRangePicker = () => {
  const emailView = useEmailView();
  const [from, setFrom] = useState(-1);
  const [to, setTo] = useState(-1);

  const handleChangeFrom = (date: DateObject) => {
    const time = date ? php.mktime({ year: date.year, month: date.monthIndex + 1, day: date.day, hour: 0, minute: 0, second: 0 }) : -1;
    setFrom(time);
    emailView.setDateRange({ fromDate: time, toDate: to });
  };

  const handleChangeTo = (date:DateObject) => {
    const time = date ? php.mktime({ year: date.year, month: date.monthIndex + 1, day: date.day, hour: 23, minute: 59, second: 59 }) : -1;
    setTo(time);
    emailView.setDateRange({ fromDate: from, toDate: time });
  };



  //https://shahabyazdi.github.io/react-multi-date-picker/props/
  return (
  <>
    <div className="inline-block ml-4">
      <label htmlFor="fromDate" className="block text-sm font-medium text-gray-600">
        開始日時
      </label>
      <div className="mt-1">
        <DatePicker
          onChange={handleChangeFrom}
          locale={ja}
          showOtherDays={true}
          placeholder="指定なし"
          inputClass="h-9 w-32 block focus:ring-indigo-500 focus:border-indigo-500 text-sm font-medium text-gray-600 rounded px-2 py-0.5"
        />
      </div>
    </div>
    <span className="text-gray-400 mx-2">〜</span>
    <div className="inline-block">
      <label htmlFor="fromTo" className="block text-sm font-medium text-gray-600">
        終了日時
      </label>
      <div className="mt-1">
        <DatePicker
          onChange={handleChangeTo}
          locale={ja}
          showOtherDays={true}
          placeholder="指定なし"
          inputClass="h-9 w-32 block focus:ring-indigo-500 focus:border-indigo-500 text-sm font-medium text-gray-600 rounded px-2 py-0.5"
        />
      </div>
    </div>

  </>
  )
}

export default DateRangePicker;
