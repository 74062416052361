import {useState, useEffect} from 'react'
import sha256 from 'crypto-js/sha256';
import axios from "axios";
import printf from 'printf';

import {useEmailView} from '../../providers/EmailViewProvider';
import { Verify } from "./Verify";
import { Spinner } from "../../components/Spinner";
import { EmailAttachmentsPart } from "./EmailAttachmentsPart";

export const EmailHtmlPart = () => {
  const emailView = useEmailView();
  const email = emailView.getEmail();
  const [html, setHtml] = useState("");

  const [running, setRunning] = useState(false);

  const api_server = localStorage.getItem("api_server") || "https://archives.mirai.ad.jp/";
  const api = `${api_server}api/arc`;

  useEffect(() => {
    const f = async () => {
      const dir = printf("%010d", Math.floor(email.id / 10000));
      setRunning(true);
      const f = `${api_server}/data/emails/htmlfiles/${dir}/${email?.html.hash}.html`;
      axios.get(f)
        .then(response => {
          setRunning(false);
          setHtml(response.data);
        })
        .catch((err) => {
          setHtml("HTMLファイルの読み込みができませんでした。");
          setRunning(false);
        });
    };
    email?.html && f();
    // eslint-disable-next-line
  }, [email]);

  //https://zenn.dev/catnose99/articles/19a05103ab9ec7
  return (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6 min-h-screen overflow-scroll">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10 ">
        <div className="sm:col-span-10">
          <dd className="mt-1 text-sm text-gray-900 ">

            {email?.html ?
              <>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                    ※HTMLメール本文内は検索対象外です。必要に応じて備考やタグを設定してください。<br />
                    　HTMLメールは正しく表示されなかったり、表示が乱れることがあります。
                </span>
                <div dangerouslySetInnerHTML={{ __html: html }} />
                  {email.html && <EmailAttachmentsPart email={email} />}
                <Verify hash1={sha256(html).toString()} hash2={email.html.hash} />

                </>
              : <span className="text-gray-400">このメールにはHTMLメールはありません。</span>}
              {running && <Spinner />}
          </dd>
        </div>

      </dl>
    </div>
  );
}

export default EmailHtmlPart;
