import {useEmailView} from '../../providers/EmailViewProvider';

export const EmailHeaderPart = () => {
  const emailView = useEmailView();
  const email = emailView.getEmail();

  const headers = Object.entries(email.headers);

  return (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6 min-h-screen overflow-scroll">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10">
        <div className="sm:col-span-10">
          <dd className="mt-1 text-sm text-gray-900">
            {headers.map((header, index) => {
              if (Array.isArray(header[1])) {
                return header[1].map((header2, index2) => {
                  return (
                  <div key={index+index2}>
                    <span className="text-pink-800">{header[0] && header[0]}</span>
                      <span className="text-green-800">[{index2}]</span>:
                    <span className="ml-2">
                      {header2 && header2}
                    </span>
                  </div>
                )
                })
              } else {
                return (
                  <div key={index}>
                    <span className="text-indigo-800">{header[0] && header[0]}</span>:
                    <span className="ml-2">
                      {header[1] && header[1]}
                    </span>
                  </div>
                );
              }
            })
            }
          </dd>
        </div>
      </dl>
    </div>
  );
}
export default EmailHeaderPart;
