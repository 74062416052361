import {useState, useRef, useEffect } from 'react'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import {  Link } from 'react-router-dom';
import { useApi } from "../../hooks/useApi";
import {Spinner} from '../../components/Spinner';

export type TToken = {
  id: number;
  token: string;
  expire: number;
  level: number;
}

export const AccountPart = () => {
  const [showOld, setShowOld] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [serviceCode, setServiceCode] = useState("");
  const [apiServer, setApiServer] = useState("");
  const [appServer, setAppServer] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [level, setLevel] = useState(2);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [authError, setAuthError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [done, setDone] = useState(false);

  const { running, error, data, execute } = useApi();
  const levelName: string[] = ["無効ユーザー",  "ゲスト", "一般ユーザー", "監査ユーザー", "幹部ユーザー", "管理者"]

  useEffect(() => {
    // ローカルストレージからキーを指定して取得
    const api_key = localStorage.getItem("token") || "";
    const id = localStorage.getItem("id") || "";
    const service_code = localStorage.getItem("service_code") || "";
    const api_server = localStorage.getItem("api_server") || "";
    const app_server = localStorage.getItem("app_server") || "";
    const name = localStorage.getItem("name") || "";
    setServiceCode(service_code);
    setApiServer(api_server);
    setAppServer(app_server);
    setName(name);
    setId(id);
    if (api_key) {
      const token: TToken = JSON.parse(atob(api_key));
      setLevel(token.level);
    }

    // eslint-disable-next-line
  }, []);

  const changeName = (e:React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setDone(false);
    setAuthError(false);
    // setPasswordError(false);
  };

  const changeOldPassword = (e:React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value);
    setDone(false);
    setAuthError(false);
    // setPasswordError(false);
  };

  const changeNewPassword = (e:React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
    setDone(false);
    setAuthError(false);
    // setPasswordError(false);
  };

  useEffect(() => {
    if (data) {
      setOldPassword(newPassword);
      setDone(true);
    }
    if (error && error?.status === 402) {
      setAuthError(true);
      setDone(false);
    }
    // eslint-disable-next-line
  }, [error, data]);

  const updateAccount = () => {
    setDone(false);
    name.length === 0 && setNameError(true);
    oldPassword.length === 0 && setOldPasswordError(true);
    newPassword.length === 0 && setNewPasswordError(true);
    const params = {
      id: id,
      account: {
        name: name,
        old_password: oldPassword,
        new_password: newPassword,
      }
    };
    if (name.length && oldPassword.length && newPassword.length) {
      execute({ url: "/v1/setup/", params, redirect: false});
    }
  };

  return (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6  overflow-scroll m-6 rounded-md bg-gray-100">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10 ">
        <div className="sm:col-span-10">
          <dd className="mt-1 text-sm  ">







      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">アカウント情報</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              お客様のアカウント情報です。
            </p>
          </div>

          <div className="mt-2 space-y-2 sm:space-y-2">


            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700 my-2">
                サービスコード
              </label>
              <div className="my-2 sm:col-span-3">
                        <div className="">
                          {serviceCode}
                        </div>
                        <div className="mt-2">
                          <Link to="/config" className="text-sm text-cyan-800 font-bold " >サービスコードの変更はこちら</Link>
                        </div>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700 my-1">
                APIサーバー
              </label>
              <div className="my-1 sm:col-span-3">
                <div className="">
                  {apiServer}
                </div>
              </div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-700 my-1">
                APPサーバー
              </label>
              <div className="my-1 sm:col-span-3">
                <div className="">
                  {appServer}
                </div>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700 my-2">
                お名前
              </label>
              <div className="my-2 sm:col-span-3">
                  <input
                    onChange={changeName}
                    value={name}
                    id="name"
                    name="name"
                    type="text"
                    required
                    className="appearance-none  px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                {nameError && (
                <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-1 rounded  mt-3 table" role="alert">
                  <span className=" sm:inline text-sm">お名前 が入力されていません。</span>
                </div>
                )}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700 my-2">
                権限
              </label>
              <div className="my-2 sm:col-span-3">
              <div className="">
                {levelName[level]}
              </div>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700 my-2">
                ログインID
              </label>
              <div className="my-2 sm:col-span-2">
                <div className="">
                  {id}
                </div>
              </div>
            </div>


            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                現在のログインパスワード
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-3">
                <div className="max-w-lg flex rounded-md">


                  <div className="relative">
                    <input
                      onChange={changeOldPassword}
                      value={oldPassword}
                      id="old_password"
                      name="old_password"
                      type={showOld ? "text": "password" }
                      required
                      className="appearance-none block w-64 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                      {!showOld && <EyeIcon width={24} color="#909090" cursor="pointer" onClick={() => {setShowOld(true)}} />}
                      {showOld && <EyeOffIcon width={24} color="#909090" cursor="pointer" onClick={() => {setShowOld(false)}}/>}
                    </div>
                  </div>
                </div>
                {oldPasswordError && (
                <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-1 rounded  mt-3 table" role="alert">
                  <span className=" sm:inline text-sm">現在のログインパスワード が入力されていません。</span>
                </div>
                )}
              {authError && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-3 rounded relative table" role="alert">
                  <strong className="font-bold text-sm">認証エラー</strong><br />
                  <span className="block sm:inline text-sm pr-8">現在のログインパスワードが間違っています。</span>
                  <span className="absolute top-0 bottom-0 right-0 px-3 py-3"
                    onClick={() => setAuthError(false)}>
                    <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                  </span>
                </div>
              )}

              </div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                新しいログインパスワード
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-3">
                <div className="max-w-lg flex rounded-md">


                  <div className="relative">
                    <input
                      onChange={changeNewPassword}
                      value={newPassword}
                      id="new_password"
                      name="new_password"
                      type={showNew ? "text": "password" }
                      required
                      className="appearance-none block w-64 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                      {!showNew && <EyeIcon width={24} color="#909090" cursor="pointer" onClick={() => {setShowNew(true)}} />}
                      {showNew && <EyeOffIcon width={24} color="#909090" cursor="pointer" onClick={() => {setShowNew(false)}}/>}
                    </div>
                  </div>
                </div>
                {newPasswordError && (
                <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-1 rounded  mt-3 table" role="alert">
                  <span className=" sm:inline text-sm">新しいログインパスワード が入力されていません。</span>
                </div>
                )}
              {done && (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mt-3 rounded relative table" role="alert">
                  <strong className="font-bold text-sm">変更完了</strong><br />
                  <span className="block sm:inline text-sm pr-8">アカウント情報の変更が完了しました。</span>
                  <span className="absolute top-0 bottom-0 right-0 px-3 py-3"
                    onClick={() => setAuthError(false)}>
                    <svg className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                  </span>
                </div>
              )}
              </div>
            </div>



          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
                {! running ? (
                  <button
                    onClick={updateAccount}
                    type="submit"
                    className="ml-4 w-24 mt-4 rounded px-3 py-1 bg-blue-600 hover:bg-blue-700 text-white font-bold focus:shadow-outline focus:outline-none"
                  >
                    変 更
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="ml-4 w-24 mt-4 rounded px-3 py-1 bg-gray-500 cursor-default text-white font-bold focus:shadow-outline focus:outline-none"
                  >
                    変 更
                  </button>
                )}
        </div>
      </div>

      {running && <Spinner text="Now Setup API processing..." />}




          </dd>
        </div>
        </dl>
    </div>
  )
}

export default AccountPart
