import { useState , useEffect} from 'react'
import {useEmailView} from '../../providers/EmailViewProvider';

export type TTarget = {
  id: number,
  name: string,
  checked: boolean
}
const defaultTargets:TTarget[] = [
  { id: 1, name: 'メール本文', checked: true},
  { id: 2, name: '件名', checked: true},
  { id: 3, name: '備考', checked: true},
  { id: 4, name: '差出人', checked: true},
  { id: 5, name: '宛先',checked: false},
  // { id: 6, name: '管理番号', checked: false},
  { id: 7, name: '添付あり', checked: false},
  { id: 8, name: 'タグあり', checked: false},
  { id: 9, name: '追加あり', checked: false},
  { id: 10, name: '備考あり', checked: false},
];

export const SearchTarget = () => {
  const emailView = useEmailView();
  const [targets, setTargets] = useState(defaultTargets);

  useEffect(() => {
    let searchTargets : string[] = [];
    targets.forEach((target) => {
      if (target.checked) {
        searchTargets.push(target.name);
      }
    });
    emailView.setSearchTarget(searchTargets);
    // eslint-disable-next-line
  }, [targets]);

  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    // event.preventDefault();
    // if (event.isDefaultPrevented()) {
    //   event.stopPropagation();
    // }
    let newTargets: TTarget[] = [];
    targets.forEach((value, index) => {
      if (value.name !== event.target.name) {
        newTargets.push(value);
      } else {
        newTargets.push({
          id: value.id,
          name: value.name,
          checked: !value.checked,
        })
      }
    });
    setTargets(newTargets);
  }

  return (
    <div>
      <div>
        <span className="text-gray-700 text-sm font-medium">検索対象</span>
      </div>
      {targets.map((target) =>
        <label key={target.id} className="ml-4" >
          <input
              id={target.name}
              aria-describedby="offers-description"
              name={target.name}
              type="checkbox"
              className="focus:ring-indigo-500 h-3 w-3 text-indigo-600 border-gray-300 rounded"
              checked={target.checked}
              value={target.id}
              onChange={handleChange}
          />
          <span className={`text-sm ml-1 hover:text-gray-800 ${target.checked ? "text-gray-700" : "text-gray-500"} `}>{target.name}</span>
      </label>
    )}




    </div>
  )
}

export default SearchTarget
