export const SetupMainContainer = (props: { children: React.ReactNode }) => {
  const children = props.children;
  return (
    <div className="m-4">
        <div className="bg-gray-300 shadow pb-4 rounded-md">
        {children}
        </div>
      </div>
  );
}

export default SetupMainContainer;
