import { useState, useEffect } from 'react'
import {useEmailView} from '../../providers/EmailViewProvider';
import Select from 'react-select';

import {TTagData } from "../EmailView/MetaTag";

export type TMetatag = {
  id: number,
  meta_tag: string,
  count: number
}

export type TOption = {
  value: string;
  label: string;
};

export const ReservedTagDatas: TTagData[] = [
  { id: 0, name: '指定なし', bgColor: "bg-white", textColor: "text-white" },
  { id: 1, name: '見積書', bgColor: "bg-red-100", textColor: "text-red-800" },
  { id: 2, name: '注文発注書', bgColor: "bg-yellow-100", textColor: "text-yellow-800" },
  { id: 3, name: '納品書', bgColor: "bg-green-100", textColor: "text-green-800" },
  { id: 4, name: '検収書', bgColor: "bg-blue-100", textColor: "text-blue-800" },
  { id: 5, name: '請求書', bgColor: "bg-indigo-100", textColor: "text-indigo-800" },
  { id: 6, name: '領収書', bgColor: "bg-purple-100", textColor: "text-purple-800" },
  { id: 7, name: '契約書', bgColor: "bg-pink-100", textColor: "text-pink-800" },
  { id: 8, name: '重要書類', bgColor: "bg-orange-100", textColor: "text-orange-800" },
  { id: 9, name: '作業中', bgColor: "bg-emerald-100", textColor: "text-emerald-800" },
];


export const MetaTagSelect = (props: { metaTags: TMetatag[] }) => {
  const metaTags = props.metaTags;
  const [selected, setSelected] = useState("");
  const [options, setOptions] = useState<TOption[]>([]);
  const emailView = useEmailView();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    // リストを作成
    let newOptions :TOption[] = [];
    newOptions.push({ label: "", value: "" });  // index:0は入力用
    metaTags.forEach((metaTag, index) => {
      if (metaTag.meta_tag === emailView.getSelectedMetaTag()) {
        setSelectedIndex(index+1);
      }
      newOptions.push(
          {
            value: metaTag.meta_tag,
            label: `${metaTag.meta_tag} (${metaTag.count})`,
          }
      );
    });
    setOptions(newOptions);
    // eslint-disable-next-line
  }, [metaTags])


  const handleChange = (tagData: string) => {
    setSelected(tagData);
    let newOptions: TOption[] = [...options!];
    newOptions[0] = { label: "", value: "" };
    setOptions(newOptions);
    emailView.setSelectedMetaTag(tagData);
    if (tagData === "") {
      setSelectedIndex(-1);
    } else {
      newOptions!.forEach((value, index) => {
        if (tagData === value.value) {
          setSelectedIndex(index);
        }
      });
    }
  };

  const handleInput = (tagData: string) => {
    if (tagData !== "") {
      setSelected(tagData);
      emailView.setSelectedMetaTag(tagData);
    }
  };

  const customStyles = {
    menu: (provided:any, state:any) => ({
      ...provided,
      width: "200px",
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    control: (provided:any, state:any) => ({
      ...provided,
      width: "200px",
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    dropdownIndicator: (provided:any, state:any) => ({
      ...provided,
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    singleValue: (provided:any, state:any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  };

  return (
    <div className="inline-block ml-4">
      <label htmlFor="email" className="block text-sm font-medium text-gray-600">
        タグ
      </label>
      <div className="mt-1">
              <Select
                onBlur={(e) => {
                  let newOptions: TOption[] = [...options!];
                  newOptions[0] = { label: selected, value: selected };
                  setOptions(newOptions);
                  setSelectedIndex( selected === "" ? -1 : 0);
                }}
                className="text-gray-600 text-xs inline-block mr-2"
                styles={customStyles}
                options={options}
                value={options[selectedIndex!]}
                placeholder="指定なし"
                isClearable={true}
                noOptionsMessage={({ inputValue }) => !inputValue ? "" : "入力に一致する候補はありません"}
                onChange={(e) => {
                  handleChange(e?.value || "");
                }}
                onInputChange={(e) => {
                  handleInput(e || "");
                }}
              />
      </div>
    </div>

  )
}

export default MetaTagSelect
