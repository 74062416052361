import { useState, useEffect } from 'react'
import Select from 'react-select';
import { useFileView } from '../../providers/FileViewProvider';

export type TOption = {
  value: string;
  label: string;
};

export const Supplier = () => {

  const fileView = useFileView();
  const [supplier, setSupplier] = useState("");
  const [options, setOptions] = useState<TOption[]>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);


  useEffect(() => {
    // リストを作成
    const suppliers = fileView.getSuppliers();
    let newOptions: TOption[] = [];
    newOptions.push({ label: "", value: "" });  // index:0は入力用
    suppliers.forEach((value, index) => {
      newOptions.push({
        value: value.supplier,
        label: value.supplier,
      });
    });
    setOptions(newOptions);
    // eslint-disable-next-line
  }, []);

  const customStyles = {
    menu: (provided:any, state:any) => ({
      ...provided,
      width: "250px",
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    control: (provided:any, state:any) => ({
      ...provided,
      width: "250px",
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    dropdownIndicator: (provided:any, state:any) => ({
      ...provided,
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    singleValue: (provided:any, state:any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  };

  const handleChange = (supplier: string) => {
    setSupplier(supplier);
    fileView.setNewSupplier(supplier);
    let newOptions: TOption[] = [...options!];
    newOptions[0] = { label: "", value: "" };
    setOptions(newOptions);

    if (supplier === "") {
      setSelectedIndex(-1);
    } else {
      newOptions!.forEach((value, index) => {
        if (supplier === value.value) {
          setSelectedIndex(index);
        }
      });
    }
  };

  const handleInput = (supplier: string) => {
    if (supplier !== "") {
      setSupplier(supplier);
      fileView.setNewSupplier(supplier);
    }
  };

  return (
    <div>
      <dt className="text-xs font-medium text-gray-500">取引先</dt>

            <dd className="inline-block">
              <div className="mt-1">
          { options && <Select
            onBlur={(e) => {
              let newOptions: TOption[] = [...options!];
              newOptions[0] = { label: supplier, value: supplier };
              setOptions(newOptions);
              setSelectedIndex( supplier === "" ? -1 : 0);
            }}
            className="text-gray-600 text-xs inline-block mr-2"
            styles={customStyles}
            options={options}
            value={options[selectedIndex!]}
            placeholder="入力または選択してください。"
            isClearable={true}
            noOptionsMessage={({ inputValue }) => !inputValue ? "" : "入力に一致する候補はありません"}
            onChange={(e) => {
              handleChange(e?.value || "");
            }}
            onInputChange={(e) => {
              handleInput(e || "");
            }}
          />}


              </div>
            </dd>

    </div>
  )
}

export default Supplier
