import Navbar from '../components/Navbar';
import EmailSearch from '../components/EmailSearch';
import {EmailViewProvider} from '../providers/EmailViewProvider';


export const EmailArc: React.FC = () => {

  return (
    <>
      <EmailViewProvider>
        <Navbar active="メールアーカイブ" />
        <EmailSearch />
      </EmailViewProvider>
    </>
	);
}

export default EmailArc;
