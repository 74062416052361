import { useState, useEffect } from 'react'
import {useFileView} from '../../providers/FileViewProvider';
import Select from 'react-select';

import {TTagData } from "../EmailView/MetaTag";

export type TMetatag = {
  id: number,
  meta_tag: string,
  count: number
}

export type TOption = {
  value: string;
  label: string;
};


export const ReservedTagDatas:TTagData[] = [
  { id: 0, name: '指定なし', bgColor: "bg-white", textColor: "text-white" },
  { id: 1, name: '見積書', bgColor: "bg-red-100", textColor: "text-red-800" },
  { id: 2, name: '注文発注書', bgColor: "bg-yellow-100", textColor: "text-yellow-800" },
  { id: 3, name: '納品書', bgColor: "bg-green-100", textColor: "text-green-800" },
  { id: 4, name: '検収書', bgColor: "bg-blue-100", textColor: "text-blue-800" },
  { id: 5, name: '請求書', bgColor: "bg-indigo-100", textColor: "text-indigo-800" },
  { id: 6, name: '領収書', bgColor: "bg-purple-100", textColor: "text-purple-800" },
  { id: 7, name: '契約書', bgColor: "bg-pink-100", textColor: "text-pink-800" },
  { id: 8, name: '重要書類', bgColor: "bg-orange-100", textColor: "text-orange-800" },
  { id: 9, name: '作業中', bgColor: "bg-emerald-100", textColor: "text-emerald-800" },
]

export const MetaTagSelect = (props: { metaTags: TMetatag[] }) => {
  const metaTags = props.metaTags;
  const [selected, setSelected] = useState("");
  const [options, setOptions] = useState<TOption[]>([]);
  const fileView = useFileView();

  useEffect(() => {
    let newOptions :TOption[] = [];
    metaTags.forEach((metaTag) => {
      newOptions.push(
          {
            value: metaTag.meta_tag,
            label: `${metaTag.meta_tag} (${metaTag.count})`,
          }
      );
    });
    setOptions(newOptions);

  }, [metaTags])

  const handleChange = (tagData: string) => {
    setSelected(tagData);
    fileView.setSelectedMetaTag(tagData);
  };

  const customStyles = {
    menu: (provided:any, state:any) => ({
      ...provided,
      width: "200px",
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    control: (provided:any, state:any) => ({
      ...provided,
      width: "200px",
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    dropdownIndicator: (provided:any, state:any) => ({
      ...provided,
      color: state.selectProps.menuColor,
      padding: 0,
    }),
    singleValue: (provided:any, state:any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  };

  return (
    <div className="inline-block ml-4">
      <label htmlFor="email" className="block text-sm font-medium text-gray-600">
        タグ
      </label>
      <div className="mt-1">
        {/* <input
          onChange={handleChange}
          type="text"
          name="Suppliers"
          id="Suppliers"
          className="h-7 focus:ring-indigo-500 focus:border-indigo-500 block w-52 sm:text-sm border-gray-300 rounded px-2 py-0.5"
          placeholder="指定なし"
        /> */}
              <Select
                className="text-gray-600 text-xs inline-block mr-2"
                styles={customStyles}
                options={options}
                placeholder="指定なし"
                isClearable={true}
                noOptionsMessage={({ inputValue }) => !inputValue ? "" : "入力に一致する候補はありません"}
                onChange={(e) => {
                  handleChange(e?.value || "");
                }}
              />
      </div>
    </div>

  )
}

export default MetaTagSelect
