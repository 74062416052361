export const Spinner = (props: { text?: string }) => {
  const text = props.text || "Now loading...";
  return (
<div className="flex justify-center mt-10">
  <div className="animate-spin h-8 w-8 bg-blue-300 rounded-xl"></div>
      <span className="text-gray-600 ml-4 mt-1">{text}</span>
</div>
  );
};

export default Spinner;
