import { useEffect, useState} from 'react';
import { useLayoutEffect, useRef } from 'react'
import * as php from '../../libs/php_libs';
import { useApi } from "../../hooks/useApi";
import {Spinner} from '../Spinner';

export type TPeople = {
    name: string,
    title: string
    email: string
    role: string
  }

const people : TPeople[] = [
  {
    name: 'Lindsay Walton',
    title: 'Front-end Developer',
    email: 'lindsay.walton@example.com',
    role: 'Member',
  },
  {
    name: 'Lindsay Walton',
    title: 'Front-end Developer',
    email: 'lindsay.walton@example.com',
    role: 'Member',
  },
  {
    name: 'Lindsay Walton',
    title: 'Front-end Developer',
    email: 'lindsay.walton@example.com',
    role: 'Member',
  },
  // More people...
]

export type TRequest = {
  account_name: string,
  content_id: number
  request_id: number
  created_at: string
  name: string
  reason: string
  // Downloadリンク生成に必用
  hash: string,
  expire: number,
  key: string,
  // 固定リンク生成に必用
  link_hash: string,
  }

export type TToken = {
  id: number;
  token: string;
  expire: number;
  level: number;
}

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

const Approval = () => {
  const [requestFileCount, setRequestFileCount] = useState(0);
  const { running: runningGetRequest, data: dataGetRequest, error: errorGetRequest, execute: executeGetRequest } = useApi();
  const { running: runningRejectRequest, data: dataRejectRequest, error: errorRejectRequest, execute: executeRejectRequest } = useApi();
  const { running: runningDeleteRequest, data: dataDeleteRequest, error: errorDeleteRequest, execute: executeDeleteRequest } = useApi();
  const { running: runningDeleteBatchRequest, data: dataDeleteBatchRequest, error: errorDeleteBatchRequest, execute: executeDeleteBatchRequest } = useApi();
  const checkbox = useRef(null)
  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [selectedFile, setSelectedFile] = useState<TRequest[]>([])
  const [fileRequets, setFileRequests] = useState<TRequest[]>([])
  const [url, setUrl] = useState("");
  const [accountId, setAccountId] = useState(0);
  const [level, setLevel] = useState(2);
  const api_server = localStorage.getItem("api_server") || "https://archives.mirai.ad.jp/";
  const api = `${api_server}api/arc`;

  useLayoutEffect(() => {
    const isIndeterminate = selectedFile.length > 0 && selectedFile.length < people.length
    setChecked(selectedFile.length === people.length)
    setIndeterminate(isIndeterminate)
    // checkbox!.current!.indeterminate = isIndeterminate
  }, [selectedFile])

  function toggleAll() {
    setSelectedFile(checked || indeterminate ? [] : fileRequets)
    setChecked(!checked && !indeterminate)
    setIndeterminate(false)
  }

  useEffect(() => {
    // ローカルストレージからキーを指定して取得
    const api_key = localStorage.getItem("token") || "";
    if (api_key) {
      const token: TToken = JSON.parse(atob(api_key));
      setAccountId(token.id);
      setLevel(token.level);
    }

    setUrl(`${window.location.protocol}//${window.location.hostname}`);
    executeGetRequest({ url: "/v1/get_requests" });
    // eslint-disable-next-line
  }, []);

  // APIで取得した申請を処理する
  useEffect(() => {
    if (dataGetRequest) {
      if (dataGetRequest?.files) {
        setRequestFileCount(dataGetRequest.files.length);
        setFileRequests(dataGetRequest.files);
      }
    };
    // eslint-disable-next-line
  }, [dataGetRequest]);

  // APIで取得した申請を処理する
  useEffect(() => {
    if (dataRejectRequest) {
      if (dataRejectRequest?.reject_file) {
        const id = dataRejectRequest?.reject_file;
        // ファイルリストを更新する
        let newFileRequest: TRequest[] = [];
        fileRequets.forEach(
          (value) => {
            if (id !== value.request_id) {
              newFileRequest.push(value);
            }
          }
        );
        setRequestFileCount(newFileRequest.length);
        setFileRequests(newFileRequest);
      }
    }
    // eslint-disable-next-line
  }, [dataRejectRequest]);

  // APIで取得した申請を処理する
  useEffect(() => {
    if (dataDeleteRequest) {
      if (dataDeleteRequest?.delete_file) {
        const id = dataDeleteRequest?.delete_file;
        // ファイルリストを更新する
        let newFileRequest: TRequest[] = [];
        fileRequets.forEach(
          (value) => {
            if (id !== value.request_id) {
              newFileRequest.push(value);
            }
          }
        );
        setRequestFileCount(newFileRequest.length);
        setFileRequests(newFileRequest);
      }
    }
    // eslint-disable-next-line
  }, [dataDeleteRequest]);

  // APIで取得した申請を処理する
  useEffect(() => {
    if (dataDeleteBatchRequest) {
      if (dataDeleteBatchRequest?.delete_files) {
        const files = dataDeleteBatchRequest?.delete_files;
        // ファイルリストを更新する
        let newFileRequest: TRequest[] = [];
        fileRequets.forEach(
          (value) => {
            if ( ! files.includes(value.request_id)  ){
              newFileRequest.push(value);
            }
          }
        );
        setRequestFileCount(newFileRequest.length);
        setFileRequests(newFileRequest);
      }
    }
    // eslint-disable-next-line
  }, [dataDeleteBatchRequest]);

  // 削除申請を却下する
  const RejectRequest = (id: number, hash:string) => {
    const params = {
      id,
      hash
    };
    executeRejectRequest({ url: `/v1/reject_request/`, params });
  }

  // 1ファイル削除申請を承認する（削除を実行する）
  const DeleteRequest = (id: number, hash:string) => {
    const params = {
      id,
      hash
    };
    executeDeleteRequest({ url: `/v1/delete_request/`, params });
  }

  // 一括ファイル削除申請を承認する（削除を実行する）
  const DeleteBatchRequest = () => {
    const params = {
      files: selectedFile
    };
    console.log("+++++ selectedFile ++++++");
    console.log(selectedFile);
    executeDeleteBatchRequest({ url: `/v1/delete_batch_request/`, params });
  }

  return requestFileCount > 0 ? (
<>
    <div className="px-4 sm:px-6 lg:px-8 mt-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">ファイル削除申請(本来はMGアカウント以上の権限)</h1>
          <p className="mt-2 text-sm text-gray-700">
            ファイル削除処理の申請があります。承認または、却下を行ってください。
          </p>
        </div>
      </div>

      <div className="mt-8 flex flex-col">
        <p className="text-sm text-gray-800 mb-1">ファイル管理</p>
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {selectedFile.length > 0 && (
                <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
                  <button
                    type="button"
                    className="inline-flex items-center rounded border border-gray-300 bg-blue-600 px-2.5 py-1.5 text-xs font-bold text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                    onClick={() => {
                      DeleteBatchRequest();
                    }}
                  >
                    一括承認
                  </button>
                </div>
              )}
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 ">
                      管理番号
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      ファイル名
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      申請者
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/4">
                      削除理由
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      申請日時
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">承認</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {fileRequets.map((request) => (
                    <tr key={request.request_id} className={selectedFile.includes(request) ? 'bg-gray-50' : undefined}>
                      <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                        {selectedFile.includes(request) && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                        )}
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                          value={request.request_id}
                          checked={selectedFile.includes(request)}
                          onChange={(e) =>
                            setSelectedFile(
                              e.target.checked
                                ? [...selectedFile, request]
                                : selectedFile.filter((p) => p !== request)
                            )
                          }
                        />
                      </td>
                      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{request.request_id}</td> */}
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <a href={`${url}/filelink/${request.content_id}/${request?.link_hash}`} target="_blank" rel="noreferrer">{request.content_id}</a>
                      </td>
                      <td
                        className={classNames(
                          'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                          selectedFile.includes(request) ? 'text-indigo-600' : 'text-gray-900'
                        )}
                      >
                        <a href={`${api}/download/${request.content_id}/${request.hash}/f/${request.expire}/${request.key}/${accountId}`} >
                          {request.name}
                        </a>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">{request.account_name}</td>
                      <td className="px-3 py-4 text-sm text-gray-500 break-words ">{request.reason}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{request.created_at.replace(/-/g,"/")}</td>
                      <td>
                        <div className="mt-1 mr-3 top-0 left-12  h-12 items-center space-x-3 bg-gray-50 sm:left-16 inline-block">
                          <button
                            type="button"
                            className="inline-flex items-center rounded border border-gray-300 bg-blue-600 px-2.5 py-1.5 text-xs text-white font-bold shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2  disabled:cursor-not-allowed disabled:opacity-30"
                            onClick={() => {
                              DeleteRequest(request.request_id, request.hash);
                            }}
                          >
                            承認
                          </button>
                        </div>
                        <div className="top-0 left-12  h-12 items-center space-x-3 bg-gray-50 sm:left-16 inline-block">
                          <button
                            type="button"
                            className="inline-flex items-center rounded border border-gray-300 bg-red-600 px-2.5 py-1.5 text-xs text-white font-bold shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 disabled:cursor-not-allowed disabled:opacity-30"
                            onClick={() => {
                              RejectRequest(request.request_id, request.hash);
                            }}
                          >
                            却下
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>



      {runningGetRequest && <Spinner text="Now Get Request API processing..." />}
      {runningRejectRequest && <Spinner text="Now Reject Request API processing..." />}
      {runningDeleteRequest && <Spinner text="Now Delete Request API processing..." />}
      {runningDeleteBatchRequest && <Spinner text="Now Delete Batch Request API processing..." />}

      {(errorGetRequest || errorRejectRequest || errorDeleteRequest || errorDeleteBatchRequest) && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  API実行中にエラーが発生しました。データは更新されていません。
                </span>
      }

</>
  ) : (
<>
    <div className="px-4 sm:px-6 lg:px-8 mt-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">ファイル削除申請</h1>
          <p className="mt-2 text-sm text-gray-700">
            対応が必要な申請はありません。
          </p>
        </div>
      </div>
  </div>
</>
    )
}

export default Approval
