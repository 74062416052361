import { useState, useEffect } from 'react'
import * as php from '../../libs/php_libs';
import { useNavigate } from 'react-router-dom';
import { UploadIcon } from '@heroicons/react/solid'
import { useFileView } from '../../providers/FileViewProvider';
import { useApi } from "../../hooks/useApi";
import {Spinner} from '../Spinner';

export type TToken = {
  id: number;
  token: string;
  expire: number;
  level: number;
}

export const Amount = () => {
  const fileView = useFileView();
  const file = fileView.getFile();
  const id = file.id;
  const [amount, setAmount] = useState("");
  const [tax, setTax] = useState("");
  const { running, error, data, execute } = useApi();
  const navigate = useNavigate()
  const [level, setLevel] = useState(2);
  const [accountId, setAccountId] = useState(0);
  const [hash, setHash] = useState("");

  useEffect(() => {
    // ローカルストレージからキーを指定して取得
    const api_key = localStorage.getItem("token") || "";
    setHash(localStorage.getItem("hash") || "");
    if (api_key) {
      const token: TToken = JSON.parse(atob(api_key));
      setLevel(token.level);
      setAccountId(token.id);
    }
  }, []);

  useEffect(() => {
    setAmount(file.amount.toString());
    setTax(file.tax.toString());
    // eslint-disable-next-line
  }, [file]);

  useEffect(() => {
    if (data) {
      file.amount = Number(amount);
      file.tax = Number(tax);
      fileView.updateFiles(file);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (error?.status === 401) {
      // ローカルストレージから対象のキーに紐づく値を削除
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      navigate('/');
    }
    // eslint-disable-next-line
  }, [error]);

  const saveAmount = () => {

    const params = {
      amount: Number(amount.replace(/(?!^)-|[^-\d]/g, "")),
      tax: Number(tax.replace(/(?!^)-|[^-\d]/g, ""))
    };
    execute({ url: `/v1/update_file/${id}/`, params });
  };

  // 権限を確認する
  // 0:権限なし 1:固定リンク 2:他ユーザー 3:作成者 4:特権ユーザー
  // levels 0:"Baned", 1:"Guest", 2:"User", 3:"Audit", 4:"Manager", 5:"Admin"];
  const getPermission = () => {
    if (level === 5) return 4;  //特権ユーザー
    if (level === 4) {
      if (file.account_id === accountId) {
        return 3;  //Manager自身が作成者
      } else {
        return 4;  //Manager 他人が作成したものは特権ユーザーとして扱う
      }
    }
    if (level >= 2) {
      if (file.account_id === accountId) {
        return 3;  //作成者
      } else {
        return 2; //他ユーザー
      }
    }
    if (file.hash === hash) return 1;
    return 0; //権限なし
  }

  return level >1 ? (
<>
    <div>


      <dt className="text-xs font-medium text-gray-500">
        <span className="">税込金額と消費税</span>
      </dt>

      <dd className="inline-block">
        <div className="mt-1">
          <input
            maxLength={12}
            type="search"
            name="amount"
            id="amount"
            className="h-9 shadow-sm focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm border-gray-300 px-4 rounded mr-2 w-36"
            placeholder="税込金額"
            value={ isNaN(Number(amount)) || amount === "" ? amount : php.number_format(Number(amount))}
            onChange={(e) => {
              let removed = e.target.value.replace(/(?!^)-|[^-\d]/g, "");
              setAmount(removed);
            }}
            disabled={getPermission()<3}
          />



          <input
            maxLength={12}
            type="search"
            name="tax"
            id="tax"
            className="h-9 shadow-sm focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm border-gray-300 px-4 rounded mr-2 w-36"
            placeholder="消費税"
            value={ isNaN(Number(tax)) || tax === "" ? tax : php.number_format(Number(tax))}
            onChange={(e) => {
              let removed = e.target.value.replace(/(?!^)-|[^-\d]/g, "");
              setTax(removed);
            }}
            disabled={getPermission()<3}
          />

          {amount && tax &&  !isNaN(Number(amount.replace(/(?!^)-|[^-\d]/g, ""))) && !isNaN(Number(tax.replace(/(?!^)-|[^-\d]/g, ""))) && getPermission() >= 3? (
              <div className="h-7 inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-blue-600 hover:bg-blue-700 text-white  cursor-pointer"
                onClick={saveAmount}
              >
                <UploadIcon className="h-3 w-3 text-white" aria-hidden="true" />
              <span className="ml-1"

              >金額更新</span>
              </div>
              ) : (
              <div className="h-7 inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-gray-400 text-white ">
                <UploadIcon className="h-3 w-3 text-white" aria-hidden="true" />
              <span className="ml-1"

              >金額更新</span>
              </div>
              )}

      {running && <Spinner text="Now File API processing..." />}
      {error && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  API実行中にエラーが発生しました。データは更新されていません。
                </span>
      }

              </div>
            </dd>


    </div>

    <div className="text-xs font-medium text-gray-500 mt-2">
        <span className={`ml-4 cursor-pointer ${!isNaN(Math.floor(Number(amount) * 8 / 108)) && Math.floor(Number(amount) * 8 / 108) === Number(tax) && 'text-indigo-800'}`}
          onClick={()=>setTax(Math.floor(Number(amount) * 8 / 108).toString())}
        >8%切捨
        <span className="ml-2">
          {!isNaN(Math.floor(Number(amount)*8/108)) ? php.number_format(Math.floor(Number(amount)*8/108))+"円" : <span className="text-red-600">不正な金額です</span>}
        </span>
      </span>
        <span className={`ml-4 cursor-pointer ${!isNaN(Math.round(Number(amount) * 8 / 108)) && Math.round(Number(amount) * 8 / 108) === Number(tax) && 'text-indigo-800'}`}
          onClick={()=>setTax(Math.round(Number(amount) * 8 / 108).toString())}
        >8%四捨五入
        <span className="ml-2">
          {!isNaN(Math.round(Number(amount)*8/108)) ? php.number_format(Math.round(Number(amount)*8/108))+"円" : <span className="text-red-600">不正な金額です</span>}
        </span>
      </span>
        <span className={`ml-4 cursor-pointer ${!isNaN(Math.ceil(Number(amount) * 8 / 108)) && Math.ceil(Number(amount) * 8 / 108) === Number(tax) && 'text-indigo-800'}`}
          onClick={()=>setTax(Math.ceil(Number(amount) * 8 / 108).toString())}
        >8%切上
        <span className="ml-2">
          {!isNaN(Math.ceil(Number(amount)*8/108)) ? php.number_format(Math.ceil(Number(amount)*8/108))+"円" : <span className="text-red-600">不正な金額です</span>}
        </span>
      </span>
    </div>
    <div className="text-xs font-medium text-gray-500 mt-2">
        <span className={`ml-4 cursor-pointer ${!isNaN(Math.floor(Number(amount) * 10 / 110)) && Math.floor(Number(amount) * 10 / 110) === Number(tax) && 'text-indigo-800'}`}
          onClick={()=>setTax(Math.floor(Number(amount) * 10 / 110).toString())}
        >10%切捨
        <span className="ml-2">
          {!isNaN(Math.floor(Number(amount)*10/110)) ? php.number_format(Math.floor(Number(amount)*10/110))+"円" : <span className="text-red-600">不正な金額です</span>}
        </span>
      </span>
        <span className={`ml-4 cursor-pointer ${!isNaN(Math.round(Number(amount) * 10 / 110)) && Math.round(Number(amount) * 10 / 110) === Number(tax) && 'text-indigo-800'}`}
          onClick={()=>setTax(Math.round(Number(amount) * 10 / 110).toString())}
        >10%四捨五入
        <span className="ml-2">
          {!isNaN(Math.round(Number(amount)*10/110)) ? php.number_format(Math.round(Number(amount)*10/110))+"円" : <span className="text-red-600">不正な金額です</span>}
        </span>
      </span>
        <span className={`ml-4 cursor-pointer ${!isNaN(Math.ceil(Number(amount) * 10 / 110)) && Math.ceil(Number(amount) * 10 / 110) === Number(tax) && 'text-indigo-800'}`}
          onClick={()=>setTax(Math.ceil(Number(amount) * 10 / 110).toString())}
        >10%切上
        <span className="ml-2">
          {!isNaN(Math.ceil(Number(amount)*10/110)) ? php.number_format(Math.ceil(Number(amount)*10/110))+"円" : <span className="text-red-600">不正な金額です</span>}
        </span>
      </span>
  </div>
</>
  ) : (
<>
    <div>


      <dt className="text-xs font-medium text-gray-500">
        <span className="">税込金額と消費税</span>
      </dt>

            <dd className="inline-block">
              <div className="mt-1 text-sm">
                税込金額 { isNaN(Number(amount)) || amount === "" ? amount : php.number_format(Number(amount))} 円
              </div>
            </dd>

            <dd className="inline-block">
              <div className="ml-4 mt-1 text-sm">
                消費税 { isNaN(Number(tax)) || tax === "" ? tax : php.number_format(Number(tax))} 円
              </div>
            </dd>

    </div>

</>

  )
}

export default Amount
