import React, { useState, createContext, useContext } from "react";
import { TEmail } from "../components/EmailView";
import { TTagData, TAllTag } from "../components/EmailView/MetaTag";

export type TDateRange = {
  fromDate: number;
  toDate: number;
}

export type TEmailView = {
  tabIndex?: number;
  emails?: TEmail[];
  currentRow?: number;
  allMetaTags?: TAllTag[];
  selectedMetaTag?: string;
  dateRange?: TDateRange;
  searchWord?: string;
  searchEmailID?: number;
  searchTarget?: string[];
  //
  hash?: string;
};

export type TEmailViewContextType = {
  emailView: TEmailView | null;
  setTabIndex: (tabIndex :number) => void;
  getTabIndex: () => number;
  setEmails: (emails: TEmail[]) => void;
  getEmails: () => TEmail[];
  getEmail: () => TEmail;
  setCurrentRow: (currentRow :number) => void;
  getCurrentRow: () => number;
  updateEmails: (email: TEmail) => void;
  setAllMetaTags: (allMetaTags :TAllTag[]) => void;
  getAllMetatags: () => TAllTag[];
  setSelectedMetaTag: (selectedMetaTag :string) => void;
  getSelectedMetaTag: () => string;
  setDateRange: ( dateRange: TDateRange) => void;
  getDateRange: () => TDateRange;
  setSearchWord: (searchWord :string) => void;
  getSearchWord: () => string;
  setSearchTarget: ( searchTarget: string[]) => void;
  getSearchTarget: () => string[];
  setSearchEmailID: (searchEmailID :number) => void;
  getSearchEmailID: () => number;
  setHash: (hash :string) => void;
  getHash: () => string;
};


// https://qiita.com/johnmackay150/items/88654e5064290c24a32a
function createCtx<ContextType>() {
  const ctx = createContext<ContextType | undefined>(undefined);
  function useCtx() {
    const c = useContext(ctx);
    if (!c) throw new Error("useCtx must be inside a Provider with a value");
    return c;
  }
  return [useCtx, ctx.Provider] as const;
}

export const [useEmailView, SetEmailViewProvider] = createCtx<TEmailViewContextType>();

export const EmailViewProvider: React.FC = (props) => {
  const emailView = useEmailViewCtx();
  return <SetEmailViewProvider value={emailView}>{props.children}</SetEmailViewProvider>;
}

const useEmailViewCtx = (): TEmailViewContextType => {
  const [emailView, setEmailView] = useState<TEmailView | null>(null);
  const setTabIndex = (tabIndex: number) => {
    setEmailView({ ...emailView, tabIndex });
  };

  const getTabIndex = () => {
    return emailView ? emailView!.tabIndex ? emailView!.tabIndex : 0 : 0;
  };

  const setEmails = (emails: TEmail[]) => {
    setEmailView({ ...emailView, emails });
  };

  const getEmails = () => {
    return !emailView ? [] : emailView.emails ? emailView.emails : [];
    // return emailView!.emails ? emailView!.emails : [] ;
  };

  const getEmail = () => {
    const emails = getEmails();
    let targetid = -1;
    for (let i = 0; i < emails.length; i++) {
      if (emails[i].id === getCurrentRow()) {
        targetid = i;
        break;
      }
    };

    return targetid >= 0 ? emails[targetid] : emails[0];
  };

  const setCurrentRow = (currentRow: number) => {
    setEmailView({ ...emailView, currentRow });
  };

  const getCurrentRow = () => {
    return emailView ? (emailView.currentRow ? emailView.currentRow : -1) : -1 ;
  };

  const updateEmails = (email: TEmail) => {
    const emails = getEmails();
    let newEmails: TEmail[] = [];

    newEmails = emails.map((value) => {
      return email.id === value.id ? email : value;
    });
    setEmailView({ ...emailView, emails: newEmails });
  };

  const setAllMetaTags = (allMetaTags: TAllTag[]) => {
    setEmailView({ ...emailView, allMetaTags });
  };

  const getAllMetatags = () => {
    return emailView ? (emailView.allMetaTags ? emailView.allMetaTags : []) : [];
  };

  const setSelectedMetaTag = (selectedMetaTag: string) => {
    setEmailView({ ...emailView, selectedMetaTag });
  };

  const getSelectedMetaTag = () => {
    return emailView ? (emailView.selectedMetaTag ? emailView.selectedMetaTag : "") : "";
  };

  const setDateRange = (dateRange: TDateRange) => {
    setEmailView({ ...emailView, dateRange });
  };

  const getDateRange = () => {
    return emailView ? (emailView.dateRange ? emailView.dateRange : {fromDate:-1, toDate:-1}) : {fromDate:-1, toDate:-1};
  };

  const setSearchWord = (searchWord: string) => {
    setEmailView({ ...emailView, searchWord });
  };

  const getSearchWord = () => {
    return emailView ? (emailView.searchWord ? emailView.searchWord : "") : "";
  };

  const setSearchTarget = (searchTarget: string[]) => {
    setEmailView({ ...emailView, searchTarget });
  };

  const getSearchTarget = () => {
    return emailView ? (emailView.searchTarget ? emailView.searchTarget : []) : [];
  };

  const setSearchEmailID = (searchEmailID: number) => {
    setEmailView({ ...emailView, searchEmailID });
  };

  const getSearchEmailID = () => {
    return emailView ? (emailView.searchEmailID ? emailView.searchEmailID : 0) : 0;
  };

    const setHash = (hash: string) => {
    setEmailView({ ...emailView, hash });
  };

  const getHash = () => {
    return emailView ? (emailView.hash ? emailView.hash : "") : "";
  };

  return {
    emailView,
    setTabIndex,
    getTabIndex,
    setEmails,
    getEmails,
    getEmail,
    setCurrentRow,
    getCurrentRow,
    updateEmails,
    setAllMetaTags,
    getAllMetatags,
    setSelectedMetaTag,
    getSelectedMetaTag,
    setDateRange,
    getDateRange,
    setSearchWord,
    getSearchWord,
    setSearchTarget,
    getSearchTarget,
    setSearchEmailID,
    getSearchEmailID,
    setHash,
    getHash
  };
};
