export const EmailViewMainContainer = (props: { children: React.ReactNode }) => {
  const children = props.children;
  return (
    <div className="bg-gray-300  sm:px-4 md:px-6 pt-2 pb-6 sm:rounded-lg" >
      <div className="bg-gray-100 shadow ">
      {children}
      </div>
    </div>
  );
}

export default EmailViewMainContainer;
