
import {useFileView} from '../../providers/FileViewProvider';
import { FileViewMainContainer } from "./FileViewMainContainer";
import { FileInfoView } from "./FileInfoView";
import {TUploadResponse} from "./FileUpload"


export type TFile = {
  id: number;
  file_name: string;
  owner: string;
  supplier: string;
  project: string;
  amount: number;
  tax: number;
  date: string;
  hash: string;
  related_files:  TUploadResponse[];
  meta_tag: string[];
  memo: string;
  account_id: number;
  name: string;
  delete_requested_at: string;
  delete_requested_user_id: number;
  delete_approved_at: string;
  delete_approved_user_id: number;
  updated_at: string;
  created_at: string;
};

export const FileView = () => {
  const fileView = useFileView();
  const files = fileView.getFiles();
  const id = fileView.getCurrentRow();

  const filterFiles = files.filter((value: TFile) => {
    let default_id=id;
    if (id === -1) {
      default_id = files[0].id;
    }
    return value.id === default_id;
  });
  const file: TFile = filterFiles[0];

  return file ? (
    <div className="mt-4">
      <FileViewMainContainer>
        <FileInfoView />
      </FileViewMainContainer>
    </div>
  ) : <></>
}

export default FileView
