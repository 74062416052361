import { useState, useEffect } from 'react'
import * as php from '../../libs/php_libs';
import {useFileView} from '../../providers/FileViewProvider';
import { MetaTag } from "./MetaTag";
import { Memo } from './Memo';
import { FileTab } from './FileTab';
import { FileUpload } from './FileUpload';
import { FileAuditPart } from './FileAuditPart';
import { Supplier } from './Supplier';
import { Project } from './Project';
import { Amount } from './Amount';
import { OperationLog } from './OperationLog';
import { DownloadLog } from './DownloadLog';
import { ClipboardCopyIcon, ClipboardCheckIcon} from '@heroicons/react/outline'


export type TOption = {
  value: string;
  label: string;
};

export const FileInfoView = () => {
  const fileView = useFileView();
  const file = fileView.getFile();
  const [options, setOptions] = useState<TOption[]>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [url, setUrl ] = useState("");
  const [copied, setCopied ] = useState(false);

  useEffect(() => {
    setUrl(`${window.location.protocol}//${window.location.hostname}`);
  },[])

  const date = (date: string) => {
    const now = new Date(date.replace(/-/g,"/"));
    const ut = now.getTime() / 1000;
    return php.date("Y/m/d H:i:s", ut);
  };

  useEffect(() => {
    // タグリストを作成
    const suppliers = fileView.getSuppliers();
    let newOptions: TOption[] = [];
    suppliers.forEach((value, index) => {
      if (file.supplier === value.supplier) {
        setSelectedIndex(index);
      }
      newOptions.push({
        value: value.supplier,
        label: value.supplier,
      });
    });
    setOptions(newOptions);


  }, [file, fileView]);


  return (
<>
    <div className="bg-gray-100 shadow mt-4">
        <div className="border-t border-gray-200 px-4 py-1 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-12">
            <div className="px-4 py-1 sm:px-6 sm:col-span-4">
              <p className="text-xs mt-1 max-w-2xl text-gray-500">日付</p>
            <dd className="mt-1 text-sm text-gray-900">{date(file?.date)}</dd>
            </div>
            <div className="px-4 py-1 sm:px-6 sm:col-span-6">
              <p className="text-xs mt-1 max-w-2xl text-gray-500">作成者</p>
            <dd className="mt-1 text-sm text-gray-900">{file?.name}</dd>
            </div>
            <div className="px-4 py-1 sm:px-6 sm:col-span-2">
              <p className="mt-1 max-w-2xl text-xs text-gray-500">管理番号</p>
              <dd className="mt-1 text-sm text-gray-900">

              {copied ? (
                  <div className="mt-2">
                    <div>{file?.id}</div>
                    <div className="inline-block">
                      <ClipboardCheckIcon className="inline-block mt-2 flex-shrink-0 h-7 w-7 text-gray-600 " aria-hidden="true"/>
                      <div className="inline-block absolute mt-3"
                      >Link Copied!!</div>
                  </div>
                </div>
              ) : (
                  <div className="mt-2">
                    <div>{file?.id}</div>
                    <div className="inline-block">
                      <ClipboardCopyIcon className="inline-block mt-2 flex-shrink-0 h-7 w-7 text-gray-600 cursor-pointer" aria-hidden="true"
                            onClick={() => {
                              navigator.clipboard.writeText(`${url}/filelink/${file?.id}/${file?.hash}`);
                              setCopied(true);
                              setTimeout(() => {setCopied(false)},1000);
                            }}
                        />
                      <div className="inline-block absolute mt-3">Link Copy</div>
                    </div>
                  </div>
              )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="border-t border-gray-200 px-4 py-1 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-12">
            <div className="px-4 py-1 sm:px-6 sm:col-span-6">
              {options && (selectedIndex !== undefined) && <Supplier />}
            </div>
            <div className="px-4 py-1 sm:px-6 sm:col-span-6">
              <Project />
            </div>
          </dl>
        </div>
        <div className="border-t border-gray-200 px-4 py-1 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-12">
            <div className="px-4 py-1 sm:px-6 sm:col-span-12">
              <Amount />
            </div>
          </dl>
        </div>

        <MetaTag />
        <Memo />
        <FileTab />
        {fileView.getTabIndex() === 0 && <FileUpload />}
        {fileView.getTabIndex() === 1 && <FileAuditPart />}
        {fileView.getTabIndex() === 2 && <OperationLog />}
        {fileView.getTabIndex() === 3 && <DownloadLog />}

    </div>
</>
  );
}

export default FileInfoView;
