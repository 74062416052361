import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from "../hooks/useApi";

export type TToken = {
  id: number;
  token: string;
  expire: number;
  level: number;
}

// test
// http://localhost:3000/filelink/34/066c8dabe32ec954b47c8afe14609faff5bfb74c810c58a3acfa4e8b4f1e0305

export const FileLink: React.FC = () => {
  const navigate = useNavigate()
  const { id, key } = useParams();
  const { running, error, data, execute } = useApi();
  const [authError, setAuthError] = useState(false);

  useEffect(() => {
    if (id && key) {
      // ローカルストレージにキーを指定して、それに紐づく値を保存
      localStorage.setItem('project_id', id);
      localStorage.setItem('project_hash', key);
      // navigate('/file');
      const params = {
        project_id : id,
        project_hash : key
      };
      execute({ url: "/v1/filelink_login", params, redirect: false});
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error && error?.status === 401) {
      setAuthError(true);
      // ローカルストレージから対象のキーに紐づく値を削除
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      // localStorage.removeItem("project_id");
      // localStorage.removeItem("project_hash");
    }
    if (data && data?.api_key) {
      // ローカルストレージからキーを指定して取得
      let api_key = localStorage.getItem("token") || "";
      if (api_key) {
        const token: TToken = JSON.parse(atob(api_key));
        const now = new Date();
        const unixTime = Math.floor(now.getTime() / 1000);
        if (token.expire > unixTime) {
          // すでに有効なログイン情報がある場合は上書きしない
          navigate('/file');
        }
      }
      api_key = data?.api_key || "";
      const name = data?.name || "";
      // ローカルストレージにキーを指定して、それに紐づく値を保存
      localStorage.setItem('token', api_key);
      localStorage.setItem('name', name);
      navigate('/file');
    }
    // eslint-disable-next-line
  }, [error, data]);


  return (
    <>
      {authError &&
        <div className="mx-6 mt-6 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold text-sm">認証エラー</strong><br />
          <span className="block sm:inline text-sm">リンクURLが無効です。</span>
        </div>
      }
    </>
	);
}

export default FileLink;
