export const Verify = (props: { hash1: string, hash2: string }) => {
  const { hash1, hash2 } = props;
  return hash1 === hash2 ? (
    <span className="inline-flex items-center px-2.5 py-2 rounded-md text-sm font-medium bg-green-100 text-blue-600 mt-4 shadow-md">
      <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
      </svg>
      [Verify OK] HASH(sha256) {hash1}<br />
      このメールは正常です。
    </span>
  ): (
    <span className="inline-flex items-center px-2.5 py-2 rounded-md text-sm font-medium bg-orange-100 text-blue-600 mt-4 shadow-md">
      <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
      </svg>
      [Verify NG] HASH(sha256) {hash1}
    </span>
  )
}

export default Verify;
