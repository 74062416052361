import Navbar from '../components/Navbar';
import Approval from '../components/Approval';

export const Home: React.FC = () => {

  return (
    <>
        <Navbar active="ホーム" />
        <Approval />
    </>
	);
}

export default Home;
