import {useFileView} from '../../providers/FileViewProvider';

export const Memo = () => {
  const fileView = useFileView();

  const handleChange = ( e :React.ChangeEvent<HTMLInputElement>) => {
    fileView.setSearchMemo(e.target.value);
  };

  return (
    <div className="inline-block ml-4">
      <label htmlFor="email" className="block text-sm font-medium text-gray-600">
        備考
      </label>
      <div className="mt-1">
        <input
          onChange={handleChange}
          type="search"
          name="Memo"
          id="Memo"
          className="h-9 focus:ring-indigo-500 focus:border-indigo-500 block w-60 sm:text-sm border-gray-300 rounded px-2 py-0.5"
          placeholder="指定なし"
        />
      </div>
    </div>
  )
}

export default Memo;
