import { useState } from "react";
import { ExclamationIcon } from '@heroicons/react/outline'

export type TConfirm = {
  title?: string;
  message?: string;
  okButton?: string;
  cancelButton ?: string;
}

export const useCancelConfirm = (props?: TConfirm) => {
  const title = props?.title || "ファイル削除申請取り消しの確認";
  const message = props?.title || "ファイル削除申請の取り消しを行います。";
  const okButton = props?.okButton || "削除申請取り消し";
  const cancelButton = props?.cancelButton || "キャンセル";
  const [cancelOpen, setCancelOpen] = useState(false);
  const [cancelOk, setCancelOk] = useState(false);
  const [cancelContent, setCancelContent] = useState("");

  const CancelConfirm = () => {
    return cancelOpen ? (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl " >


            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-emerald-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-emerald-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <span className="text-lg leading-6 font-medium text-gray-900">
                    {title}
                  </span>
                  <div className="mt-2">
                    <span className="text-sm text-gray-500">
                      <div dangerouslySetInnerHTML={{ __html: message.replace(/\r?\n/g, '<br />') }} />
                      <div className="text-gray-800 text-sm my-4" dangerouslySetInnerHTML={{ __html: cancelContent.replace(/\r?\n/g, '<br />') }} />
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-bold text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setCancelOk(true);
                    setCancelOpen(false);
                  }}
                >
                  {okButton}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-bold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setCancelOpen(false);
                    setCancelOk(false);
                  }}
                >
                  {cancelButton}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" ></div>
      </>
    ) : null
  }


  return { cancelOk, cancelOpen, setCancelOpen, setCancelOk, setCancelContent, CancelConfirm }
}

export default useCancelConfirm;
