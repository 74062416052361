import { useEffect, useState, useMemo, useRef } from 'react';
import * as php from '../../libs/php_libs';
import DataGrid ,{  DataGridHandle } from 'react-data-grid';
import type { Column, SortColumn } from 'react-data-grid';
import FileView, { TFile } from '../FileView';
import {useFileView} from '../../providers/FileViewProvider';
import { tagDatas } from "../FileView/MetaTag";
import "./index.css";
import { DocumentIcon, TagIcon , PencilAltIcon} from '@heroicons/react/solid'

export type TToken = {
  id: number;
  token: string;
  expire: number;
  level: number;
}

type Comparator = (a: TFile, b: TFile) => number;
const getComparator = (sortColumn: string): Comparator => {
  switch (sortColumn) {
    case 'date':
    case 'supplier':
    case 'project':
    case 'memo':
      return (a, b) => {
        return a[sortColumn].localeCompare(b[sortColumn]);
      };
    case 'meta_tag':
    case 'related_files':
      return (a, b) => {
        return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
      };
    case 'id':
    case 'amount':
      return (a, b) => {
        return a[sortColumn] - b[sortColumn];
      };
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
};


export const FileList = (props:{files:TFile[]}) => {
  const gridRef = useRef<DataGridHandle>(null);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [selectedRows, onSelectedRowsChange] = useState((): ReadonlySet<number> => new Set());
  const [files, ] = useState<any[]>(props.files);
  const fileView = useFileView();
  const [level, setLevel] = useState(0);

  useEffect(() => {
      const api_key = localStorage.getItem("token") || "";
      if (api_key) {
        const token: TToken = JSON.parse(atob(api_key));
        setLevel(token.level);
      }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fileView.setFiles(files);
    // eslint-disable-next-line
  },[files]);

  const columns = useMemo((): readonly Column<TFile>[] => {

    const date = (date: string) => {
      const now = new Date(date.replace(/-/g,"/"));
      const ut = now.getTime() / 1000;
      return php.date("Y/m/d", ut);
    }


    return [
      // {
      //   ...SelectColumn,
      //   width: 30,
      //   frozen: true,
      // },
      {
        key: 'id',
        name: '管理番号',
        width: 80,
        frozen: true,
      },
      {
        key: 'date',
        name: '日付',
        width: 100,
        frozen: true,
        formatter: ({ row }) => row.date ? <span>{date(row.date)}</span>
          : null
      },
      {
        key: 'name',
        name: '作成者',
        width: 100,
        frozen: true,
      },
      {
        key: 'supplier',
        name: '取引先',
        width: 200,
        frozen: true,
      },
      {
        key: 'project',
        name: '案件名',
        width: 200,
        frozen: true,
      },
      {
        key: 'amount',
        name: '金額(円)',
        width: 100,
        frozen: true,
        formatter: ({ row }) => row.amount ? (<span className="flex-shrink-0 h-5 w-5" aria-hidden="true" >{php.number_format(row.amount)}</span>) : <></>,
      },
      {
        key: 'meta_tag',
        name: 'タグ',
        width: 120,
        frozen: true,
        formatter: ({ row }) =>
          row?.meta_tag?.length ?
          <div>
            <TagIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />{row.meta_tag ? row.meta_tag.length : 0}
            {row && row?.meta_tag?.map(tag => {

              let textColor = "text-gray-800";
              let bgColor = "bg-gray-200";
              for (let i = 0; i < tagDatas.length; i++){
                if (tagDatas[i].name === tag) {
                  textColor = tagDatas[i].textColor;
                  bgColor = tagDatas[i].bgColor;
                }
              }
              return (
                  <span key={tag}>
                    <div className={`h-6 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ml-1 ${bgColor}`}>
                      <span className={`ml-1 ${textColor}`}>{tag}</span>
                    </div>
                  </span>
              )
            })}
          </div> : null
      },
      {
        key: 'related_files',
        name: 'ファイル',
        width: 80,
        frozen: true,
        formatter: ({ row }) =>
          row?.related_files?.length ?
          <div>
            <DocumentIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />{row.related_files ? row.related_files.length : 0}
              {row && row?.related_files?.map((file) => {
              return (
                  <span key={file.hash}>
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ml-1 bg-gray-200">
                      <span className="ml-1 text-gray-600" key={file.hash}>{file.name}</span>
                    </span>
                  </span>
              )
            })}
          </div> : null

        // formatter: ({ row }) => row.related_files  && row.related_files.length > 0 ? (<div><DocumentIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />{row.related_files ? row.related_files.length : 0}</div>) : <></>,
      },
      {
        key: 'memo',
        name: '備考',
        width: 300,
        frozen: true,
        formatter: ({ row }) => row.memo && row.memo ? (<><PencilAltIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" /><span className="ml-1">{row.memo}</span></>) : <></>,
      },
    ]
    }, []);

  const sortedRows = useMemo((): TFile[] => {

    if (sortColumns.length === 0) return fileView.getFiles(); // ソート対象のカラムが無い場合

    let sortedRows = fileView.getFiles();
    sortedRows.sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
    // emailView.setEmails(sortedRows);
    return sortedRows;
  }, [ sortColumns, fileView]);


  const rowKeyGetter = (row: TFile) => row.id;
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // if (event.isDefaultPrevented()) {
    //   event.stopPropagation();
    // }
    // // ref 反映待ち これでいいのか？
    // setTimeout(() => {
    //   const id = Number( gridRef.current!.element!.querySelector<HTMLDivElement>('[tabindex="0"]')?.parentElement!.querySelector<HTMLDivElement>('[aria-colindex="2"]')?.textContent);
    //   setCurrentRow(id);
    // }, 10);

  }
  //https://www.npmjs.com/package/react-data-grid
  //https://github.com/adazzle/react-data-grid/blob/main/README.md
  // return files && files.length > 0 ?(
  return files && files.length > 0 ?(
    <div onKeyDown={onKeyDown} className="m-4" >
      {level > 1 && <div>
        <span className="mb-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 ml-2">
          ※一覧より確認したいファイルをクリックしてください。
        </span>
        <DataGrid
          ref={gridRef}
          rowKeyGetter={rowKeyGetter}
          columns={columns}
          rows={sortedRows}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
            minWidth: 30
          }}
          selectedRows={selectedRows}
          onSelectedRowsChange={onSelectedRowsChange}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          className={`ClearTWCheckbox CleatTWSVG rounded-lg border-indigo-200`}
          // className={filters.enabled ? filterContainerClassname : undefined}
          onRowClick={(e) => {
            fileView.setCurrentRow(e.id);
          }}
        />
      </div>}
      <FileView />
      </div >
  ) : (
      <div className="mt-4">
        <span className="text-gray-500 text-sm m-6">検索条件に該当する情報はありません。</span>
      </div>
      );
}


export default FileList;
