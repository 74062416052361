import { useCallback} from 'react'
import { useEmailView } from '../../providers/EmailViewProvider';

const tabs = [
  { name: 'メール本文', tabIdx: 0 },
  { name: 'HTMLメール本文', tabIdx: 1 },
  { name: 'メールヘッダー', tabIdx: 2 },
  { name: '追加ファイル', tabIdx: 3 },
  { name: '監査情報', tabIdx: 4 },
  { name: '操作履歴', tabIdx: 5 },
  { name: 'ダウンロード履歴', tabIdx: 6 },
];

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ')
}

export const EmailTab = () => {
  const emailView = useEmailView();
  const email = emailView.getEmail();

  const ClickTab = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tabIdx: number) => {
    emailView.setTabIndex(tabIdx);
    event.preventDefault();
    if (event.isDefaultPrevented()) {
      event.stopPropagation();
    }
  }, [emailView]);

  return (
    <div >
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className=" block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-400 rounded-t-lg"
          defaultValue={tabs.find((tab) => tab.tabIdx === emailView.getTabIndex())?.name || 1 }
        >
          {tabs.map((tab) => (
            <option key={tab.name} >{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="relative rounded-t-lg shadow flex divide-x divide-gray-400" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <a
              onClick={
                (event) => {
                  ClickTab(event, tabIdx)
                }}
              key={tab.name}
              href="/"
              className={classNames(
                tab.tabIdx === emailView.getTabIndex() ? 'text-gray-900 bg-blue-50' :
                  'text-gray-500 hover:text-gray-700 bg-gray-200',

                'group relative min-w-0 flex-1 overflow-hidden  py-2 px-4 text-xs font-medium text-center hover:bg-gray-100 focus:z-10'
              )}
              aria-current={tab.tabIdx === emailView.getTabIndex() ? 'page' : undefined}
            >
              <span>{tab.name}
                {tab.tabIdx === 3 && email.related_files && email.related_files.length ?
                  <span className="ml-1">
                    ({email.related_files ? email.related_files.length : 0})
                  </span> : null
                }
                {tab.tabIdx === 0 && email.attachments && email.attachments.length && email.text ?
                  <span className="ml-1">
                    ({email.attachments ? email.attachments.length : 0})
                  </span> : null
                }
                {tab.tabIdx === 1 && email.attachments && email.attachments.length && email.html ?
                  <span className="ml-1">
                    ({email.attachments ? email.attachments.length : 0})
                  </span> : null
                }

              </span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.tabIdx === emailView.getTabIndex() ? 'bg-indigo-500' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </a>
          ))}
        </nav>
      </div>
    </div>  )
}

export default EmailTab;
