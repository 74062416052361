import Navbar from '../components/Navbar';
import FileSearch from '../components/FileSearch';
import {FileViewProvider} from '../providers/FileViewProvider';


export const FileArc: React.FC = () => {

  return (
    <>
      <FileViewProvider>
        <Navbar active="ファイル管理" />
        <FileSearch />
      </FileViewProvider>
    </>
	);
}

export default FileArc;
