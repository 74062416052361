
import { useEffect, useState} from 'react';
import * as php from '../../libs/php_libs';
import {useEmailView} from '../../providers/EmailViewProvider';
import { useApi } from "../../hooks/useApi";
import  EmailList from '../EmailList';
import {TEmail} from '../EmailView';
import {Spinner} from '../Spinner';
import { DateRangePicker } from './DateRangePicker';
import {MetaTagSelect, TMetatag} from './MetaTagSelect';
import {SearchTarget} from './SearchTarget';
import {SearchWord} from './SearchWord';
import {SearchEmailID} from './SearchEmailID';
import Breadcrumbs, {TPage} from '../../components/Breadcrumbs';

export type TTagsGet = {
  tags: string[];
};

export type TEmailGet = {
  emails: TEmail[];
};

const subMenu = ["メール本文", "HTMLメール本文", "メールヘッダー", "追加ファイル", "監査情報", "操作履歴", "ダウンロード履歴" ];

export const EmailSearch = () => {
  const emailView = useEmailView();
  const [emailCount, setEmailCount] = useState(0);
  const [emailTotalCount, setEmailTotalCount] = useState(0);
  const [metaTags, setMetaTags] = useState<TMetatag[]>([]);
  const { running:runningEmail , data:dataEmail, error:errorEmail, execute:executeEmail } = useApi();
  const { running:runningConfig , data:dataConfig, error:errorConfig, execute:executeConfig } = useApi();
  const tabIndex = emailView.getTabIndex();
  const pages: TPage[] = dataEmail ?
    [
      { name: 'メールアーカイブ', href: '/email', current: false },
      { name: 'メール検索', href: '', current: true },
      { name:  subMenu[tabIndex], href: '', current: true },
    ]
    :
    [
      { name: 'メールアーカイブ', href: '/email', current: false },
      { name: 'メール検索', href: '', current: true },
    ];

  useEffect(() => {
    executeConfig({ url: "/v1/get_email_config" });

    // ローカルストレージからキーを指定して取得
    let email_id = localStorage.getItem("email_id") || "";
    let email_hash = localStorage.getItem("email_hash") || "";
    if (email_id && email_hash) {
      // ローカルストレージから対象のキーに紐づく値を削除
      // localStorage.removeItem("email_id");
      // localStorage.removeItem("email_hash");

      const params = {
        "search_emailID": Number(email_id),
        "hash": email_hash,
      };
      executeEmail({ url: "/v1/get_emails", params });

    }
    // eslint-disable-next-line
    // eslint-disable-next-line
  }, []);

  // APIで取得したemailsを摘要
  useEffect(() => {
    if (dataEmail) {
      dataEmail?.emails && setEmailCount(dataEmail.emails.length);
    };
    // eslint-disable-next-line
  }, [dataEmail]);

  // APIで取得したconfigを摘要
  useEffect(() => {
    if (dataConfig) {
      setEmailTotalCount(dataConfig?.config?.emails?.count);
      setMetaTags(dataConfig?.config?.meta_tags);
      emailView.setAllMetaTags(dataConfig?.config?.all_meta_tags);
    };
    // eslint-disable-next-line
  }, [dataConfig])

  // メール検索実行
  const handleClick = () => {
    const params = {
      "meta_tag": emailView.getSelectedMetaTag(),
      "date_range": emailView.getDateRange(),
      "search_word": emailView.getSearchWord(),
      "search_target": emailView.getSearchTarget(),
      "search_emailID": emailView.getSearchEmailID(),
      // "hash": emailView.getEmailHash(), // hashがあれば権限のないメールも取得できる
    };
    executeEmail({ url: "/v1/get_emails", params });
    executeConfig({ url: "/v1/get_email_config" });
  };

  return (
    <>
      {/* <Breadcrumbs pages={pages} /> */}
      <div className="bg-gray-100 shadow  rounded-md m-4">
        <div className="grid  grid-cols-1 gap-x-4  sm:grid-cols-12">

          <div className="px-2 py-1  sm:col-span-10">
            <DateRangePicker />
            <MetaTagSelect metaTags={metaTags} />
          </div>
          <div className="px-2 py-1  sm:col-span-10">
            <SearchEmailID />
            <SearchWord />
          </div>

          <div className="px-2 py-1  sm:col-span-2 items-center row-span-2 mt-2">
          {!(runningEmail ||runningConfig) ? (
            <div>
                <button id="submit" className="mt-4 rounded px-3 py-1 bg-blue-600 hover:bg-blue-700 text-white font-bold focus:shadow-outline focus:outline-none"
                  onClick={handleClick}>
                <span className="text-sm" >メール検索</span>
              </button>
              <div className="mt-2 items-center">
                <span className="text-sm text-gray-600 ">{php.number_format(emailCount)} / {php.number_format(emailTotalCount)} 件</span>
              </div>
            </div>
          ) : (
            <div>
              <button id="submit" className="mt-4 rounded px-3 py-1 bg-gray-500  text-white font-bold focus:shadow-outline focus:outline-none cursor-wait">
                <span className="text-sm" onClick={handleClick} >メール検索</span>
              </button>
              <div className="mt-2 items-center">
                    <span className="text-sm text-gray-600 ">{php.number_format(emailCount)} / {php.number_format(emailTotalCount)} 件</span>
              </div>
            </div>
          )}
          </div>

          <div className="px-4 py-2 sm:col-span-10">
            <SearchTarget />
          </div>

        </div>

      </div>

      {dataEmail && <EmailList emails={dataEmail.emails} />}
      {runningConfig && <Spinner text="Now Config API processing..." />}
      {runningEmail && <Spinner text="Now Email API processing..." />}
      {errorConfig && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  API実行中にエラーが発生しました。
                </span>
      }
      {errorEmail && <span className="mt-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800 ml-4 mb-4">
                  API実行中にエラーが発生しました。
                </span>
      }


    </>
  )
};

export default EmailSearch;
