import { useCallback} from 'react'
import { useSetup } from '../../providers/SetupProvider';

const tabs = [
  { name: 'アカウント', href: '#', tabIdx: 0 },
  { name: 'メール管理設定', href: '#', tabIdx: 1 },
  { name: 'ファイル管理設定', href: '#', tabIdx: 2 },
];

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ')
}

export const SetupMainTab = () => {
  const setup = useSetup();

  const ClickTab = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tabIdx: number) => {
    setup.setTabIndex(tabIdx);
    event.preventDefault();
    if (event.isDefaultPrevented()) {
      event.stopPropagation();
    }
  }, [setup]);

  return (
    <div className="">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-400 rounded-t-lg"
          defaultValue={tabs.find((tab) => tab.tabIdx === setup.getTabIndex())?.name || 1 }
        >
          {tabs.map((tab) => (
            <option key={tab.name} >{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="relative rounded-t-lg shadow flex divide-x divide-gray-400" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <a
              onClick={
                (event) => {
                  ClickTab(event, tabIdx)
                }}
              key={tab.name}
              href="/"
              className={classNames(
                tab.tabIdx === setup.getTabIndex() ? 'text-gray-900 bg-blue-50' :
                  'text-gray-500 hover:text-gray-700 bg-gray-200',

                'group relative min-w-0 flex-1 overflow-hidden  py-2 px-4 text-xs font-medium text-center hover:bg-gray-100 focus:z-10'
              )}
              aria-current={tab.tabIdx === setup.getTabIndex() ? 'page' : undefined}
            >
              <span>{tab.name}
              </span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.tabIdx === setup.getTabIndex() ? 'bg-blue-500' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </a>
          ))}
        </nav>
      </div>
    </div>  )
}

export default SetupMainTab;
