import { BrowserRouter, Route, Routes } from 'react-router-dom';
import EmailArc from "./pages/EmailArc";
import FileArc from "./pages/FileArc";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Setup from "./pages/Setup";
import Forget from "./pages/Forget";
import Config from "./pages/Config";
import Reset from "./pages/Reset";
import EmailLink from "./pages/EmailLink";
import FileLink from "./pages/FileLink";
import Page404 from "./pages/Page404";

export const App: React.FC = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/email" element={<EmailArc />} />
        <Route path="/emaillink/:id/:key" element={<EmailLink />} />
        <Route path="/filelink/:id/:key" element={<FileLink />} />
        <Route path="/file" element={<FileArc />} />
        <Route path="/setup" element={<Setup />} />
        <Route path="/forget" element={<Forget />} />
        <Route path="/config" element={<Config />} />
        <Route path="/reset/:id/:key" element={<Reset />} />
        <Route path='*' element={<Page404 />} />
      </Routes>
    </BrowserRouter>
	);
}

export default App;
