import {useEmailView} from '../../providers/EmailViewProvider';
import { EmailAttachmentsPart } from "./EmailAttachmentsPart";

export const EmailTextPart = () => {
  const emailView = useEmailView();
  const email = emailView.getEmail();

  return (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6 min-h-screen overflow-scroll">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10 ">
        <div className="sm:col-span-10">
          <dd className="mt-1 text-sm text-gray-900 ">
            {email?.text ?
              <div dangerouslySetInnerHTML={{ __html: email?.text.replace(/\r?\n/g, '<br />') || "" }} />
              : email?.html ?
                <span className="text-gray-400">メール本文は空です。HTMLメール本文を確認してください。</span>
                : <span className="text-gray-400">メール本文は空です。</span>
            }
          </dd>
        </div>
        {email.text && <EmailAttachmentsPart email={email} />}
      </dl>
    </div>
  );
}

export default EmailTextPart;
