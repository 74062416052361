import { useState, useEffect } from "react";
import { PaperClipIcon } from '@heroicons/react/solid'
import {  DocumentDownloadIcon } from '@heroicons/react/solid'
import { TEmail } from "../EmailView";

export type TToken = {
  id: number;
  token: string;
  expire: number;
  level: number;
}

export const EmailAttachmentsPart = (props: { email: TEmail }) => {
  const email = props.email;
  const attachments = props.email.attachments;
  const [accountId, setAccountId] = useState(0);
  const api_server = localStorage.getItem("api_server") || "https://archives.mirai.ad.jp/";
  const api = `${api_server}api/arc`;

  useEffect(() => {
    // ローカルストレージからキーを指定して取得
    const api_key = localStorage.getItem("token") || "";
    if (api_key) {
      const token: TToken = JSON.parse(atob(api_key));
      setAccountId(token.id);
    }
    // eslint-disable-next-line
  }, []);

  return attachments && attachments.length >0 ? (
    <div className="sm:col-span-10">
      <dt className="text-sm font-medium text-gray-500">添付ファイル</dt>
      <dd className="mt-1 text-sm text-gray-900">
        <ul className=" divide-y divide-gray-200">
            {attachments.map( (attachment,index) => {
              return (
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-200 rounded-md mt-2 shadow-md hover:bg-gray-50" key={index}>
                  <div className="w-0 flex-1 flex items-center">
                    <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="ml-2 flex-1 w-0 truncate">{attachment.file_name}</span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <span className="ml-2 text-gray-500">({
                      attachment.file_size > 1024
                        ? attachment.file_size > 1048576
                          ? Math.round(attachment.file_size / 1048576) + "mb" : Math.round(attachment.file_size / 1024) + "kb"
                        : attachment.file_size + "b"
                    }) </span>

                    <a href={`${api}/download/${email.id}/${attachment.hash}/a/${attachment.expire}/${attachment.key}/${accountId}`} >
                      <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded inline-flex items-center">
                        <DocumentDownloadIcon className="h-5 w-4 mr-1" />
                        <span className="text-xs">ダウンロード</span>
                      </button>
                    </a>

                  </div>
                </li>
              )
            })
          }
        </ul>
      </dd>
    </div>
  ) : <></>;
}

export default EmailAttachmentsPart;
