import * as php from '../../libs/php_libs';
import sha256 from 'crypto-js/sha256';
import { Verify } from "./Verify";
import {useEmailView} from '../../providers/EmailViewProvider';

export const EmailAuditPart = () => {
  const emailView = useEmailView();
  const email = emailView.getEmail();

  const auditAttachments = () => {
    let s = "";
    if (email.html) {
      s +=
`========================================
HTML Mail
FileName: ${email.html.hash}.html
FileSize: ${php.number_format(email.html.file_size)} bytes
HASH(sha256): ${email.html.hash}
`;
    }
    email.attachments && email.attachments.forEach((emailattachment, key) => {
      s +=
`========================================
FileName: ${emailattachment.file_name}
FileSize: ${php.number_format(emailattachment.file_size)} bytes
HASH(sha256): ${emailattachment.hash}
`;
    })
    return s;
  };

  const auditText = `Date: ${email.date}
From: ${email.from}
To: ${email.to}
Subject: ${email.subject}

${email.text}

${auditAttachments()}`;

  return (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6 min-h-full overflow-scroll">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-10">
        <div className="sm:col-span-10">
          <dd className="mt-1 text-sm text-gray-900">
            メール管理番号: {email.id}<br />
            <span className="text-gray-400">-----BEGIN EMAIL-----</span><br />
            <div dangerouslySetInnerHTML={{ __html: auditText.replace(/\r?\n/g, '<br />') }} />
            <span className="text-gray-400">-----END EMAIL-----</span><br />
            <Verify hash1={sha256(auditText).toString()} hash2={email.hash} />
            <hr className="my-4" />
            <span className="text-gray-400">-----BEGIN RSA SIGNATURE-----</span><br />
            <span className="text-gray-400">{email.signature}</span>
            <span className="text-gray-400">-----END RSA SIGNATURE-----</span><br />
            <span className="inline-flex items-center px-2.5 py-2 rounded-md text-sm font-medium bg-green-100 text-blue-600 mt-4 shadow-md">
            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
            </svg>
            <div dangerouslySetInnerHTML={{ __html: "[Decrypt OK]<br />" + email.decode_signature.replace(/\r?\n/g, '<br />') + "認証局の署名は正常です。" }} className="text-blue-500" />
          </span>
            <hr className="my-4" />
            <div className="text-gray-400">
              Mirai Communication Network Time Stamp Service Public Key<br />
              -----BEGIN PUBLIC KEY-----<br />
              MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2PMyAKeZPFDfc+fO3IpL<br />
              LbjcKD+yblwapR9tzMxMSyxTE0FzvlQHNe/eU+UiaO0mDPOf/Yclc/hSsAZD2/1b<br />
              6XCqOkMMQ7lIh2a3Rbj8SenXZd/M2Vlrra1V3+Io5iyVH3+Gkc2GKaAe1c9xBDob<br />
              U84ULvjW79X4bArybODwpjaCEd5f9wvlIOTsi07/FITRpVstxxaNYugnBA/UAE3z<br />
              FZqjbuSuIolbEKy7Yep5QZVdwJ4zFJ8+HZGnMDmnY4nWG/UpIwl3ZGopGRnXXeZx<br />
              45NXns7Zw0zXVlpgeWWiupNy6r7m75n6yWRmfVR0lOQRThjpN0/aPKSM2L1j+ANl<br />
              ZwIDAQAB<br />
              -----END PUBLIC KEY-----<br />
            </div>
          </dd>
        </div>
      </dl>
    </div>
  );
}

export default EmailAuditPart;
